<template>
  <f7-page no-toolbar no-swipeback name="buffet-promotion" class="page-buffet-promotion">
    <f7-navbar :title="$t('v1_bp_0001')" back-link :no-hariline="true"></f7-navbar>
    <f7-card class="card-user-info" v-if="userInfo.UserName">
      <f7-card-content>
        <f7-row no-gap class="row-level">
          <f7-col width="35" class="user-vip-img text-center">
            <img :src="`../../static/images/yt999/vip/${userInfo.vipLevelCode.toLowerCase()}.png`" alt />
          </f7-col>
          <f7-col width="35" class="col-name">
            <div class="user-name">{{ userInfo.UserName.split(".")[1] }}</div>
            <div class="user-level">{{ userInfo.VipLevelName }}</div>
          </f7-col>
          <f7-col width="30"></f7-col>
        </f7-row>
        <div class="position-user-level">{{ userInfo.VipLevelName }}</div>

        <div class="panel-login-info">
          <f7-row no-gap>
            <f7-col width="30" class="text-left">{{ memberLevelMode === "AutoDemotion_LevelUpdateByTurnover" ? $t("v1_bp_0002") : $t("v1_bp_0003") }}</f7-col>
            <f7-col width="70" class="text-left">{{ levelUpInfo.CurrentDepositTotal }}</f7-col>
          </f7-row>
        </div>
      </f7-card-content>
    </f7-card>

    <f7-block class="block-remark">
      <div class="list">
        <ul>
          <li>
            <a v-if="levelLoadStatus" class="item-link smart-select smart-select-init" data-open-in="sheet">
              <select v-model="defaultLevelId">
                <option v-for="item in validUpList" :key="item.VIPLevelID" :value="item.VIPLevelID">{{ item.VIPLevelName }}</option>
              </select>
              <div class="item-content">
                <div class="item-inner">
                  <div class="item-title">{{ $t("v1_bp_0004") }}:</div>
                  <div class="item-after">{{ defaultLevelName }}</div>
                </div>
              </div>
            </a>
            <span v-else class="error-tips">{{ defaultLevelName }}</span>
          </li>
        </ul>
      </div>
    </f7-block>
    <div class="list inset button-promotion" :class="{ 'disabled-status': !levelUpInfo.EnableUpButton }">
      <ul>
        <li>
          <a href="#" class="list-button color-black" @click="levelSubmit">{{ $t("v1_bp_0005") }}</a>
        </li>
      </ul>
    </div>
    <f7-block class="block-tip">
      <p class="tip-title">{{ $t("common_0004") }}：</p>
      <p>1.&nbsp;{{ $t("v1_bp_0006") }}</p>
      <p>2.&nbsp;{{ $t("v1_bp_0007") }}</p>
      <p>3.&nbsp;{{ $t("v1_bp_0008", [$f7.params.name]) }}</p>
    </f7-block>
  </f7-page>
</template>

<script>
import { setCookie, getCookie, getStore, removeStore } from "../../../config/utils";
import { mapGetters, mapMutations, mapActions } from "vuex";
import { getUserInfo, getBalances, checkLevelup, doLevelup } from "../../../axios/api";
import common from "../../../service/common";

export default {
  components: {},
  props: {},
  data() {
    return {
      balances: {},
      levelUpInfo: {},
      validUpList: [],
      vipLevelCode: "",
      memberLevelMode: getStore("MemberLevelMode"),
      levelLoadStatus: false,
      defaultLevelName: "",
      defaultLevelId: "",
    };
  },
  watch: {},
  computed: {
    ...mapGetters(["userInfo"]),
  },
  methods: {
    ...mapMutations(["SETUSERINFO", "SETALLBALANCES"]),
    ...mapActions(["getUserInfo"]),
    balancesEvent() {
      const self = this;
      getBalances().then((data) => {
        if (data.Code === "NoError") {
          self.SETALLBALANCES(data.Data);
          self.balances = data.Data;
        }
      });
    },
    checkLevelupEvent() {
      const self = this;
      checkLevelup().then((data) => {
        if (data.Code === "NoError") {
          self.levelLoadStatus = data.Data.ValidUpList && data.Data.ValidUpList.length > 0;
          self.levelUpInfo = data.Data;
          if (data.Data.ValidUpList && data.Data.ValidUpList.length > 0) {
            self.validUpList = data.Data.ValidUpList;
            self.defaultLevelName = data.Data.ValidUpList[0].VIPLevelName;
            self.defaultLevelId = data.Data.ValidUpList[0].VIPLevelID;
          } else {
            self.defaultLevelName = data.Data.UpRemark;
          }
        }
      });
    },
    levelSubmit() {
      const self = this;
      if (!self.levelUpInfo.EnableUpButton) return;
      const param = {
        data: JSON.stringify({ TargetVIPLevelID: self.defaultLevelId }),
      };
      doLevelup(param).then((data) => {
        self.$f7.dialog.alert(data.Data, this.$t("common_0004"), () => {
          self.checkLevelupEvent();
        });
      });
    },
  },
  created() {
    this.checkLevelupEvent();
    //this.balancesEvent();
    this.getUserInfo();
  },
  mounted() {},
};
</script>
<style lang="less" scoped>
.page-buffet-promotion {
  .card-user-info {
    background-color: transparent;
    box-shadow: none;
    display: flex;
    flex-direction: row;
    margin: var(--f7-margin-size-0);

    .card-content {
      width: var(--f7-size-percent-93);
      padding: var(--f7-padding-size-5);
      margin: 0 auto;
      height: 180px;
      background: url(../../../static/images/yt999/about_bg.png) no-repeat;
      background-size: var(--f7-size-percent-100) var(--f7-size-percent-100);
      margin-bottom: var(--f7-px-4);
      margin-top: var(--f7-px-4);
      border: 1px solid #e4e3e3;

      .row {
        align-items: center;
        padding: var(--f7-padding-size-6) 0;

        .user-vip-img {
          img {
            width: var(--f7-px-84);
            height: var(--f7-px-84);
          }
        }
        .col-name {
          margin-bottom: 10px;
        }
        .user-name {
          color: #464646;
          font-size: var(--f7-px-26);
        }

        .user-level {
          background: -webkit-linear-gradient(left, #3a60c1, #677bb3);
          background: -o-linear-gradient(right, #3a60c1, #677bb3);
          background: -moz-linear-gradient(right, #3a60c1, #677bb3);
          background: linear-gradient(to right, #3a60c1, #677bb3);
          -webkit-background-clip: text;
          -webkit-text-fill-color: transparent;
          color: #f1d49a;
          font-size: var(--f7-px-20);
          font-weight: bold;
        }
      }

      .row-level {
        border-bottom: 1px solid var(--f7-color-blue-keba);
      }

      .position-user-level {
        color: var(--f7-color-black);
        position: var(--f7-postion-absolute);
        right: -2px;
        top: var(--f7-px-13);
        font-size: var(--f7-px-14);
        font-weight: bold;
        transform: rotate(40deg);
        -ms-transform: rotate(40deg);
        -moz-transform: rotate(40deg);
        -webkit-transform: rotate(40deg);
        -o-transform: rotate(40deg);
      }

      .panel-login-info {
        margin: var(--f7-px-5) var(--f7-margin-size-10);
        color: #464646;
        font-size: var(--f7-px-16);

        i.f7-icons {
          font-size: var(--f7-px-16);
          color: var(--f7-color-gray-tint);
          margin-right: var(--f7-px-f5);
        }
      }
    }
  }
  .block-remark {
    margin: 5px 10px 10px;
    padding: 0;
    .list {
      margin: 0;
      ul {
        background-color: transparent;
        .smart-select {
          .item-content {
            .item-inner {
              .item-title {
                color: #464646;
              }
              .item-after {
                width: 70%;
              }
            }
          }
        }
        .smart-select.active-state {
          background-color: transparent;
        }
      }
      ul:before,
      ul:after {
        height: 0;
      }
    }
  }
  .button-promotion {
    margin: 10px 8px;

    ul {
      background: #5dabfe;
    }
  }
  .button-promotion.disabled-status {
    ul {
      background: #ccc;
    }
  }
  .block-tip {
    margin: 20px 8px;
    padding: 0;
    p {
      color: #464646;
    }
    .tip-title {
      color: #464646;
    }
  }

  .error-tips {
    color: #ad0000;
    font-size: 14px;
  }
}
</style>
