nav-balance
<template>
  <f7-page class="page-home" name="home" @page:init="onPageInit" @page:beforein="onPageBeforeIn">
    <!-- Top Navbar -->
    <f7-navbar :sliding="false">
      <f7-nav-left class="">
        <img height="44" :src="`../static/images/yt999/merchant/${$f7.params.fileName}/logo/dlog.png`" />
      </f7-nav-left>
      <f7-nav-title sliding></f7-nav-title>
      <f7-nav-right class="nav-balance">
        <f7-link v-if="!getLoginState" @click="$f7router.navigate('/register/')">
          <span class="registerbtn">{{ $t("home_0016") }}</span>
        </f7-link>
        <f7-link external :href="dowloadLink" v-if="!isAppDevice && dowloadLink != ''">
          <img class="btn-dowload" src="../static/images/yt999/app_dowload.png" height="22" />
        </f7-link>
        <!-- <f7-link @click="openChangeLanguagePopup">
          <img class="btn-dowload" src="../static/images/yt999/lang.png" height="22" />
        </f7-link> -->
        <f7-link @click="openChangeCurrencyPopup" class="btn-currency" v-show="currencyList.length > 1">
          <span :class="['icon-currency', 'icon-' + currency]"></span>
          <span class="code">{{ currency }}</span>
        </f7-link>
      </f7-nav-right>
    </f7-navbar>
    <!-- Swiper banner -->
    <!-- <f7-block class="block-banner home-banner"> -->
    <f7-block class="block-banner">
      <f7-swiper pagination next-button prev-button data-lazy="{'enabled': true}" :params="swiperParams" class="swiper-banner" v-if="bannerShow" @click.native="goActivity()">
        <f7-swiper-slide v-for="(item, index) in bannerList" :key="index">
          <img :src="`${bannerBaseUri + item.Url}`" class="swiper-lazy" />
          <div class="preloader swiper-lazy-preloader"></div>
        </f7-swiper-slide>
      </f7-swiper>
    </f7-block>
    <!-- Top User info-->
    <div class="main-category">
      <marquee :lamplist="lampList"></marquee>
      <div class="user-info">
        <div class="user-detail row no-gap">
          <div class="left-area col-35">
            <div class="balance-title">{{ $t("home_0001") }}</div>
            <div v-if="getLoginState" class="login-balance">
              <span v-if="memberBalance || memberBalance == 0.0">{{ memberBalance | formatCurrency }}</span>
              <preloader :list="4" class="incenter" v-else></preloader>
              <span class="referr" v-if="memberBalance || memberBalance == 0.0" @click="getUserMemberBalance()">
                <img src="../static/images/yt999/home/icon-refresh2.png" alt="" height="15" />
              </span>
            </div>
            <div v-else class="non-balance" @click="$f7router.navigate('/login/')">{{ $t("home_0002") }}</div>
          </div>
          <div class="right-area col-65 row no-gap">
            <div class="action col-25" @click="$f7router.navigate('/member-center/deposit/')">
              <a>
                <p>
                  <img :src="`../static/images/yt999/home/deposit_${$f7.params.currLang}.png`" :onerror="`this.onerror=''; src='../static/images/yt999/home/deposit_enUS.png'`" alt="Deposit" />
                </p>
              </a>
            </div>
            <div class="action col-25" @click="$f7router.navigate('/member-center/withdraw/')">
              <a>
                <p>
                  <img :src="`../static/images/yt999/home/withdraw_${$f7.params.currLang}.png`" :onerror="`this.onerror=''; src='../static/images/yt999/home/withdraw_enUS.png'`" alt="Withdraw" />
                </p>
              </a>
            </div>
            <!-- <div class="action col-25" @click="$f7router.navigate('/member-center/transfer/')"> -->
            <div class="action col-25" @click="openBalancePopup">
              <a>
                <p>
                  <img :src="`../static/images/yt999/home/transfer_${$f7.params.currLang}.png`" :onerror="`this.onerror=''; src='../static/images/yt999/home/transfer_enUS.png'`" alt="Transfer" />
                </p>
              </a>
            </div>
            <div class="action col-25" @click="$f7router.navigate('/member-center/')">
              <a>
                <p>
                  <img :src="`../static/images/yt999/home/referrer_${$f7.params.currLang}.png`" :onerror="`this.onerror=''; src='../static/images/yt999/home/referrer_enUS.png'`" alt="Transfer" />
                </p>
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
    <van-tabs class="tabs-games" @change="activeTab" color="#4076fa" title-active-color="#4076fa" sticky line-width="0" offset-top="45" @scroll="setOffsetTop">
      <van-tab v-for="(groups, key) of gameCategory" :key="key" :title="groups.name" :name="'tab-' + key" class="tab-content" :title-class="'title-' + key">
        <template #title>
          <img :src="`../static/images/yt999/home/nav-icon/nav_${key.toLowerCase()}.png`" alt="" />
          <span>{{ groups.name }}</span>
        </template>
        <template v-if="key == 'Egames'">
          <van-tabs class="egames-item" @click="egameActicveTab" line-width="0">
            <van-tab v-for="(game, key) of groups.list" :key="game.Id" :title="game.Name" :name="game.Id">
              <template #title>
                <img :src="`../static/images/yt999/home/logo/logo_${providerCodes[game.ProviderId].code.toLowerCase()}.png`" />
              </template>
              <div v-if="game.Id == egameId" class="game-list">
                <f7-searchbar search-container=".search-list" search-in=".game-name" search-item=".item-game" :disable-button-text="$t('common_0002')" :placeholder="$t('egame_list_0003')"></f7-searchbar>
                <f7-list v-show="!loadEgameList" class="searchbar-not-found">
                  <f7-list-item :title="$t('common_have_no_data')"></f7-list-item>
                </f7-list>
                <f7-row no-gap class="search-list searchbar-found">
                  <f7-col class="item-game" width="33" v-for="item in loadEgameList" :key="item.GameId" @click="playGame(item)">
                    <img v-if="GameImagesURL == ''" class="lazy lazy-fade-in" :src="`../../static/images/${item.ParentClassId}/${item.GameCode.toLowerCase()}.jpg`" />
                    <img v-if="GameImagesURL != ''" class="lazy lazy-fade-in" :src="`${GameImagesURL}static/images/${item.ParentClassId}/${item.GameCode.toLowerCase()}_enUS.jpg`" />
                    <p class="text-center game-name">{{ item.GameName }}</p>
                  </f7-col>
                </f7-row>
              </div>
            </van-tab>
          </van-tabs>
        </template>
        <template>
          <div class="hotGames-item" v-show="key == 'HotGames'">
            <div class="item-title">{{ $t("home_0017") }}</div>
            <div class="game-list">
              <f7-row no-gap class="hotGame-list">
                <f7-col class="item-game" style="margin: 0" width="100" @click="$f7router.navigate('/member-center/sign-in/')">
                  <img class="bg lazy lazy-fade-in game-lazy" :src="`../static/images/yt999/home/harian1.png`" alt="" />
                </f7-col>
                <f7-col class="item-game" style="margin: 0" width="100" @click="$f7router.navigate('/member-center/online-cs/')">
                  <img class="bg lazy lazy-fade-in game-lazy" :src="`../static/images/yt999/home/harian2.png`" alt="" />
                </f7-col>
              </f7-row>
            </div>
          </div>
        </template>
        <template>
          <div class="hotGames-item" v-show="key == 'HotGames'">
            <div class="item-title">{{ $t("home_0018") }}</div>
            <div class="game-list">
              <f7-row no-gap class="hotGame-list">
                <f7-col class="item-game two" style="margin: 0" width="50" v-for="(item, index) in lotto_tgc" :key="index" @click="playGame(item)">
                  <img class="bg lazy lazy-fade-in game-lazy" :src="`../static/images/yt999/lottery/${item.ProviderId}/${item.GameCode}.png`" alt="" />
                  <!-- <p class="text-center">{{ item.GameName }}</p> -->
                </f7-col>
              </f7-row>
            </div>
          </div>
        </template>
        <template v-for="game of groups.list">
          <div v-if="key == 'Fish'" class="game-item fish-item" :class="[{ 'is-hot': game.IsHot }, 'is-hot-' + $f7.params.currLang]" :key="game.Id" @click="playGame(game)">
            <img class="bg lazy lazy-fade-in game-lazy" :src="`${fishHomeImgUri + game.GameCode.toLowerCase()}_home.png`" alt="" />
            <div class="item-inner" :data-id="game.ProviderId">
              <!-- <div class="name">{{ providerCodes[game.ProviderId].code }}{{ $t('common_0009') }}</div> -->
              <div class="name-fish">{{ game.GameName }}</div>
            </div>
          </div>
          <div v-else-if="key == 'HotGames'" class="hotGames-item">
            <div class="item-title">{{ game.ProviderName }}</div>
            <div class="game-list">
              <f7-row no-gap class="hotGame-list">
                <f7-col class="item-game" width="25" v-for="item in game.GameList" :key="item.GameId" @click="playGame(item)">
                  <img v-if="GameImagesURL != '' && item.Type != 'Fish'" class="lazy lazy-fade-in" :src="`${GameImagesURL}static/images/${item.ParentClassId}/${item.GameCode.toLowerCase()}_enUS.jpg`" />
                  <img v-else class="bg lazy lazy-fade-in game-lazy" :src="`${fishHomeImgUri + item.GameCode.toLowerCase()}_home.png`" alt="" />
                  <p class="text-center">{{ item.GameName }}</p>
                </f7-col>
              </f7-row>
            </div>
          </div>
          <div v-else-if="key !== 'Egames'" class="game-item" :class="[{ 'is-hot': game.IsHot }, 'is-hot-' + $f7.params.currLang]" :key="game.GameCode" @click="playGame(game)">
            <img class="bg" :src="`../static/images/yt999/home/sidebar/${game.Type}_${game.Code}.png`" alt="" />
            <div class="item-inner" :data-id="game.ProviderId">
              <div class="logo" v-if="providerCodes[game.ProviderId]">
                <img :src="`../static/images/yt999/home/logo/logo_${providerCodes[game.ProviderId].code.toLowerCase()}.png`" />
              </div>
              <div class="name">{{ game.Name }}</div>
            </div>
          </div>
        </template>
      </van-tab>
    </van-tabs>
    <!-- <div class="empty-area">
    </div> -->
    <!-- Popup -->
    <f7-popup v-show="showMarqueePopup" animate backdrop id="popup-marquee" class="popup-marquee" @popup:opened="marqueePopupOpened()" @popup:close="marqueePopupClose">
      <f7-page>
        <f7-navbar :title="$t('home_0009')">
          <f7-nav-right>
            <f7-link popup-close>
              <i class="f7-icons">multiply_circle</i>
            </f7-link>
          </f7-nav-right>
        </f7-navbar>
        <f7-block v-if="loadingSkeleton">
          <f7-card v-for="item in lampList" :key="item.Id" :content="item.Content" :footer="item.UpdateTs | time"></f7-card>
        </f7-block>
        <f7-block v-else>
          <f7-card v-for="item in lampList" :key="item.Id" :content="item.Content" :footer="item.UpdateTs | time"></f7-card>
        </f7-block>
      </f7-page>
    </f7-popup>
    <!-- <div class="dialog dialog-balance dialog-move" @dialog:closed="balanceCloseEvent" @dialog:open="balanceOpenEvent"> -->
    <div class="dialog dialog-balance" @dialog:closed="balanceCloseEvent" @dialog:open="balanceOpenEvent">
      <div class="dialog-inner">
        <div class="dialog-title">
          {{ $t("home_0010") }}
          <f7-link @click="closeDialogBalance">
            <i class="f7-icons">chevron_right</i>
          </f7-link>
        </div>
        <div class="dialog-text">
          <f7-block class="block-balance">
            <f7-block-header class="block-header-totalBalance">
              <f7-row>
                <f7-col width="35">{{ $t("home_0011") }}</f7-col>
                <f7-col width="35">
                  <span v-if="totalWalletFlag">{{ totalBalance | formatCurrency }}</span>
                  <preloader :list="4" v-else></preloader>
                </f7-col>
                <f7-col width="30">
                  <f7-button raised fill class="btn-refresh" @click="getAllBalanceList">{{ $t("home_0012") }}</f7-button>
                </f7-col>
              </f7-row>
              <f7-row>
                <f7-col width="35">{{ $t("home_0013") }}</f7-col>
                <f7-col width="35">
                  <span v-if="totalWalletFlag">{{ walletBalance | formatCurrency }}</span>
                  <preloader :list="4" v-else></preloader>
                </f7-col>
                <f7-col width="30">
                  <f7-button raised fill class="btn-recycling" @click="oneClickRecycling">{{ $t("home_0014") }}</f7-button>
                </f7-col>
              </f7-row>
              <f7-row>
                <f7-col width="35">{{ $t("home_0015") }}</f7-col>
                <f7-col width="35">
                  <span v-if="totalWalletFlag">{{ paddingBalance | formatCurrency }}</span>
                  <preloader :list="4" v-else></preloader>
                </f7-col>
                <f7-col width="30"></f7-col>
              </f7-row>
            </f7-block-header>
            <div class="block-content">
              <f7-row class="item-balance" v-for="item in gameBalanceList" :key="item.ProviderId" :class="`item-balance-${item.ProviderId}`">
                <f7-col width="35">{{ item.Name }}</f7-col>
                <f7-col width="35" class="col-balance">
                  <span v-if="totalWalletFlag">
                    <span v-if="item.IsMaintain">{{ $t("common_under_maintenance") }}</span>
                    <span v-else-if="!item.QuerySuccess">N/A</span>
                    <span v-else>{{ item.Balance | formatCurrency }}</span>
                  </span>
                  <preloader :list="4" v-else></preloader>
                </f7-col>
                <f7-col width="30">
                  <f7-row class="btn-group">
                    <f7-col>
                      <f7-button raised fill class="btn-transfer" :class="{ 'btn-disabled': item.IsMaintain || !item.QuerySuccess }" @click="mainWalletToGame(item)">{{ $t("common_balance_transfer_in") }}</f7-button>
                    </f7-col>
                    <f7-col>
                      <f7-button raised fill class="btn-transferOut" :class="{ 'btn-disabled': item.IsMaintain || !item.QuerySuccess }" @click="gameWalletToMain(item)">{{ $t("common_balance_transfer_out") }}</f7-button>
                    </f7-col>
                  </f7-row>
                </f7-col>
              </f7-row>
            </div>
            <f7-block-footer>
              <f7-row no-gap>
                <f7-col tag="span">
                  <f7-button large class="btn-recharge border-radius-left" @click="jumpPage(0, 'member-center/deposit')">{{ $t("home_deposit") }}</f7-button>
                </f7-col>
                <f7-col tag="span">
                  <f7-button large fill class="btn-withdrawal border-radius-right" @click="jumpPage(0, 'member-center/withdraw')">{{ $t("home_withdrawal") }}</f7-button>
                </f7-col>
              </f7-row>
            </f7-block-footer>
          </f7-block>
        </div>
      </div>
    </div>
    <div class="dialog dialog-message" id="dialog-message">
      <div class="dialog-inner">
        <div class="dialog-title">
          <h3>{{ selectedMessage.Title }}</h3>
          <h5>{{ selectedMessage.CreateTs | time }}</h5>
        </div>
        <div class="dialog-text">
          <f7-block class="block-message">
            <div class="block-content" v-html="selectedMessage.Content"></div>
            <f7-row no-gap class="row-toggle">
              <f7-col></f7-col>
              <f7-col class="text-right">
                <span class="text-14">{{ $t("common_set_readed") }}</span>
                <f7-toggle id="chkRead" checked color="blue" @change="toggleRead" :checked="checkRead"></f7-toggle>
              </f7-col>
            </f7-row>
            <f7-block-footer>
              <f7-row no-gap>
                <f7-col tag="span">
                  <f7-button large class="btn-next border-radius-left" :class="userMessageList.length === 1 ? `button-disabled` : ``" @click="nextMessage($event)">{{ $t("common_next") }}</f7-button>
                </f7-col>
                <f7-col tag="span">
                  <f7-button large fill class="btn-close border-radius-right" @click="closeMessagePopup()">{{ $t("common_close") }}</f7-button>
                </f7-col>
              </f7-row>
            </f7-block-footer>
          </f7-block>
        </div>
      </div>
    </div>
    <!-- 首次注册后新会员引导 -->
    <div class="dialog dialog-newPlayerNav" id="dialog-newPlayerNav" @dialog:closed="messageCloseEvent" @dialog:open="messageOpenEvent">
      <div class="dialog-inner">
        <div class="dialog-title">
          <h3>{{ $t("home_new_member_nva") }}</h3>
        </div>
        <div class="dialog-text">
          <f7-block class="block-message">
            <f7-swiper :params="swiperNewPlayerNav">
              <f7-swiper-slide v-for="item in 5" :key="item">
                <div class="block-content">
                  <img :src="`../static/images/yt999/merchant/${$f7.params.fileName}/newPlayerNav/${item}_${$f7.params.currLang}.png`" width="100%" alt="" class="new-player-nav-border" />
                </div>
              </f7-swiper-slide>
            </f7-swiper>
            <f7-block-footer>
              <f7-row no-gap>
                <f7-col tag="span">
                  <f7-button large next-button class="btn-next border-radius-left swiper-button-next">{{ $t("common_next") }}</f7-button>
                </f7-col>
                <f7-col tag="span">
                  <f7-button large fill class="btn-close border-radius-right" @click="closeShowNewPlayerNav()">{{ $t("common_close") }}</f7-button>
                </f7-col>
              </f7-row>
            </f7-block-footer>
          </f7-block>
        </div>
      </div>
    </div>
    <!-- 广告弹窗 -->
    <div class="dialog dialog-popupAd" id="dialog-popupAd">
      <div class="dialog-inner">
        <div class="dialog-title">
          <h3>{{ $t("home_news") }}</h3>
          <h5>{{ popupAdName }}</h5>
        </div>
        <div class="dialog-text">
          <f7-block class="block-message">
            <f7-swiper :params="swiperPopupAd">
              <f7-swiper-slide v-for="(item, index) in popupAdlist" :key="index">
                <div class="block-content">
                  <img :src="`${popupAdImgUri + item.Url}`" width="100%" alt="" class="new-player-nav-border" />
                </div>
              </f7-swiper-slide>
            </f7-swiper>
            <div class="next-popup">{{ $t("common_next") }}{{ nextPopupAdName }}</div>
            <f7-block-footer>
              <f7-row no-gap>
                <f7-col tag="span">
                  <f7-button large next-button class="btn-next border-radius-left swiper-button-next">{{ $t("common_next") }}</f7-button>
                </f7-col>
                <f7-col tag="span">
                  <f7-button large fill class="btn-close border-radius-right" @click="closeShowPopupAd()">{{ $t("common_close") }}</f7-button>
                </f7-col>
              </f7-row>
            </f7-block-footer>
          </f7-block>
        </div>
      </div>
    </div>
    <f7-popup v-show="showLoading" class="loadding-popup" :opened="loadingPopupOpened" tablet-fullscreen>
      <f7-page>
        <f7-block>
          <div class="loader-ring">
            <div class="loader-ring-light"></div>
            <div class="loader-ring-track"></div>
            <div class="loader-logo">
              <img :src="`../static/images/yt999/merchant/${$f7.params.fileName}/logo/loading.png`" />
            </div>
          </div>
          <div class="loadding-timeout" v-if="timeoutStatus">
            <p>{{ $t("home_network_slow") }}</p>
            <a href="javascript:location.reload();">{{ $t("home_try_refresh_page") }}?</a>
          </div>
          <div class="copy-right">
            <p>{{ $t("copyright", [$f7.params.name]) }} Inc.</p>
            <p>{{ app_version }}</p>
          </div>
        </f7-block>
      </f7-page>
    </f7-popup>

    <!-- PWA进入游戏 -->
    <f7-popup id="PWAGame" v-if="showPWAGame" class="onlineCustom-popup" :opened="showPWAGameOpened" @popup:open="openPWAGame" @popup:close="closePWAGame">
      <f7-page style="position: relative;">
        <img src="../static/images/yt999/pwa_close.png" ref="draggableButton" class="popupCloseBtn"  @click="closePWAGame()"/>
        <!-- <f7-link id="closeButton" ref="draggableButton"  popup-close style="position:fixed; top:10px; right:45%;color: black;z-index: 1000;"><i class="f7-icons">multiply_circle</i></f7-link> -->
        <iframe :src="PWAGameUrl" class="iframe-onlineCustom" frameborder="0"  style="transform: none !important;width: 100%;height: 100%;"></iframe>
      </f7-page>
    </f7-popup>

    <f7-fab position="right-bottom" @click="$f7.dialog.open('#dialog-activity')" v-draggable v-show="missionList.length">
      <div class="fab-contaienr">
        <img class="bg-image" src="../static/images/yt999/hongbao.png" />
        <!-- <img class="icon-close" src="../static/images/yt999/balace.png"> -->
      </div>
    </f7-fab>
  </f7-page>
</template>
<script>
import marquee from "./template/marquee";
import preloader from "./template/preloader";
import api from "../config/api";
import { getCookie, setStore, removeStore, getStore, setCookie } from "../config/utils";
import { mapActions, mapGetters, mapMutations, mapState } from "vuex";
import { Draggable } from "draggable-vue-directive";
import { getInfo, getUserInfo, getCarouseladvertList, getLampList, getGameListp, getGameListByPId, getBalances, getGameEntry, getValidProviders, mainWalletToGame, gameWalletToMain, recyclingFunds, getUserNewsList, getPopupAd, alreadyRead, getActivityList, getPromotionDetail, getHotGameLists, getMemberBalance, getFisheListbyClass, getMissionList, getGamebyhotlist, getClassChildList } from "../axios/api";
import http from "../axios/http";
import common from "../service/common";

export default {
  components: {
    marquee,
    preloader,
  },
  directives: {
    Draggable,
  },
  data() {
    const self = this;
    return {
      app_version: VERSION,
      promotionPageSize: 3,
      casinoPageSize: 5,
      egamePageSize: 5,
      fishPageSize: 5,
      dowloadLink: "",
      bannerList: [],
      bannerBaseUri: "",
      bannerShow: false,
      popupAdlist: [],
      popupAdImgUri: "",
      popupAdName: "",
      fishHomeImgUri: "",
      nextPopupAdName: "",
      popupAdIndex: 0,
      lampList: [], //以下关于游戏变量
      promotionList: [],
      loadingSkeleton: true, //加载消息弹窗特效标识
      skeletonTimeout: null, //消息弹窗timeout 对象
      memberBalance: 0, //钱包余额
      gameBalanceList: [], //以下是钱包变量

      totalWalletFlag: false,
      totalBalance: 0.0,
      walletBalance: 0.0,
      paddingBalance: 0.0,
      infiniteLoading: false, //加载条
      userMessageList: [], //个人消息
      selectedMessage: {},
      checkRead: false,
      messageNum: 0,
      isAppDevice: getStore("device"),
      getInfoStatus: false,
      getGameListStatus: false,
      timeoutStatus: false,
      popupOpened: false,
      showLoading: true,

      showPWAGame: true,
      showPWAGameOpened: false,
      PWAGameUrl:'',

      activityDetails: {},
      swiperGameParams: {
        speed: 700,
        loop: false,
        direction: "vertical",
        lazy: {
          loadPrevNext: true,
        },
      },
      swiperParams: {
        speed: 700,
        loop: true,
        autoplay: {
          disableOnInteraction: false,
          delay: 8000,
        },
        lazy: {
          loadPrevNext: true,
        },
      },
      swiperNewPlayerNav: {
        navigation: {
          nextEl: ".swiper-button-next",
        },
      },
      swiperPopupAd: {
        navigation: {
          nextEl: ".swiper-button-next",
        },
        on: {
          slideChangeTransitionEnd: function () {
            self.slideChangeTransition(this.activeIndex);
          },
        },
      },
      UniverseSetting: {}, //{0} 天地
      clientWidth: document.documentElement.clientWidth,
      clientHeight: document.documentElement.clientHeight,
      defaultGameListAreaHeight: 509,
      footerHeigh: 70,
      GameImagesURL: "",
      activeGame: "tab-HotGames",
      loadEgameList: "",
      egameId: 0,
      gameCategory: {
        HotGames: { name: this.$t("common_0042"), list: [] },
        Egames: { name: this.$t("common_0006"), list: [] },
        Lottery: { name: this.$t("common_0011"), list: [] },
        SportsBook: { name: this.$t("common_0010"), list: [] },
        Casino: { name: this.$t("common_0007"), list: [] },
        Card: { name: this.$t("common_0008"), list: [] },
        Fish: { name: this.$t("common_0009"), list: [] },
      },
      autoOpenDialogQueue: [
        { id: "dialog-activity", show: false, check: false, isRead: false },
        { id: "dialog-newPlayerNav", show: false, check: true, isRead: false },
        { id: "dialog-message", show: false, check: false, isRead: false },
        { id: "dialog-popupAd", show: false, check: false, isRead: false },
      ],
      showMarqueePopup: true,
      lotto_tgc: [
        {
          ProviderGameCode: "4DSGC＠",
          ProviderId: 526,
          GameCode: "TCGVND_4DSGC",
          GameId: 1,
          GameName: "Singapore 4D",
          Type: "None",
          Interval: 1,
          Total: 1,
        },
        {
          ProviderGameCode: "TOTOMAS＠",
          ProviderId: 526,
          GameCode: "TCGVND_TOTOMAS",
          GameId: 1,
          GameName: "Malaysia TOTO",
          Type: "None",
          Interval: 2,
          Total: 1,
        },
      ],
      isAndroid: false,
      isIOS: false,
    };
  },
  computed: {
    ...mapState(["currency", "currencyList", "missionList"]),
    ...mapGetters(["getLoginState", "userInfo", "getMerchantInfo", "getAllBalances", "firstLoadApp", "providerCodes"]),
    loadingPopupOpened() {
      return this.$f7router.currentRoute.name == "home" && this.firstLoadApp;
    },
    gameAreaHeight: function () {
      //-45 顶部
      //-10 消息和user info中间10间距
      //-90 user info高度
      //-6  game area margin 6
      //-70 底部菜单
      // SUM = -221
      //--------------------------------------------------------------
      //-125 轮播高度 暂时无用
      //var swiperHeight =110;
      //var clientWidth=document.documentElement.clientWidth;
      // if(clientWidth>=359&&clientWidth<479){
      //   swiperHeight=125;
      // }else if(clientWidth>=479&&clientWidth<539){
      //   swiperHeight=160;
      // }else if(clientWidth>=539&&clientWidth<639){
      //   swiperHeight=180;
      // }else{
      //   swiperHeight=320;
      // }
      //return (document.documentElement.clientHeight-221-swiperHeight)+'px';
      //--------------------------------------------------------------
      return document.documentElement.clientHeight - 45 - 125 - 30 - 60 - 70 - 6 + "px";
    },
    gameCategoryHeight: function () {
      //计算如上 另外再剪掉 more game 12
      //--------------------------------------------------------------
      //-125 轮播高度 暂时无用
      //var swiperHeight =110;
      // var clientWidth=document.documentElement.clientWidth;
      // if(clientWidth>=359&&clientWidth<479){
      //   swiperHeight=125;
      // }else if(clientWidth>=479&&clientWidth<539){
      //   swiperHeight=160;
      // }else if(clientWidth>=539&&clientWidth<639){
      //   swiperHeight=180;
      // }else{
      //   swiperHeight=320;
      // }
      //return (document.documentElement.clientHeight-221-swiperHeight-12)+'px';
      //--------------------------------------------------------------
      return document.documentElement.clientHeight - 221 - 12 + "px";
    },
  },
  watch: {
    autoOpenDialogQueue: {
      deep: true,
      handler(queue) {
        if (queue.every((item) => item.check)) {
          const index = queue.findIndex((item) => item.show && !item.isRead);
          if (!~index) {
            return;
          }
          const item = queue[index];
          this.$f7.dialog.open(`#${item.id}`);
        }
      },
    },
  },
  methods: {
    ...mapMutations(["SETMERCHAT_INFO", "SETALLBALANCES", "SETFIRSTLOADAPP"]),
    ...mapActions(["getUserInfo"]),
    getImageURL(code, id) {
      return "../static/images/yt999/home/" + code + id + ".png";
    },
    goActivity() {
      this.$f7router.navigate("/activity/");
    },
    getInfo() {
      const self = this;
      getInfo().then((data) => {
        if (data.Code === "NoError" && data.Data) {
          self.getInfoStatus = true;
          const d = data.Data;
          self.SETMERCHAT_INFO(d); //存储Vuex store merchantinfo
          setStore(`ImagesURL`, d.ImagesURL);
          if (d.GameImagesURL && d.GameImagesURL != "") {
            setStore(`GameImagesURL`, d.GameImagesURL); // 电子游艺游戏图片
          }
          self.GameImagesURL = d.GameImagesURL;
          self.bannerBaseUri = d.ImagesURL + window.configs.carouseladvertImg;
          self.fishHomeImgUri = d.GameImagesURL + (window.configs.fishHomeImg == null ? "static/images/fish/mobile/" : window.configs.fishHomeImg);
          
          self.checkDeviceType();
          if(self.isAndroid && d.BaseInfo.AppSite!==''){
            self.dowloadLink = d.BaseInfo.AppSite;
          }else if(self.isIOS && d.BaseInfo.AppDownloadSite_iOS!==''){
            self.dowloadLink = d.BaseInfo.AppDownloadSite_iOS;
          }

          self.UniverseSetting = d.UniverseSetting;
          self.getBannerList({ data: 2 });
        }
        self.getLampList();
        self.getGameList();
        self.getFishList();
        self.getPromotionList();
        self.getMissionList();
        self.getHomeHotGameLists();
      });
    },
    checkDeviceType() {
      const userAgent = navigator.userAgent || navigator.vendor || window.opera;

      // 判断是否为安卓设备
      if (/android/i.test(userAgent)) {
        this.isAndroid = true;
      }

      // 判断是否为 iOS 设备
      if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
        this.isIOS = true;
      }
    },
    getUserMemberBalance() {
      const self = this;
      self.memberBalance = null;
      // getMemberBalance().then(data => {
      //   if (data.Code === "NoError") {
      //     setTimeout(()=>{
      //       self.memberBalance = data.Data;
      //     },1500);
      //   }
      // });
      getBalances().then((data) => {
        if (data.Code === "NoError") {
          const res = data.Data;
          // self.totalWalletFlag = true;
          // self.SETALLBALANCES(res); //存储Vuex store balance
          // self.gameBalanceList = res[0];
          // self.totalBalance = res[1];
          // self.paddingBalance = res[2];
          // self.walletBalance = res[3];
          self.memberBalance = res[1];
        }
      });
    },
    getBannerList(param) {
      const self = this;
      getCarouseladvertList(param).then((data) => {
        if (data.Code === "NoError") {
          self.bannerShow = true;
          self.bannerList = data.Data;
        }
      });
    },
    getLampList() {
      const self = this;
      getLampList().then((data) => {
        if (data.Code === "NoError") {
          self.lampList = data.Data;
        }
      });
    },
    getGameList() {
      const self = this;
      getGameListp().then((data) => {
        if (data.Code === "NoError" && data.Data && data.Data.length) {
          self.getGameListStatus = true;
          const d = data.Data;
          for (let item of d) {
            if (item.Type !== "Fish") {
              this.gameCategory[item.Type].list.push(item);
            }
          }
          self.egameId = self.gameCategory.Egames.list[0].Id;
          self.getClassChildList(self.egameId, 0);
        }
      });
    },
    getFishList() {
      const self = this;
      const data = {
        ParentClassId: 0,
        ChildClassId: 0,
        IsHot: false,
        Platform: 2,
      };
      const params = { data: JSON.stringify(data) };
      getFisheListbyClass(params).then((data) => {
        if (!Array.isArray(data.Data)) return;
        self.gameCategory.Fish.list = data.Data;
      });
    },
    getHomeHotGameLists() {
      const self = this;
      // if (getStore("GameImagesURL") && getStore("GameImagesURL") != "") {
      //   self.GameImagesURL = getStore("GameImagesURL");
      //   console.log(self.GameImagesURL);
      // }
      getGamebyhotlist().then((data) => {
        if (data.Code === "NoError") {
          self.gameCategory.HotGames.list = data.Data;
        }
      });
    },
    getClassChildList(type, classType) {
      const self = this;
      const param = {
        data: JSON.stringify({
          ParentClassId: type,
          ChildClassId: classType == -1 ? 0 : classType,
          IsHot: classType == -1,
          Platform: 2,
        }),
      };
      getClassChildList(param).then((data) => {
        if (data.Code === "NoError") {
          self.loadEgameList = data.Data;
        }
      });
    },
    detail(item) {
      this.activityDetails = item;
      this.popupOpened = true;
    },
    playGame(item) {
      if (item.Id == 0) {
        return;
      }
      const self = this;
      //Egames进入子分类页面需要
      // if (item.Type == "Egames" && this.activeGame != "tab-HotGames") {
      //   self.goGameList(item.Id);
      //   return;
      // }
      if (item.isHotGame) {
        // 首页热门游戏进入,参数转换
        item["ProviderId"] = item.ProviderID;
        item["ProviderGameCode"] = item.ProviderGameCode;
        item["GameName"] = item.gamingPlatformName;
      }
      const router = self.$f7router;
      if (self.getLoginState && getCookie("zz")) {
        const params = {
          ProviderId: item.ProviderId,
          gameCode: item.ProviderGameCode,
          Language: "zhCN",
          View: "v1",
          TemplateName: "flashtechgray",
          Platform: 2,
          Name: item.Name || item.GameName,
          Host: window.location.origin,
        };
        const d = { type: 1, data: JSON.stringify(params), z: getCookie("zz") || getStore("zz") };
        const deviceFlag = getStore("device");
        const isApp = deviceFlag === "App";
        // if (!isApp && !common.getBrowser.isSougou) {
        //   setStore("playGameParam", JSON.stringify(params));
        //   this.common.redirectURL(`/#/loading-game/`);
        //   return;
        // }

        let promtLoadingGame = self.$f7.dialog
          .create({
            title: "",
            text: this.$t("common_game_loading_format").Format(params.Name),
            cssClass: "dialog-preloadgame",
          })
          .open();
        self.$f7.params.dialog.closeByBackdropClick = false;
        self.$f7.$(".dialog-backdrop").css("background-color", "transparent");

        getGameEntry(d).then((data) => {
          setTimeout(() => {
            self.$f7.params.dialog.closeByBackdropClick = true;
            self.$f7.$(".dialog-backdrop").css("background-color", "rgba(0, 0, 0, 0.74)");
            promtLoadingGame.close();
          }, 3000);

          if (data.Code === "NoError" && data.Data) {
            if (params.ProviderId == 512) {
              const entryData = JSON.parse(data.Data);
              let encode = btoa(encodeURI(data.Data));
              window.location.href = entryData.GameEntryURL + "/index.html?info=" + encode;
            } else {
              if (deviceFlag === "PWA") {
                //use iframe
                setTimeout(() => {
                  self.openPWAGame();
                  self.PWAGameUrl = data.Data;
                }, 3000);
              } else {
                window.location.href = data.Data;
              }
            }
          } else {
            promtLoadingGame.close();
            let content = data.Code === "ProviderMaintain" && data.Data && data.Data.length >= 2 ? this.$t("common_game_maintenance_from") + data.Data[0].replace("T", " ") + this.$t("common_game_maintenance_to") + data.Data[1].replace("T", " ") : this.$t("common_loading_game_failed");
            //self.$f7.dialog.alert(content, "");
            self.promptMaintain(content);
          }
        });
      } else {
        let promtLoadingGame = self.$f7.dialog
          .create({
            title: "",
            text: this.$t("common_need_login"),
            cssClass: "dialog-preloadgame",
          })
          .open();
        setTimeout(function () {
          promtLoadingGame.close();
        }, 1500);
        //router.navigate("/login/");
      }
    },
    promptMaintain(content) {
      const self = this;
      self.$f7.params.dialog.closeByBackdropClick = false;
      self.$f7.$(".dialog-backdrop").css("background-color", "transparent");
      let promptMaintain = self.$f7.dialog
        .create({
          title: "",
          text: content,
          cssClass: "dialog-game",
        })
        .open();
      setTimeout(() => {
        self.$f7.params.dialog.closeByBackdropClick = true;
        self.$f7.$(".dialog-backdrop").css("background-color", "rgba(0, 0, 0, 0.74)");
        promptMaintain.close();
      }, 2000);
    },
    goGameList(id) {
      this.$f7router.navigate(`/game-list/${id}/`);
    },
    goGameCategoryList(gameType) {
      this.$f7router.navigate(`/game-category-list/${gameType}/`);
    },
    goFishGameList() {
      this.$f7router.navigate(`/fish-list/`);
    },
    showDeterminate(multiColor) {
      // 顶部 progressbar加载条
      const self = this;
      const app = self.$f7;
      if (self.infiniteLoading) return;
      self.infiniteLoading = true;
      if (multiColor) {
        app.progressbar.show("multi");
      } else {
        app.progressbar.show(app.theme === "md" ? "yellow" : "blue");
      }
    },
    async getValidProviders() {
      const self = this;
      let res = await http.post(api.validproviders);
      if (res.Code === "NoError") {
        self.$f7.params.dialog.closeByBackdropClick = true; //点击背景关闭
        self.$f7.dialog.open(".dialog-balance", true);
        self.gameBalanceList = res.Data;
        self.getAllBalanceList();
      }
    },
    getAllBalanceList() {
      const self = this;
      self.totalWalletFlag = false; //总钱包余额是否请求完成标识
      getBalances().then((data) => {
        if (data.Code === "NoError") {
          const res = data.Data;
          self.totalWalletFlag = true;
          self.SETALLBALANCES(res); //存储Vuex store balance
          self.gameBalanceList = res[0];
          self.totalBalance = res[1];
          self.paddingBalance = res[2];
          self.walletBalance = res[3];
        }
      });
    },
    getMissionList() {
      const self = this;
      getMissionList().then((data) => {
        const item = this.autoOpenDialogQueue.find((item) => item.id == "dialog-activity");
        if (data.Code === "NoError" && data.Data) {
          self.$store.commit("SET_MISSION_LIST", data.Data);
          const unShow = /true/.test(getCookie("unShow"));
          if (Array.isArray(data.Data) && data.Data.length && !unShow) {
            item.show = true;
          } else {
            item.show = false;
          }
          item.check = true;
        }
      });
    },
    jumpPage(type, param) {
      const self = this;
      const router = self.$f7router;
      if (type === 0) {
        //balance popup 弹窗
        self.$f7.dialog.close(".dialog-balance");
        router.navigate(`/${param}/`);
      }
    },
    oneClickRecycling(event) {
      const self = this;
      if (!self.totalWalletFlag) return;
      self.$f7.$(".btn-recycling").addClass("progressbar");
      self.$f7.$(".btn-recycling").text(this.$t("common_game_balance_transfer_back"));
      recyclingFunds().then((data) => {
        self.$f7.$(".btn-recycling").removeClass("progressbar");
        self.$f7.$(".btn-recycling").text(this.$t("common_one_key_transfer_game_balance_back"));
        if (data.Code === "NoError") {
          self.getAllBalanceList();
        }
      });
    },
    mainWalletToGame(item) {
      const self = this;
      if (!self.totalWalletFlag || item.IsMaintain || !item.QuerySuccess || !self.walletBalance) return;
      const params = {
        ProviderId: item.ProviderId,
        Amount: self.walletBalance,
      };
      const d = { data: JSON.stringify(params) };
      self.$f7.$(`.item-balance-${item.ProviderId}`).addClass("progressbar");
      mainWalletToGame(d).then((data) => {
        setTimeout(() => {
          self.$f7.$(`.item-balance-${item.ProviderId}`).removeClass("progressbar");
        }, 1000);
        if (data.Code === "NoError") {
          self.getAllBalanceList();
        }
      });
    },
    gameWalletToMain(item) {
      const self = this;
      if (!self.totalWalletFlag || item.IsMaintain || !item.QuerySuccess || !item.Balance) return;
      const params = {
        ProviderId: item.ProviderId,
        Amount: item.Balance,
      };
      const d = { data: JSON.stringify(params) };
      self.$f7.$(`.item-balance-${item.ProviderId}`).addClass("progressbar");
      gameWalletToMain(d).then((data) => {
        setTimeout(() => {
          self.$f7.$(`.item-balance-${item.ProviderId}`).removeClass("progressbar");
        }, 1000);
        if (data.Code === "NoError") {
          self.getAllBalanceList();
        }
      });
    },
    marqueePopupOpened() {
      var self = this;
      self.showMarqueePopup = true;
      self.skeletonTimeout = setTimeout(() => {
        self.loadingSkeleton = false;
      }, 3000);
    },
    marqueePopupClose() {
      clearTimeout(this.skeletonTimeout);
      this.loadingSkeleton = true;
      this.showMarqueePopup = false;
    },
    openChangeLanguagePopup() {
      const self = this;
      self.$f7.params.dialog.closeByBackdropClick = true; //点击背景关闭
      self.$f7.dialog.open("#dialog-change-language", true);
    },
    openChangeCurrencyPopup() {
      const self = this;
      self.$f7.params.dialog.closeByBackdropClick = true; //点击背景关闭
      self.$f7.dialog.open("#dialog-change-currency", true);
    },
    openBalancePopup() {
      const self = this;
      if (self.getLoginState) {
        self.getValidProviders();
      } else {
        this.$f7router.navigate("/login/");
      }
    },
    closeDialogBalance() {
      this.$f7.dialog.close(".dialog-balance");
    },
    balanceOpenEvent() {
      this.$f7.$(".dialog-backdrop").css("background", "rgba(0, 4, 7, 0.77)");
    },
    balanceCloseEvent() {
      this.$f7.$(".dialog-backdrop").css("background", "transparent");
    },
    getUserNewsListEvent() {
      const self = this;
      const param = {
        data: JSON.stringify({
          Status: 2,
          BeginDate: 0,
          EndDate: 0,
          PageNow: 1,
          PageSize: "100",
        }),
      };
      const item = self.autoOpenDialogQueue.find((item) => item.id == "dialog-message");
      getUserNewsList(param).then((data) => {
        if (data.Code === "NoError" && data.Data.Total > 0) {
          self.userMessageList = data.Data.Data;
          self.selectedMessage = self.userMessageList[self.messageNum];
          self.$f7.params.dialog.closeByBackdropClick = false; //点击背景不关闭
          item.show = true;
          // self.$f7.dialog.open('.dialog-message');
        }

        item.check = true;
      });
    },
    showNewPlayerNav() {
      const self = this;
      self.$f7.params.dialog.closeByBackdropClick = false; //点击背景不关闭
      // self.$f7.dialog.open('.dialog-newPlayerNav');
      //新会员引导
      // const item = this.autoOpenDialogQueue.find((item) => item.id == "dialog-newPlayerNav");
      // item.check = true;
      // item.show = true;
      removeStore("showNewPlayerNav");
    },
    showNewPopupAd() {
      const self = this;
      const param = {
        data: JSON.stringify({
          Type: 4,
        }),
      };
      getPopupAd(param).then((data) => {
        const item = this.autoOpenDialogQueue.find((item) => item.id == "dialog-popupAd");
        item.check = true;
        if (data.Code === "NoError" && data.Data.Status && data.Data.CarouselAdvert.length > 0) {
          self.popupAdlist = data.Data.CarouselAdvert;
          self.popupAdName = self.popupAdlist[0].Name;
          if (self.popupAdlist.length > 1) {
            self.nextPopupAdName = self.popupAdlist[1].Name;
          } else {
            self.nextPopupAdName = this.$t("common_have_no_next");
          }
          self.popupAdImgUri = getStore(`ImagesURL`) + window.configs.popupAdImg;
          self.$f7.params.dialog.closeByBackdropClick = false; //点击背景不关闭
          // self.$f7.dialog.open('.dialog-popupAd');
          item.show = true;
          sessionStorage.setItem("mainPopup", 1);
        }
      });
    },
    messageOpenEvent() {
      this.$f7.$(".dialog-backdrop").css("background", "rgba(0, 4, 7, 0.77)");
    },
    messageCloseEvent() {
      this.$f7.$(".dialog-backdrop").css("background", "transparent");
    },
    toggleRead() {
      if (this.checkRead) {
        this.checkRead = false;
      } else {
        this.checkRead = true;
      }
    },
    nextMessage(event) {
      const self = this;
      self.messageNum++;
      if (self.messageNum < self.userMessageList.length) {
        if (self.checkRead) {
          alreadyRead({ data: self.selectedMessage.Id }).then((data) => {});
        }
        self.selectedMessage = self.userMessageList[self.messageNum];
        if (self.messageNum === self.userMessageList.length - 1) {
          self.$f7.$(event.currentTarget).addClass("button-disabled");
        }
      }
    },
    slideChangeTransition(index) {
      const self = this;
      self.popupAdName = self.popupAdlist[index].Name;
      if (self.popupAdlist.length - 1 > index) {
        self.nextPopupAdName = self.popupAdlist[index + 1].Name;
      } else {
        self.nextPopupAdName = this.$t("common_have_no_next");
      }
    },
    getPromotionList() {
      const self = this;
      const params = { data: JSON.stringify({ Type: 0 }) };
      getActivityList(params).then((data) => {
        if (data.Code === "NoError") {
          self.promotionList = [];
          var current_flag = 0;
          var arr = [];
          for (let index = 0; index < data.Data.length; index++) {
            const element = data.Data[index];
            if (element.Type == 5) {
              arr[arr.length] = element;
              current_flag++;
              if (current_flag == self.promotionPageSize) {
                self.promotionList[self.promotionList.length] = arr;
                current_flag = 0;
                arr = [];
              }
            }
          }
        }
      });
    },
    closeMessagePopup() {
      const self = this;
      if (self.checkRead) {
        const param = { data: self.selectedMessage.Id };
        alreadyRead(param).then((data) => {});
      }
      self.$f7.dialog.close("#dialog-message");
      const item = self.autoOpenDialogQueue.find((item) => item.id == "dialog-message");
      item.isRead = true;
    },
    closeShowNewPlayerNav() {
      const self = this;
      self.$f7.dialog.close(".dialog-newPlayerNav");
      const item = self.autoOpenDialogQueue.find((item) => item.id == "dialog-newPlayerNav");
      item.isRead = true;
    },
    closeShowPopupAd() {
      const self = this;
      self.$f7.dialog.close(".dialog-popupAd");
      const item = this.autoOpenDialogQueue.find((item) => item.id == "dialog-popupAd");
      item.isRead = true;
    },
    loadingStatusEvent() {
      const self = this;
      const start = new Date();
      let timeoutShowReload = setInterval(() => {
        const end = new Date();
        let time = (end - start) / 1000;
        if (time >= 10) {
          self.timeoutStatus = true;
          clearInterval(timeoutShowReload);
        }
      }, 1000);
      let loadingInterval = setInterval(() => {
        const end = new Date();
        let time = (end - start) / 1000;
        if (time > 3) {
          if (this.firstLoadApp && this.bannerShow && this.getInfoStatus && this.getGameListStatus) {
            //this.loadingPopupOpened = false;
            this.showLoading = false;
            this.$f7.popup.close(".loadding-popup");
            this.SETFIRSTLOADAPP(false);
            clearInterval(loadingInterval);
            clearInterval(timeoutShowReload);
          }
        }
      }, 1000);
    },
    onPageInit(e, page) {
      const self = this;
      //if (page.router.currentRoute.name == "home") {
      self.$f7ready((f7) => {
        if (self.firstLoadApp) {
          self.loadingStatusEvent();
        }
        //self.showDeterminate(false);
        self.getInfo();
        if (getStore("zz") != "") {
          this.getUserInfo();
          this.getUserMemberBalance();
        }
        //广告弹窗
        if (!sessionStorage.getItem("mainPopup")) {
          self.showNewPopupAd();
        }
        //新手引导
        if (getStore("showNewPlayerNav")) {
          self.showNewPlayerNav();
        }

        //if (getCookie("zz") && page.router.currentRoute.name == "home")
        if (getCookie("zz")) {
          self.getUserNewsListEvent();
        } else {
          const item = self.autoOpenDialogQueue.find((item) => item.id == "dialog-message");
          item.check = true;
        }
        self.$f7.on("onCloseDialog", (targetId) => {
          const item = self.autoOpenDialogQueue.find((item) => item.id == targetId);
          if (item) {
            item.isRead = true;
          }
        });
      });
      //}
    },
    onPageBeforeIn() {
      this.$f7.emit("myCustomEvent", "home");
      removeStore("mc-tab-active");
    },
    goUniverse(type) {
      const self = this;
      if (type === "") {
        let promtLoadingGame = self.$f7.dialog
          .create({
            title: "",
            text: this.$t("common_keep_waiting"),
            cssClass: "dialog-preloadgame",
          })
          .open();
        self.$f7.params.dialog.closeByBackdropClick = false;
        self.$f7.$(".dialog-backdrop").css("background-color", "transparent");
        setTimeout(() => {
          promtLoadingGame.close();
        }, 1500);
        return;
      }
      var mreturn = "";
      if (!getStore("device")) {
        mreturn = btoa(window.location.protocol + "//" + window.location.host);
      } else {
        const d = localStorage.getItem("d") && localStorage.getItem("d") != "" ? localStorage.getItem("d") : "";
        const appVer = localStorage.getItem("appVer") && localStorage.getItem("appVer") != "" ? localStorage.getItem("appVer") : "";
        const appHref = window.location.protocol + "//" + window.location.host + "/#/app/?d=" + d + "&device=App&appVer=" + appVer;
        mreturn = btoa(appHref);
      }
      const regist = btoa(window.location.protocol + "//" + window.location.host + "/#/register/");
      const href = `${type}?platform=mobile&system=${self.UniverseSetting.SystemCode}&merchant=${window.configs.merchantAlias}&token=${getCookie("zz") ? getCookie("zz") : ""}&return=${mreturn}&regist=${regist}`;
      if (!getStore("device")) {
        this.common.redirectURL(href);
      } else {
        let promtLoadingGame = self.$f7.dialog
          .create({
            title: "",
            text: this.$t("home_TV_loading", [this.$f7.params.shortName]),
            cssClass: "dialog-preloadgame",
          })
          .open();
        self.$f7.params.dialog.closeByBackdropClick = false;
        self.$f7.$(".dialog-backdrop").css("background-color", "transparent");
        setTimeout(() => {
          promtLoadingGame.close();
        }, 1500);
        window.location.href = href;
      }
    },
    activeTab(value) {
      this.activeGame = value;
    },

    egameActicveTab(key) {
      const self = this;
      if (key == self.egameId) {
        return;
      }
      self.egameId = key;
      self.loadEgameList = "";
      self.getClassChildList(key, 0);
    },
    setOffsetTop() {},
    openPWAGame() {
      this.showPWAGame = true;
      this.showPWAGameOpened = true;

    },
    closePWAGame() {
      this.showPWAGameOpened = false;
      this.$f7.popup.close('#PWAGame', true);
    },
    initDrag() {
      const button = this.$refs.draggableButton;
      const onDragStart = (event) => {
        let shiftX = event.touches[0].clientX - button.getBoundingClientRect().left;
        let shiftY = event.touches[0].clientY - button.getBoundingClientRect().top;

        const moveAt = (pageX, pageY) => {
          // 获取按钮和屏幕的宽度和高度
          const buttonWidth = button.offsetWidth;
          const buttonHeight = button.offsetHeight;
          const screenWidth = document.documentElement.clientWidth;
          const screenHeight = document.documentElement.clientHeight;

          // 计算按钮的新位置，确保它不会超出屏幕
          let newLeft = pageX - shiftX;
          let newTop = pageY - shiftY;

          // 边界检查
          if (newLeft < 0) newLeft = 0;
          if (newLeft + buttonWidth > screenWidth) newLeft = screenWidth - buttonWidth;
          if (newTop < 0) newTop = 0;
          if (newTop + buttonHeight > screenHeight) newTop = screenHeight - buttonHeight;

          button.style.left = newLeft + 'px';
          button.style.top = newTop + 'px';
        };

        const onTouchMove = (event) => {
          moveAt(event.touches[0].pageX, event.touches[0].pageY);
        };

        document.addEventListener('touchmove', onTouchMove);

        button.ontouchend = () => {
          document.removeEventListener('touchmove', onTouchMove);
          button.ontouchend = null;
        };
      };

      button.ontouchstart = onDragStart;
    }
  },
  created() {
    const d = this.$f7route.query.d;
    const r = this.$f7route.query.r;
    setStore("d", d);
    setStore("r", r);
  },
  mounted() {
    window.addEventListener("resize", function () {
      //-45 顶部
      //-10 消息和user info中间10间距
      //-90 user info高度
      //-6  game area margin 6
      //-70 底部菜单
      // SUM = -221
      this.clientHeight = document.body.clientHeight;
      this.clientWidth = document.body.clientWidth;
    });
    this.initDrag();

  },
  beforeDestroy() {
    this.closeShowPopupAd();
  },
  updated() {
    this.$f7.lazy.create(this.$el);
    //this.$f7.swiper.create(this.$el);
  },
};
</script>
<style lang="less" scoped>
.activity-detail-popup {
  .block {
    margin: var(--f7-margin-size-0);
    padding: var(--f7-padding-size-0);
  }
}

.next-popup {
  color: #000;
  text-align: center;
  margin-bottom: 10px;
}

.new-player-nav-border {
  border: 1px solid #4e4e4e;
}

.martop {
  margin-top: -3px !important;
}

.referr {
  position: absolute;
  top: 21px;
  right: 8px;

  img {
    vertical-align: middle;
  }
}

.incenter {
  margin: 0 auto;
  display: inline-block;
}

.tabs-games {
  padding-bottom: 88px;

  /deep/ .van-tabs__wrap {
    box-shadow: 0px 0px 8px 0px rgba(104, 0, 255, 0.18);

    .van-tabs__nav {
    }
  }
  /deep/ .van-sticky {
    .van-tab__text {
      display: flex;
      position: relative;
      align-items: center;
      background-color: #f3f3f3;
      border-radius: 25px;
      padding: 4px 18px 4px 38px;
      height: 20px;
      font-size: 12px;
      box-shadow: 0 0 2px 0px rgba(0, 0, 0, 0.1);

      img {
        position: absolute;
        left: 0;
        bottom: 0;
        height: 32px;
      }
    }
  }

  /deep/ .van-tab--active {
    &.title-SportsBook .van-tab__text {
      background: linear-gradient(45deg, #4fe387, #6ce164);
      color: #fff;
    }

    &.title-Casino .van-tab__text {
      background: linear-gradient(45deg, #56c2f2, #3cacf6);
      color: #fff;
    }

    &.title-Egames .van-tab__text {
      background: linear-gradient(45deg, #f8ae4f, #f79b2b);
      color: #fff;
    }

    &.title-Lottery .van-tab__text {
      background: linear-gradient(45deg, #f84fe6, #f72fc8);
      color: #fff;
    }

    &.title-Card .van-tab__text {
      background: linear-gradient(45deg, #efb190, #dd8864);
      color: #fff;
    }

    &.title-Fish .van-tab__text {
      background: linear-gradient(45deg, #3b7aee, #2033af);
      color: #fff;
    }

    &.title-HotGames .van-tab__text {
      background: linear-gradient(45deg, #ffd200, #ffd200);
      color: #fff;
    }
  }

  .tab-content {
    display: flex;
    flex-wrap: wrap;
    padding: 6px 5px;

    .game-item {
      position: relative;
      width: 33.333333%;
      text-align: center;
      margin-bottom: 5px;
      box-sizing: border-box;

      .bg {
        width: calc(100% - 10px);
      }

      .item-inner {
        position: absolute;
        bottom: 10px;
        left: 15px;
        //right: 15px;

        color: #fff;

        .logo {
          line-height: 0;
          height: 28px;

          text-align: left;

          img {
            height: 100%;
          }
        }

        .name {
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          text-align: center;
        }

        .name-fish {
          text-align: center;
        }
      }
    }

    .hotGames-item {
      width: 98%;
      border-bottom: 1px solid #dddddd;
      margin: auto;

      .item-title {
        padding: 6px 5px;
        color: #5295fc;
        font-size: 16px;
        font-weight: 600;
      }

      .game-list {
        .hotGame-list {
          justify-content: start;
          .two:nth-child(1) {
            margin-right: 5px !important;
          }
        }

        .item-game {
          margin: 0 1.8px;
          img {
            width: 100%;
            border-radius: 8px;
            height: auto;
          }
          p {
            margin: var(--f7-px-1) 0 var(--f7-px-8);
            color: #464646;
          }
        }
      }
    }

    .egames-item {
      display: flex;
      padding-bottom: 0;
      flex-wrap: wrap;
      width: 100%;
      ::v-deep .van-tabs__wrap {
        display: flex;
        overflow: hidden;
        position: -webkit-sticky;
        position: sticky;
        top: 50px;
        z-index: 10;
        height: 620px;
        box-shadow: none;
        .van-tab {
          padding: 0;
          margin: 3px 0;
          &::after {
            content: "";
            width: 3.838em;
            height: 1px;
            background-image: url("../static/images/yt999/home/ico/borderBottom-icon.png");
            background-repeat: no-repeat;
            background-size: contain;
            box-sizing: content-box;
            position: absolute;
            bottom: -3px;
            z-index: 11;
            background-position: 50%;
          }
        }
        .van-tabs__nav {
          display: flex;
          flex-direction: column;
          overflow-y: scroll;
          overflow-x: hidden;
          padding: 0;
          .van-tab__text {
            display: flex;
            height: 32px;
            padding: 4px 4px;
            background-color: #5295fc;
            border-radius: 6px;
            width: 62px;
            justify-content: center;
            img {
              width: 50px;
              position: relative;
            }
          }
          .van-tab--active {
            .van-tab__text {
              background-color: #f79b2b !important;
            }
          }
        }
      }
      ::v-deep .van-tabs__content {
        flex: 1;
        padding: 0px 10px;
        .game-list {
          .searchbar {
            position: sticky;
            top: 50px;
            width: 99%;
            margin-left: 3px;
          }
          .item-inner {
            justify-content: center;
          }
          .search-list {
            justify-content: start;
            .item-game {
              margin-left: 3px;
              img {
                width: 100%;
              }
              p {
                margin: 4px 0;
              }
            }
          }
        }
      }
    }

    .fish-item {
      margin-bottom: 10px;

      img {
        border-radius: 8px 8px 0 0;
        height: calc(100% - 44px);
        min-height: 100px;
        background: #f3f3f3;
      }

      .item-inner {
        margin: -5px auto 0;
        padding: 3px 0;
        font-weight: 500;
        position: unset;
        background-color: #569add;
        width: calc(100% - 10px);
        border-radius: 0 0 8px 8px;
        min-height: 40px;
      }
    }
  }
}

.fab-contaienr {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  width: var(--f7-fab-size);
  height: var(--f7-fab-size);

  .bg-image {
    width: 50px;
    height: 50px;
  }

  .icon-close {
    position: absolute;
    top: -10px;
    right: -10px;
    width: 20px;
    height: 20px;
  }
}
.registerbtn {
  height: 20px;
  font-size: 14px;
  background: #5688f4;
  line-height: 20px;
  color: white;
  padding: 0 5px;
  border-radius: 5px;
  font-weight: 600;
}
.popupCloseBtn{
  display: inline-block;
  position: fixed;
  top: 10px;
  right: 10px;
  width: 25px;
  z-index: 100;
}
</style>
