<template>
  <div class="activity-deposit">
    <div class="title">
      <img :src="`static/images/yt999/activity/t_01_${$f7.params.currLang}.png`" :onerror="`this.onerror=''; src='../static/images/yt999/activity/t_01_enUS.png'`">
    </div>
    <div class="rules">
      <img class="ico" src="static/images/yt999/activity/8.png" alt="">
      <span class="label" @click="openDialog('dialog-deposit-rules')">{{ $t('activity_center_0002') }}</span>
    </div>
    <div class="task-list">
      <div class="task-item" v-for="(mission, p) of item.SettingList" :key="p">
        <div class="item-left">
          <div class="item-title">{{ $t('activity_center_0017', [mission.RequiredQuantity, mission.Amount]) }}</div>
          <div class="progress">
            <div class="progress-value">
              <span class="value">{{ mission.OwnQuantity > mission.RequiredQuantity ? mission.RequiredQuantity :  mission.OwnQuantity}}</span>
              /<span class="total">{{ mission.RequiredQuantity }}</span>
            </div>
            <div class="line" :style="`--percent: ${getProgressPercent(mission)}%`"></div>
          </div>
          <div class="desc">{{ getDesc(mission) }}</div>
        </div>
        <div class="item-right">
          <f7-button class="btn-receive" @click="receive(mission)" v-if="mission.OwnQuantity >= mission.RequiredQuantity && !mission.IsReceived">{{ $t('activity_center_0018') }}</f7-button>
          <f7-button class="btn-received" v-else-if="mission.OwnQuantity >= mission.RequiredQuantity && mission.IsReceived">{{ $t('activity_center_0005') }}</f7-button>
          <f7-button class="btn-details" v-else>{{ $t('activity_center_0006') }}</f7-button>
        </div>
      </div>
    </div>
    <div class="dialog dialog-rules" id="dialog-deposit-rules" @dialog:closed="$f7.$('.dialog-backdrop').css('background', 'transparent');" @dialog:open="$f7.$('.dialog-backdrop').css('background', 'rgba(0, 4, 7, 0.77)')">
      <div class="dialog-inner">
        <div class="dialog-title">
          {{ $t('activity_center_0008') }}
        </div>
        <div class="dialog-body">
          <ul class="list">
            <li class="item">{{ $t('activity_center_0019', [item.MissionTimeRangeType]) }}</li>
            <li class="item">{{ $t('activity_center_0010') }}</li>
            <li class="item">{{ $t('activity_center_0011', [getGameTypeLimitDesc(item.BonusAuditGameTypes)]) }}</li>
            <li class="item">{{ $t('activity_center_0020') }}</li>
            <li class="item">{{ $t('activity_center_0012') }}</li>
          </ul>
        </div>
        <div class="dialog-footer">
          <f7-link class="btn-close" @click="closeDialog('dialog-deposit-rules')">{{ $t('common_close') }}</f7-link>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { receiveBouns } from '@/axios/api'
export default {
  name: "TotalDepositBonus",
  props: {
    item: {
      type: Object,
      default() {
        return {
          SettingList: []
        }
      }
    }
  },
  data() {
    return {

    }
  },
  methods: {
    getDesc(item) {
      if (item.IsReceived) {
        return ''
      } else if (item.OwnQuantity >= item.RequiredQuantity) {
        return ''
      } else {
        return this.$t('activity_center_0021', [item.RequiredQuantity - item.OwnQuantity])
      }
    },
    getProgressPercent(item) {
      return Math.round(item.OwnQuantity / item.RequiredQuantity * 10000) / 100
    },
    async receive(item) {
      let loading = this.$f7.dialog.create({
        title: '',
        text: this.$t('activity_center_0013'),
        cssClass: "dialog-preloadgame",
      }).open();
      try {
        const res = await receiveBouns({ data: JSON.stringify({ missionID: item.MissionID, amount: item.Amount }) })
        this.$f7.dialog.alert(res.Data, this.$t('common_0004'));
        if (res.Status == 0) {
            this.$emit('receive')
        }
      } finally {
        loading.close();
      }
    },
    openDialog(id) {
      this.$f7.dialog.open(`#${id}`)
    },
    closeDialog(id) {
      this.$f7.dialog.close(`#${id}`)
    },
    getGameTypeLimitDesc(list) {
      if (!list) {
        return this.$t('activity_center_0014')
      } else {
        return list.map(gameType => gameType).join('/')
      }
    },
  }
}
</script>

<style lang="less">
.activity-deposit {
  position: relative;
  background: url("../../../static/images/yt999/activity/7.png");
  background-size: 100% 100vh;
  height: calc(100vh - 70px);
  .title {
    position: absolute;
    top: 0;
    img {
      width: 100%;
    }
  }
  .rules {
    display: flex;
    align-items: flex-end;
    justify-content: flex-start;
    height: 160px;
    padding: 0 16px;
    font-weight: bold;
    .ico {
      width: 18px;
      height: 18px;
      margin-right: 6px;
    }
    .label {
      color: #476af7;
    }
  }

  .task-list {
    background: #fff;
    margin: 16px;
    padding: 16px;
    border-radius: 8px;
    .task-group + .task-group {
      margin-top: 16px;
    }
    .task-item {
      background: url("../../../static/images/yt999/activity/6.png");
      background-size: 100% 100%;
      border-radius: 8px;
      padding: 8px;
      display: flex;
      align-items: center;
      + .task-item {
        margin-top: 8px;
      }
      .item-left {
        flex: 1;
        display: flex;
        flex-direction: column;
        height: 100%;
        .progress {
          width: 180px;
          flex: 1;
          margin-top: 6px;
          .progress-value {
            display: flex;
            align-items: center;
            font-size: 12px;
            color: #fff;
          }
          .line {
            position: relative;
            height: 6px;
            width: 100%;
            border-radius: 100px;
            overflow: hidden;
            &::before {
              content: "";
              position: absolute;
              left: 0;
              right: 0;
              height: 100%;
              background: #a52716;
              border-radius: 100px;
            }
            &::after {
              content: "";
              position: absolute;
              left: 0;
              height: 100%;
              width: var(--percent);
              background: #fef552;
              border-radius: 100px;
            }
          }
        }
      }
      .desc {
        font-size: 12px;
        color: #fff;
        margin-top: 6px;
      }
      .item-title {
        font-size: 14px;
        font-weight: bold;
        color: #fff;
      }

      .item-right {
        display: flex;
        flex-direction: column;
        align-items: center;
        color: #fff;
        .value {
          font-size: 16px;
        }
      }
    }
    .btn-details {
      background: rgba(#000, 0.1);
      border-radius: 100px;
    }
    .btn-receive {
      background: #faf34f;
      color: #ea3a29;
      border-radius: 100px;
      font-weight: bold;
    }
    .btn-received {
        background: rgba(#000, 0.1);
        border-radius: 100px;
    }
  }
}
</style>