import { render, staticRenderFns } from "./fish-list.vue?vue&type=template&id=2b2e7b63&scoped=true"
import script from "./fish-list.vue?vue&type=script&lang=js"
export * from "./fish-list.vue?vue&type=script&lang=js"
import style0 from "./fish-list.vue?vue&type=style&index=0&id=2b2e7b63&prod&lang=less&scoped=true"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2b2e7b63",
  null
  
)

export default component.exports