import axios from "axios";
import api from "../config/api";

axios.defaults.timeout = 15000;
axios.defaults.headers.post["Content-Type"] = "application/x-www-form-urlencoded;charset=UTF-8";
axios.defaults.headers.get["Content-Type"] = "application/x-www-form-urlencoded;charset=UTF-8";
axios.defaults.transformRequest = [
  function (data) {
    let ret = "";
    for (let it in data) {
      ret += encodeURIComponent(it) + "=" + encodeURIComponent(data[it]) + "&";
    }
    return ret;
  },
];

export const getInfo = (params) => {
  return axios.get(api.getBrand, { params: params }).then((res) => res.data);
};

export const login = (params) => {
  return axios.post(api.login, params).then((res) => res.data);
};

export const getCarouseladvertList = (params) => {
  return axios.post(api.getCarouseladvertList, params).then((res) => res.data);
};

export const getLampList = (params) => {
  return axios.get(api.getLamplist, { params: params }).then((res) => res.data);
};

export const getGameListp = (params) => {
  return axios.get(api.getGameListp, { params: params }).then((res) => res.data);
};

export const getGamebyhotlist = (params) => {
  return axios.post(api.getGamebyhotlist, { params: params}).then((res) => res.data);
}

export const getGameListByPId = (params) => {
  return axios.post(api.getGameListbyClass, params).then((res) => res.data);
};

export const getBalances = (params) => {
  return axios.post(api.getapibalances, params).then((res) => res.data);
};

export const getBalance = (params) => {
  return axios.post(api.getApibalance, params).then((res) => res.data);
};

export const getMainWallet = (params) => {
  return axios.post(api.mainwalletdetail, params).then((res) => res.data);
};

export const getGameEntry = (params) => {
  return axios.post(api.getgameentry, params).then((res) => res.data);
};

export const getValidProviders = (params) => {
  return axios.post(api.validproviders, params).then((res) => res.data);
};

export const recyclingFunds = (params) => {
  return axios.post(api.recyclingFunds, params).then((res) => res.data);
};

export const mainWalletToGame = (params) => {
  return axios.post(api.toGame, params).then((res) => res.data);
};

export const gameWalletToMain = (params) => {
  return axios.post(api.toBalance, params).then((res) => res.data);
};

export const getUserInfo = (params) => {
  return axios.post(api.getInfo, params).then((res) => res.data);
};

export const checkUserLogin = (params) => {
  return axios.post(api.checkUser, params).then((res) => res.data);
};

export const logout = (params) => {
  return axios.post(api.loginOut, params).then((res) => res.data);
};

export const checkPermissions = (params) => {
  return axios.post(api.checkPermissions, params).then((res) => res.data);
};

export const register = (params) => {
  return axios.post(api.register, params).then((res) => res.data);
};

export const mobileVerifyCode = (params) => {
  return axios.post(api.mobileVerify, params).then((res) => res.data);
};

export const getPromotionList = (params) => {
  return axios.post(api.getPromotionlist, params).then((res) => res.data);
};

export const getBonus = (params) => {
  return axios.post(api.getBonus, params).then((res) => res.data);
};

export const getActivityList = (params) => {
  return axios.post(api.getActivityList, params).then((res) => res.data);
};

export const getPromotionDetail = (params) => {
  return axios.post(api.getPromotionDetail, params).then((res) => res.data);
};

export const postPromotionDetail = (params) => {
  return axios.post(api.postPromitionDetail, params).then((res) => res.data);
};

export const getGameClassList = (params) => {
  return axios.post(api.getGameClassListc, params).then((res) => res.data);
};

export const getClassChildList = (params) => {
  return axios.post(api.getGameListbyClass, params).then((res) => res.data);
};

export const getValidList = (params) => {
  return axios.post(api.getvalidlist, params).then((res) => res.data);
};

export const getQrList = (params) => {
  return axios.post(api.getQR, params).then((res) => res.data);
};

export const payQR = (params) => {
  return axios.post(api.payQR, params).then((res) => res.data);
};

export const getOnlinePayList = (params) => {
  return axios.post(api.getPayAccList, params).then((res) => res.data);
};

export const onlinePayRequest = (params) => {
  return axios.post(api.onlinePay, params).then((res) => res.data);
};

export const checkBonusPointWallet = (params) => {
  return axios.post(api.postForPoints, params).then((res) => res.data);
};

export const userNameSignindetailDay = (params) => {
  return axios.post(api.getUsernameSignindetailDay, params).then((res) => res.data);
};

export const getPayBankList = (params) => {
  return axios.post(api.getPayBankList, params).then((res) => res.data);
};

export const requestBankList = (params) => {
  return axios.get(api.getBankList, { params: params }).then((res) => res.data);
};

export const payBankTransfer = (params) => {
  return axios.post(api.payBankPay, params).then((res) => res.data);
};

export const getWithdrawInfo = (params) => {
  return axios.post(api.getdrinfo, params).then((res) => res.data);
};

export const checkWithdraw = (params) => {
  return axios.post(api.checkwr, params).then((res) => res.data);
};

export const requestWithdraw = (params) => {
  return axios.post(api.memberWr, params).then((res) => res.data);
};

export const getTurnoverauditlist = (params) => {
  return axios.post(api.getTurnoverauditlist, params).then((res) => res.data);
};

export const getVipsigninsettinglist = (params) => {
  return axios.post(api.getVipsigninsettinglist, params).then((res) => res.data);
};

export const signIn = (params) => {
  return axios.post(api.postSingIn, params).then((res) => res.data);
};

export const getUsernameSignindetailDay = (params) => {
  return axios.post(api.getUsernameSignindetailDay, params).then((res) => res.data);
};

export const checkMonthlySalary = (params) => {
  return axios.post(api.checkMonthlySalary, params).then((res) => res.data);
};

export const getMonthlySalary = (params) => {
  return axios.post(api.getMonthlySalary, params).then((res) => res.data);
};

export const getBirthdayStatus = (params) => {
  return axios.post(api.getBirthdayStatus, params).then((res) => res.data);
};

export const getBirthdayBonus = (params) => {
  return axios.post(api.getBirthdayBonus, params).then((res) => res.data);
};

export const checkFestival = (params) => {
  return axios.post(api.checkFestival, params).then((res) => res.data);
};

export const getFestivalBonus = (params) => {
  return axios.post(api.getFestivalBonus, params).then((res) => res.data);
};

export const checkLevelup = (params) => {
  return axios.post(api.checkLevelup, params).then((res) => res.data);
};

export const doLevelup = (params) => {
  return axios.post(api.doLevelup, params).then((res) => res.data);
};

export const postForPoints = (params) => {
  return axios.post(api.postForPoints, params).then((res) => res.data);
};

export const getbonuspointgoodslist = (params) => {
  return axios.post(api.getbonuspointgoodslist, params).then((res) => res.data);
};

export const doBonuspointUse = (params) => {
  return axios.post(api.doBonuspointUse, params).then((res) => res.data);
};

export const getDailyTaskReviewList = (params) => {
  return axios.post(api.activitybonusauditusernameGet, params).then((res) => res.data);
};

export const getActivityAuditUserName = (params) => {
  return axios.post(api.getActivityAuditUserName, params).then((res) => res.data);
};

export const getDepositRecordsList = (params) => {
  return axios.post(api.usernameonlineandofflinepaymentget, params).then((res) => res.data);
};

export const getWithdrawRecordsList = (params) => {
  return axios.post(api.usernamewithdrawrecordget, params).then((res) => res.data);
};

export const getFundsRecordsList = (params) => {
  return axios.post(api.getFundsdetails, params).then((res) => res.data);
};

export const getIntegralRecordsList = (params) => {
  return axios.post(api.getBonuspointdetaillist, params).then((res) => res.data);
};

export const getProvidergame = (params) => {
  return axios.post(api.getProvidergame, params).then((res) => res.data);
};

export const getTransactionlist = (params) => {
  return axios.post(api.getTransactionlist, params).then((res) => res.data);
};

export const updateUserInfo = (params) => {
  return axios.post(api.updatepersonal, params).then((res) => res.data);
};

export const getBlankList = (params) => {
  return axios.post(api.getBlankList, params).then((res) => res.data);
};

export const bankbind = (params) => {
  return axios.post(api.bankbind, params).then((res) => res.data);
};

export const updateLoginPwd = (params) => {
  return axios.post(api.updateloginpwd, params).then((res) => res.data);
};

export const getFisheListbyClass = (params) => {
  return axios.post(api.getFisheListbyClass, params).then((res) => res.data);
};

export const updateSecPwd = (params) => {
  return axios.post(api.updatesecurepwd, params).then((res) => res.data);
};

export const getSecurityQuestionList = (params) => {
  return axios.post(api.getSecurityQuestionList, params).then((res) => res.data);
};

export const getSecurityQuestionStatus = (params) => {
  return axios.post(api.getSecurityQuestionStatus, params).then((res) => res.data);
};

export const setSecurityQuestionAnswer = (params) => {
  return axios.post(api.setSecurityQuestionAnswer, params).then((res) => res.data);
};

export const updateSecurityQuestionAnswer = (params) => {
  return axios.post(api.updateSecurityQuestionAnswer, params).then((res) => res.data);
};

export const getUserNewsList = (params) => {
  return axios.post(api.getUserNewsList, params).then((res) => res.data);
};

export const getMsgSystemList = (params) => {
  return axios.post(api.getMsgSystemList, params).then((res) => res.data);
};

export const alreadyRead = (params) => {
  return axios.post(api.alreadyRead, params).then((res) => res.data);
};

export const getPopupAd = (params) => {
  return axios.post(api.getLadvertList, params).then((res) => res.data);
};

export const checksecuritypass = (params) => {
  return axios.post(api.checksecuritypass, params).then((res) => res.data);
};

export const safetyboxbalance = (params) => {
  return axios.post(api.safetyboxbalance, params).then((res) => res.data);
};

export const safetyboxtransfer = (params) => {
  return axios.post(api.safetyboxtransfer, params).then((res) => res.data);
};

export const safetyboxtransferhistorymemeberlist = (params) => {
  return axios.post(api.safetyboxtransferhistorymemeberlist, params).then((res) => res.data);
};

export const agentRegister = (params) => {
  return axios.post(api.agentRegister, params).then((res) => res.data);
};

export const getMemberBalance = (params) => {
  return axios.post(api.getMemberBalance, params).then((res) => res.data);
};

export const getHotGameLists = (params) => {
  return axios.post(api.hotGameLists, params).then((res) => res.data);
};

export const referrerinfo = (params) => {
  return axios.post(api.referrerinfo, params).then((res) => res.data);
};

export const commisionforecast = (params) => {
  return axios.post(api.commisionforecast, params).then((res) => res.data);
};

export const referreramounttomainwallet = (params) => {
  return axios.post(api.referreramounttomainwallet, params).then((res) => res.data);
};

export const referrercommision = (params) => {
  return axios.post(api.referrercommision, params).then((res) => res.data);
};

export const referrerdownlinememberlist = (params) => {
  return axios.post(api.referrerdownlinememberlist, params).then((res) => res.data);
};

export const referrertransferhistorylist = (params) => {
  return axios.post(api.referrertransferhistorylist, params).then((res) => res.data);
};

export const referrerDownlineMemberEnterpriseList = (params) => {
  return axios.post(api.referrerDownlineMemberEnterpriseList, params).then((res) => res.data);
};

export const referrerdownlinememberenterprisedetaillist = (params) => {
  return axios.post(api.referrerdownlinememberenterprisedetaillist, params).then((res) => res.data);
};

export const getCurrencyList = (params) => {
  return axios.post(api.getCurrencyList, params).then((res) => res.data);
};

export const getmemberwinloserp = (params) => {
  return axios.post(api.memberwinloserp, params).then((res) => res.data);
};

export const referralplandetail = (_) => {
  return axios.post(api.referralplandetail, {}).then((res) => res.data);
};

export const onlineBankSumbit = (params) => {
  return axios.post(api.onlineBankSumbit, params).then((res) => res.data);
};

export const checkSecurePwdStatus = (params) => {
  return axios.post(api.checkSecurePwdStatus, params).then((res) => res.data);
};

export const getMissionList = (params) => {
  return axios.post(api.getmissionlist, params).then((res) => res.data);
};

export const receiveBouns = (params) => {
  return axios.post(api.receiveBouns, params).then((res) => res.data);
};

export const depositqalist = (_) => {
  return axios.post(api.depositqalist, {}).then((res) => res.data);
};

export const getonlinecslist = (_) => {
  return axios.post(api.onlinecslist, {}).then((res) => res.data);
};

export const countryCodeListGet = (_) => {
  return axios.post(api.countrycodelistget, {}).then((res) => res.data);
};
