<template>
  <f7-page no-toolbar no-swipeback name="betting-records" class="page-betting-records" infinite :infinite-distance="50" :infinite-preloader="showPreloader" @infinite="loadMore">
    <f7-navbar :title="$t('betting_records_0001')" back-link :no-hariline="true" innerClass="inner-betting">
      <f7-nav-right>
        <f7-link>
          <f7-button outline class="button-total" @click="openBettingDialog">{{ $t("common_0032") }}</f7-button>
        </f7-link>
      </f7-nav-right>
    </f7-navbar>
    <div class="block accordion-list block-records" v-if="bettingList.length > 0">
      <div class="accordion-item" v-for="item in formatBettingList" :key="item.ProviderTicketNo">
        <div class="accordion-item-toggle">
          <div class="row no-gap">
            <div class="col-50">{{ item.ProviderName }} - {{ item.GameName }}</div>
            <div class="col-50 text-right">{{ item.BetTs | time_local }}</div>
          </div>
          <div class="row no-gap">
            <div class="col-80">{{ $t("betting_records_0002") }} {{ item.ProviderTicketNo }}</div>
            <div class="col-20 text-right">
              (
              <span v-if="item.WLStatus == 'P'" class="col-yellow">{{ $t("betting_records_0003") }}</span>
              <span v-if="item.WLStatus == 'C'" class="col-green">{{ $t("betting_records_0004") }}</span>
              <span v-if="item.WLStatus == 'D'" class="col-green">{{ $t("betting_records_0005") }}</span>
              <span v-if="item.WLStatus == 'R'" class="col-red">{{ $t("betting_records_0006") }}</span>
              <span v-if="item.WLStatus == 'WA'" class="col-green">{{ $t("betting_records_0007") }}</span>
              <span v-if="item.WLStatus == 'WH'" class="col-green">{{ $t("betting_records_0008") }}</span>
              <span v-if="item.WLStatus == 'LA'" class="col-red">{{ $t("betting_records_0009") }}</span>
              <span v-if="item.WLStatus == 'LH'" class="col-red">{{ $t("betting_records_0010") }}</span>
              <span v-if="item.WLStatus == '其他'" class="col-red">{{ $t("betting_records_0011") }}</span>
              )
            </div>
          </div>
          <div class="row no-gap">
            <div class="col-80">
              {{ $t("betting_records_0012") }}({{ item.BetAmount | formatCurrency }}) {{ $t("betting_records_0013") }}(
              <span :class="item.WLAmount > 0 ? 'col-green' : 'col-red'">{{ item.WLAmount | formatCurrency }}</span>
              )
            </div>
            <div class="col-20 text-right col-detail">
              {{ $t("betting_records_0014") }}
              <i class="f7-icons">chevron_right</i>
            </div>
          </div>
        </div>
        <div class="accordion-item-content">
          <p class="text-center">{{ $t("betting_records_0015") }}({{ item.EffectiveAmount | formatCurrency }})</p>
          <div class="card card-outline">
            <div class="card-content">
              <!-- <span v-if="item.ProviderId == 1" v-html="$options.filters.T(item.BetPosition)"></span>
              <span v-if="item.ProviderId == 3" v-html="item.betSource"></span>
              <span v-if="item.ProviderId == 4" v-html="$options.filters.OG(item.BetPosition)"></span>
              <span v-if="item.ProviderId == 16" v-html="$options.filters.GD(item.BetPosition)"></span>
              <span v-if="item.ProviderId == 18" v-html="$options.filters.AB(item.BetPosition)"></span>
              <span v-if="item.ProviderId == 24" v-html="$options.filters.EB(item.BetPosition)"></span>
              <span v-if="item.ProviderId == 13" v-html="$options.filters.SB(item.BetPosition)"></span>
              <span
                v-if="item.ProviderId == 30&&item.ProviderGameCode!='AGIN_TASSPTA'"
                v-html="$options.filters.AG(item.BetPosition)"
              ></span>
              <span v-if="item.ProviderId == 25" v-html="$options.filters.VR(item.BetPosition)"></span>
              <span
                v-if="item.ProviderId == 30&&item.ProviderGameCode=='AGIN_TASSPTA'"
                v-html="item.BetPositionFmt"
              ></span>
              <span v-if="item.ProviderId == 14" v-html="$options.filters.IM(item.BetPosition)"></span>
              <span v-if="item.ProviderId == 15" v-html="$options.filters.VIR(item.BetPosition)"></span>
              <span v-if="item.ProviderId == 31" v-html="$options.filters.KK(item.BetPosition)"></span>
              <span v-if="item.ProviderId == 505" v-html="$options.filters.BBIN(item.BetPosition)"></span>
              <span v-if="item.ProviderId == 504" v-html="$options.filters.MG(item.BetPosition)"></span>
              <span v-if="item.ProviderId == 509" v-html="$options.filters.BG(item.BetPosition)"></span>
              <span v-if="item.ProviderId == 510" v-html="$options.filters.FY(item.BetPosition)"></span>
              <span v-if="item.ProviderId == 511" v-html="$options.filters.SEXY(item.BetPosition)"></span>
              <span v-if="item.ProviderId == 513" v-html="$options.filters.N2(item.BetPosition)"></span>
              <span v-if="item.ProviderId == 514" v-html="$options.filters.SingBetSports(item.BetPosition)"></span>
              <span v-if="item.ProviderId == 517" v-html="$options.filters.WM(item.BetPosition)"></span>
              <span v-if="item.ProviderId == 521" v-html="$options.filters.IBCSports(item.BetPosition)"></span>
              <span
                class="no-detail"
                v-if="item.ProviderId == 20 || item.ProviderId == 22 || item.ProviderId == 23 || item.ProviderId == 19 || item.ProviderId == 6 || item.ProviderId == 2 || item.ProviderId == 12 || item.ProviderId == 503"
              >{{ $t('common_0033') }}</span> -->
              <span class="no-detail">--</span>
            </div>
          </div>
          <p class="text-center">{{ $t("betting_records_0016") }}：{{ item.ResultTs | time_local }}</p>
        </div>
      </div>
    </div>

    <f7-card v-else-if="bettingList.length <= 0 && !showDataBetting" class="no-data">
      <img :src="`../../../static/images/yt999/noData_${$f7.params.currLang}.png`" width="45%" :onerror="`this.onerror=''; src='../../../static/images/yt999/noData_enUS.png'`" alt="No data" />
    </f7-card>

    <div class="dialog dialog-balance dialog-betting dialog-move" @dialog:closed="actionBettingTotalCloseEvent" @dialog:open="actionBettingTotalOpenEvent">
      <div class="dialog-inner">
        <div class="dialog-title">
          {{ $t("common_0032") }}
          <f7-link @click="closeDialogBalance">
            <i class="f7-icons">chevron_right</i>
          </f7-link>
        </div>
        <div class="dialog-text">
          <f7-block class="block-balance">
            <div class="block-content">
              <f7-row class="item-balance">
                <f7-col width="50">{{ $t("betting_records_0017") }}</f7-col>
                <f7-col width="50" class="col-balance">{{ pageTotal }}</f7-col>
              </f7-row>
              <f7-row class="item-balance">
                <f7-col width="50">{{ $t("betting_records_0018") }}</f7-col>
                <f7-col width="50" class="col-balance">{{ totalBetAmount | formatCurrency }}</f7-col>
              </f7-row>
              <f7-row class="item-balance">
                <f7-col width="50">{{ $t("betting_records_0015") }}</f7-col>
                <f7-col width="50" class="col-balance">{{ totalEffectiveAmount | formatCurrency }}</f7-col>
              </f7-row>
              <f7-row class="item-balance">
                <f7-col width="50">{{ $t("betting_records_0019") }}</f7-col>
                <f7-col width="50" class="col-balance">{{ totalWLAmount | formatCurrency }}</f7-col>
              </f7-row>
            </div>
          </f7-block>
        </div>
      </div>
    </div>
  </f7-page>
</template>

<script>
import { getTransactionlist } from "../../../axios/api";
import common from "../../../service/common";

export default {
  components: {},
  props: {},
  data() {
    return {
      bettingList: [],
      allowInfinite: true,
      showPreloader: true,
      pageNow: 1,
      pageTotal: 0,
      totalBetAmount: 0,
      totalEffectiveAmount: 0,
      totalWLAmount: 0,
      showDataBetting: true,
    };
  },
  watch: {},
  computed: {
    formatBettingList() {
      if (!this.bettingList.length) return this.bettingList;
      let betList = this.bettingList;
      betList.forEach((item) => {
        if (item.ProviderId == 3) {
          var betInfo = item.BetPosition.split("^"),
            isFH = betInfo[2].toLowerCase() == "true",
            isHomeGive = betInfo[3].toLowerCase() == "true",
            isBetHome = betInfo[4].toLowerCase() == "true",
            hdp = Math.abs(new Number(betInfo[5])),
            odds = new Number(betInfo[6]),
            runHomeScore = parseInt(betInfo[9]),
            runAwayScore = parseInt(betInfo[10]),
            isRun = betInfo[11].toLowerCase() == "true",
            choice = new Number(betInfo[12]),
            oddsType = betInfo[13],
            matchDate = betInfo[14],
            isCashOut = betInfo[19].toLowerCase() == "true",
            soldTicketMoney = new Number(betInfo[20]),
            tradeInRiskWin = new Number(betInfo[21]),
            extend = betInfo[23],
            percent = new Number(betInfo[24]),
            transType = betInfo[26],
            sportType = betInfo[27],
            leagueName = betInfo[29],
            home = betInfo[31],
            away = betInfo[32];

          var otype = transType;
          if (otype == "HDP" || otype == "OU" || otype == "OE") {
            otype = oddsType;
          } else {
            otype = "DE";
          }
          var ootype = otype.trim() == "MR" ? "MMR" : otype;
          var odd = ootype == "MMR" ? odds : odds.toFixed(3);
          var betType = common.publicFile.betTypes[transType];
          var isPar = betType == "PAR";
          var parOdds = 0;
          if (isPar && item.ParlayTransaction.length > 0) {
            var betSource = "";
            item.ParlayTransaction.forEach(function (item1, key) {
              var parBetInfo = item1.ParBetPosition;
              var parBet = {
                a: parBetInfo[2],
                league: parBetInfo[4],
                home: parBetInfo[5],
                away: parBetInfo[6],
              };
              if (key != item.ParlayTransaction.length - 1) {
                var br = "<br/><br/>";
              } else {
                var br = "";
              }
              betSource += common.getBetSource(oddsType, item1.Choice, item1.IsFH, publicFile.betTypes[item1.ParTransType], Math.abs(item1.HDP), item1.IsHomeGive, item1.IsBetHome, parBet.home, parBet.away, item1.IsRun, item1.HomeScore + "-" + item1.AwayScore, item1.ParOdds, isPar, item1.TransType, percent, parBet.league, matchDate, item1.HTScore, item1.FTScore, item1.ParDangerStatus, extend) + br;
            });

            item.betSource = betSource;
          } else {
            item.betSource = common.getBetSource(oddsType, choice, isFH, betType, hdp, isHomeGive, isBetHome, home, away, isRun, runHomeScore + "-" + runAwayScore, 0, isPar, transType, percent, leagueName, matchDate, "", "", "", extend);
          }
          item.odds = odd;
          item.ootype = ootype;
          item.isCashOut = isCashOut;
          item.isRun = isRun;
          item.status = common.publicFile.sportsStatus[item.WLStatus];
          item.tradeInRiskWin = tradeInRiskWin;
          item.soldTicketMoney = soldTicketMoney;
          item.sportsTypeName = common.publicFile.sportsType[sportType];
        }

        if (item.ProviderId == 30 && item.ProviderGameCode == "AGIN_TASSPTA") {
          // var btdata=item.BetPosition.split('^');
          let dat = JSON.parse(item.BetPosition.match(/\{([\s\S]*)\}/)[0]);
          var data = JSON.parse(dat.remark).detail[0];
          if (data) {
            var str1 = "";
            if (item.WLStatus.indexOf("输") > -1) {
              if (data.settlementResult) {
                str1 = '<span style="color:#0275d8">' + data.selection[0].name + "-<span >" + data.odds + "</span></span>[" + data.settlementResult[0].name + "]<br>";
              } else {
                str1 = '<span style="color:#0275d8">' + data.selection[0].name + "-<span >" + data.odds + "</span></span><br>";
              }
            } else if (item.WLStatus.indexOf("赢") > -1) {
              if (data.settlementResult) {
                str1 = '<span style="color:#d9534f">' + data.selection[0].name + "-<span >" + data.odds + "</span></span>[" + data.settlementResult[0].name + "]<br>";
              } else {
                str1 = '<span style="color:#d9534f">' + data.selection[0].name + "-<span >" + data.odds + "</span></span><br>";
              }
            } else {
              if (data.settlementResult) {
                str1 = "<span>" + data.selection[0].name + "-<span >" + data.odds + "</span></span>[" + data.settlementResult[0].name + "]<br>";
              } else {
                str1 = "<span>" + data.selection[0].name + "-<span >" + data.odds + "</span></span><br>";
              }
            }
            data = str1 + "<span>" + data.market[0].name + "</span><br>" + "<span>" + data.event[0].name + "</span><br>" + "<span>" + data.competition[0].name + "</span><br>";
          } else {
            data = "";
          }
          item.BetPositionFmt = data;
          // return data;
        }
      });
      return betList;
    },
  },
  methods: {
    getBettingListEvent() {
      const self = this;
      let queryParam = JSON.parse(self.$f7route.params.Info);
      queryParam.BeginDate = Math.round(new Date(queryParam.BeginDate.replace(/-/g, "/")).getTime() / 1000);
      queryParam.EndDate = Math.round(new Date(queryParam.EndDate.replace(/-/g, "/")).getTime() / 1000);
      queryParam.PageNow = self.pageNow;
      queryParam.PageSize = 10;

      const params = { data: JSON.stringify(queryParam) };
      getTransactionlist(params).then((data) => {
        if (data.Code === "NoError") {
          self.bettingList = self.bettingList.concat(data.Data.Data);
          self.showDataBetting = self.bettingList.length > 0;
          self.pageTotal = data.Data.Total;
          self.totalBetAmount = data.Data.TotalBetAmount;
          self.totalEffectiveAmount = data.Data.TotalEffectiveAmount;
          self.totalWLAmount = data.Data.TotalWLAmount;
          if (self.bettingList.length >= self.pageTotal) {
            self.showPreloader = false;
          }
        }
      });
    },
    loadMore() {
      const self = this;
      if (!self.allowInfinite) return;
      self.allowInfinite = false;

      setTimeout(() => {
        if (self.bettingList.length >= self.pageTotal) {
          self.showPreloader = false;
          return;
        }
        self.pageNow += 1;
        self.getBettingListEvent();
        self.allowInfinite = true;
      }, 1000);
    },
    actionBettingTotalOpenEvent() {
      this.$f7.$(".dialog-backdrop").css("background", "rgba(0, 4, 7, 0.77)");
    },
    actionBettingTotalCloseEvent() {
      this.$f7.$(".dialog-backdrop").css("background", "transparent");
    },
    closeDialogBalance() {
      this.$f7.dialog.close(".dialog-betting");
    },
    openBettingDialog() {
      this.$f7.dialog.open(".dialog-betting");
    },
  },
  created() {
    this.getBettingListEvent();
  },
  mounted() {},
};
</script>
<style lang="less" scoped></style>
