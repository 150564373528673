<template>
  <f7-page @page:init="onPageInit" @page:beforein="onPageBeforeIn" @page:beforeout="onPageBeforeOut" class="page-member-center" name="member-center">
    <f7-navbar :title="$t('member_center_0001')" :back-link="false" :no-hariline="true"></f7-navbar>
    <f7-card class="card-user-info" v-if="getLoginState">
      <f7-card-content>
        <f7-row no-gap class="row-level">
          <f7-col width="35" class="user-vip-img text-center">
            <img :src="userInfo.VipLevelUrl" v-if="userInfo.VipLevelUrl" alt="" />
            <!-- <img src="../../static/images/yt999/vip/x-vip.png" v-else-if="!/vip\d/i.test(userInfo.vipLevelCode)" alt=""> -->
            <img v-else :src="`../../static/images/yt999/vip/vip.png`" alt />
          </f7-col>
          <f7-col width="35">
            <div class="user-name">{{ userInfo.UserName | formatUserName }}</div>
            <div class="user-level">{{ userInfo.VipLevelName }}</div>
          </f7-col>
          <f7-col width="30"></f7-col>
        </f7-row>
        <div class="position-user-level">{{ userInfo.VipLevelName }}</div>

        <div class="panel-login-info">
          <f7-row no-gap>
            <f7-col width="100" class="text-left welcome">{{ $t("member_center_0002") }}</f7-col>
          </f7-row>
          <f7-row no-gap>
            <f7-col width="35" class="text-left">{{ $t("member_center_0003") }}</f7-col>
            <f7-col width="65" class="text-right">{{ getLoginInfo.LastLoginTs | time_local }}</f7-col>
          </f7-row>
          <!-- <f7-row no-gap>
            <f7-col width="35" class="text-left">登录区域</f7-col>
            <f7-col width="65" class="text-right">{{getLoginInfo.LastLoginAddress}}</f7-col>
          </f7-row> -->
          <f7-row no-gap class="row-totalAmt">
            <f7-col width="35" class="text-left">{{ $t("member_center_0004") }}</f7-col>
            <f7-col width="65" class="text-right">
              <f7-link popup-open=".balanceInfo-popup" v-if="totalBalance > -1">
                <span class="color-yellow">{{ userInfo.currencySymbol }}&nbsp;{{ totalBalance | formatCurrency }}</span>
                <i class="f7-icons">chevron_right</i>
              </f7-link>
              <preloader :list="4" v-else></preloader>
            </f7-col>
          </f7-row>
        </div>
      </f7-card-content>
    </f7-card>
    <f7-card class="card-no-login" v-if="!getLoginState">
      <f7-button outline href="/login/">{{ $t("member_center_0005") }}</f7-button>
    </f7-card>
    <div class="buttons-tab tabs">
      <f7-link tab-link="#tab-accountCenter" tab-link-active icon="iconfont icon-huiyuanvip" :text="$t('member_center_0006')"></f7-link>
      <f7-link tab-link="#tab-selfHelpPrefer" icon="iconfont icon-mendianzhuanxiangshenhe" :text="$t('member_center_0007')"></f7-link>
      <f7-link tab-link="#tab-accountList" icon="iconfont icon-qingdan" :text="$t('member_center_0008')"></f7-link>
      <f7-link tab-link="#tab-personalInfo" icon="iconfont icon-gerenziliao1" :text="$t('member_center_0009')"></f7-link>
      <f7-link tab-link="#tab-message">
        <f7-icon icon="iconfont icon-youxiang">
          <f7-badge color="red" v-if="userMessgaeList.length > 0"></f7-badge>
        </f7-icon>
        <span class="tabbar-label">{{ $t("member_center_0010") }}</span>
      </f7-link>
    </div>
    <f7-tabs class="tabs-member-center">
      <f7-tab @tab:show="tabInit(1)" id="tab-accountCenter" class="page-content" tab-active>
        <f7-block>
          <f7-list>
            <f7-list-item link="/member-center/referrer/" :title="$t('member_center_0011')" class="icon-animation">
              <f7-icon slot="media" icon="iconfont icon-VIP"></f7-icon>
            </f7-list-item>
            <f7-list-item link="/member-center/winloserp/" :title="$t('member_center_0012')">
              <f7-icon slot="media" icon="iconfont icon-qingdan" style="color: #e63636"></f7-icon>
            </f7-list-item>
            <f7-list-item link="/member-center/deposit/" :title="$t('member_center_0013')" class="icon-color">
              <f7-icon slot="media" icon="iconfont icon-cunkuan"></f7-icon>
            </f7-list-item>
            <f7-list-item link="/member-center/withdraw/" :title="$t('member_center_0014')" class="icon-color">
              <f7-icon slot="media" icon="iconfont icon-qukuan"></f7-icon>
            </f7-list-item>
            <f7-list-item link="/member-center/transfer/" :title="$t('member_center_0015')" class="icon-color">
              <f7-icon slot="media" icon="iconfont icon-zhuanzhang"></f7-icon>
            </f7-list-item>
            <!-- <f7-list-item link="/member-center/safe/" title="保险箱" class="icon-color">
              <f7-icon slot="media" icon="iconfont icon-zhuanzhang"></f7-icon>
            </f7-list-item> -->
            <f7-list-item link="/member-center/vip-club/" class="vip" :title="$t('member_center_0016')" :after="$t('member_center_0017', [$f7.params.name])">
              <f7-icon slot="media" icon="iconfont icon--vip-"></f7-icon>
            </f7-list-item>
            <f7-list-item link="#" @click="openChangeLanguagePopup" :title="$t('member_center_0018')">
              <f7-icon slot="media" icon="iconfont icon-yuyan"></f7-icon>
            </f7-list-item>
            <f7-list-item link="#" @click="logout" :href="false" :title="$t('member_center_0019')" v-if="getLoginState">
              <f7-icon slot="media" icon="iconfont icon-iconfonttuijianren"></f7-icon>
            </f7-list-item>
          </f7-list>
        </f7-block>
      </f7-tab>
      <f7-tab @tab:show="tabInit(2)" id="tab-selfHelpPrefer" class="page-content">
        <f7-block>
          <f7-list>
            <f7-list-item link="/member-center/sign-in/" :title="$t('member_center_0020')" class="icon-animation">
              <f7-icon slot="media" icon="iconfont icon-qiandao"></f7-icon>
            </f7-list-item>
            <!-- <f7-list-item link="/member-center/vip-monthly-salary/" :title="$t('member_center_0021')" class="icon-animation">
              <f7-icon slot="media" icon="iconfont icon-a-huiyuanVIPVIPka"></f7-icon>
            </f7-list-item>
            <f7-list-item link="/member-center/vip-birthday-gift/" :title="$t('member_center_0022')" class="icon-animation">
              <f7-icon slot="media" icon="iconfont icon-a-huiyuanVIP"></f7-icon>
            </f7-list-item>
            <f7-list-item link="/member-center/vip-holiday-gift/" :title="$t('member_center_0023')" class="icon-animation">
              <f7-icon slot="media" icon="iconfont icon-Giftliwu1"></f7-icon>
            </f7-list-item> -->
            <f7-list-item link="/member-center/buffet-promotion/" :title="$t('member_center_0024')" class="icon-animation">
              <f7-icon slot="media" icon="iconfont icon-reshengji"></f7-icon>
            </f7-list-item>
            <!-- <f7-list-item link="/member-center/point-center/" :title="$t('member_center_0025')" class="icon-animation">
              <f7-icon slot="media" icon="iconfont icon-jifen"></f7-icon>
            </f7-list-item> -->
            <!-- <f7-list-item link="/member-center/daily-task-search/" :title="$t('member_center_0026')">
              <f7-icon slot="media" icon="iconfont icon-check"></f7-icon>
            </f7-list-item> -->
            <f7-list-item link="/member-center/activities-search/" :title="$t('member_center_0027')">
              <f7-icon slot="media" icon="iconfont icon-check"></f7-icon>
            </f7-list-item>
          </f7-list>
        </f7-block>
      </f7-tab>
      <f7-tab @tab:show="tabInit(3)" id="tab-accountList" class="page-content">
        <f7-block>
          <f7-list>
            <f7-list-item link="/member-center/deposit-search/" :title="$t('member_center_0028')">
              <f7-icon slot="media" icon="iconfont icon-churukujilu"></f7-icon>
            </f7-list-item>
            <f7-list-item link="/member-center/withdraw-search/" :title="$t('member_center_0029')">
              <f7-icon slot="media" icon="iconfont icon-churukujilu"></f7-icon>
            </f7-list-item>
            <f7-list-item link="/member-center/funds-search/" :title="$t('member_center_0030')">
              <f7-icon slot="media" icon="iconfont icon-churukujilu"></f7-icon>
            </f7-list-item>
            <f7-list-item link="/member-center/integral-search/" :title="$t('member_center_0031')">
              <f7-icon slot="media" icon="iconfont icon-jifenmingxi1"></f7-icon>
            </f7-list-item>
            <f7-list-item link="/member-center/activity-search/" :title="$t('member_center_0032')">
              <f7-icon slot="media" icon="iconfont icon-Giftliwu1"></f7-icon>
            </f7-list-item>
            <f7-list-item link="/member-center/betting-search/" :title="$t('member_center_0033')">
              <f7-icon slot="media" icon="iconfont icon-jilu"></f7-icon>
            </f7-list-item>
          </f7-list>
        </f7-block>
      </f7-tab>
      <f7-tab @tab:show="tabInit(4)" id="tab-personalInfo" class="page-content">
        <f7-block>
          <f7-list>
            <f7-list-item link="/member-center/member-info/" :title="$t('member_center_0034')">
              <f7-icon slot="media" icon="iconfont icon-yonghuxinxi"></f7-icon>
            </f7-list-item>
            <f7-list-item link="/member-center/bank-info/1/" :title="$t('member_center_0035')">
              <f7-icon slot="media" icon="iconfont icon-yinhangqia"></f7-icon>
            </f7-list-item>
            <f7-list-item link="/member-center/change-login-pwd/" :title="$t('member_center_0043')">
              <f7-icon slot="media" icon="iconfont icon-xiugaimima"></f7-icon>
            </f7-list-item>
            <f7-list-item link="/member-center/change-security-pwd/" :title="$t('member_center_0044')">
              <f7-icon slot="media" icon="iconfont icon-xiugaimima"></f7-icon>
            </f7-list-item>
            <!-- <f7-list-item link="/member-center/set-encrypted/" title="设置密保">
              <f7-icon slot="media" icon="iconfont icon-yanzhengma1"></f7-icon>
            </f7-list-item> -->
          </f7-list>
        </f7-block>
      </f7-tab>
      <f7-tab @tab:show="tabInit(5)" id="tab-message" class="page-content">
        <f7-block>
          <f7-list>
            <f7-list-item link="/member-center/message-center/1/" :title="$t('member_center_0037')">
              <f7-icon slot="media" icon="iconfont icon-gonggao"></f7-icon>
            </f7-list-item>
            <f7-list-item link="/member-center/message-center/2/" :title="$t('message_center_0003')" :badge="userMessgaeList.length">
              <f7-icon slot="media" icon="iconfont icon-a-yonghuxinxigerenziliao"></f7-icon>
            </f7-list-item>
          </f7-list>
        </f7-block>
      </f7-tab>
    </f7-tabs>   

    <f7-popup v-show="showBalanceInfoPopup" class="balanceInfo-popup" :opened="balancePopupOpened" @popup:open="initBalanceInfo" @popup:close="closedBalanceInfo">
      <f7-page>
        <f7-navbar :title="$t('member_center_0004')">
          <f7-nav-right>
            <f7-link popup-close>
              <i class="f7-icons">multiply_circle</i>
            </f7-link>
          </f7-nav-right>
        </f7-navbar>
        <f7-block>
          <f7-row no-gap class="block-balance">
            <f7-col class="text-left">{{ $t("member_center_0038") }}</f7-col>
            <f7-col class="text-right">{{ userInfo.currencySymbol }}{{ totalBalance | formatCurrency }}</f7-col>
          </f7-row>
          <f7-row no-gap class="block-balance">
            <f7-col class="text-left">{{ $t("member_center_0039") }}</f7-col>
            <f7-col class="text-right">{{ userInfo.currencySymbol }}{{ walletBalance | formatCurrency }}</f7-col>
          </f7-row>
          <f7-row no-gap class="block-balance">
            <f7-col class="text-left">{{ $t("member_center_0040") }}</f7-col>
            <f7-col class="text-right">{{ userInfo.currencySymbol }}{{ paddingBalance | formatCurrency }}</f7-col>
          </f7-row>
          <f7-row no-gap class="block-sign">
            <f7-col class="text-left">{{ $t("member_center_0041") }}</f7-col>
            <f7-col class="text-right">{{ userInfo.currencySymbol }}{{ userSignAmount | formatCurrency }}</f7-col>
          </f7-row>
          <f7-row no-gap class="block-point">
            <f7-col class="text-left">{{ $t("member_center_0042") }}</f7-col>
            <f7-col class="text-right">{{ currentBonusPoint | formatCurrency }}</f7-col>
          </f7-row>
        </f7-block>
      </f7-page>
    </f7-popup>
  </f7-page>
</template>

<script>
import preloader from "../template/preloader";
import api from "../../config/api";
import common from "../../service/common";
import { setCookie, getCookie, getStore, removeStore, setStore } from "../../config/utils";
import { mapGetters, mapMutations, mapActions } from "vuex";
import { getUserInfo, getBalances, logout, checkBonusPointWallet, userNameSignindetailDay, getUserNewsList } from "../../axios/api";
import http from "../../axios/http";

export default {
  components: {
    preloader,
  },
  props: {},
  data() {
    return {
      totalBalance: -1,
      paddingBalance: 0,
      walletBalance: 0,
      currentBonusPoint: 0,
      userSignAmount: 0,
      balancePopupOpened: false,
      userMessgaeList: [],
      showBalanceInfoPopup: true,
    };
  },
  watch: {},
  computed: {
    ...mapGetters(["userInfo", "getLoginInfo", "getLoginState"]),
  },
  methods: {
    ...mapMutations(["SETALLBALANCES"]),
    ...mapActions(["saveLoginState", "getUserInfo"]),
    getAllBalances() {
      const self = this;
      getBalances().then((data) => {
        if (data.Code === "NoError") {
          self.SETALLBALANCES(data.Data);
          self.totalBalance = data.Data[1];
          self.paddingBalance = data.Data[2];
          self.walletBalance = data.Data[3];
        }
      });
    },
    openChangeLanguagePopup() {
      const self = this;
      self.$f7.params.dialog.closeByBackdropClick = true; //点击背景关闭
      self.$f7.dialog.open("#dialog-change-language", true);
    },
    logout() {
      const self = this;
      self.$f7.dialog
        .create({
          title: this.$t("common_0004"),
          text: this.$t("member_center_0045"),
          cssClass: "dialog-logout",
          buttons: [
            {
              text: this.$t("common_0002"),
              cssClass: "dialog-cancel",
              close: true,
            },
            {
              text: this.$t("common_0001"),
              cssClass: "dialog-ok",
              onClick: () => {
                logout().then((data) => {
                  common.logoutEvent(self);
                  self.saveLoginState();
                  self.$f7router.navigate("/");
                });
              },
            },
          ],
        })
        .open();
    },
    initBalanceInfo() {
      const self = this;
      self.showBalanceInfoPopup = true;
      checkBonusPointWallet().then((data) => {
        if (data.Code === "NoError") {
          self.currentBonusPoint = data.Data.CurrentBonusPoint;
        }
      });
      const param = { data: JSON.stringify({ Choose: false }) };
      userNameSignindetailDay(param).then((data) => {
        if (data.Code === "NoError") {
          self.userSignAmount = data.Data[1];
        }
      });
    },
    userMessgaeListEvent() {
      const self = this;
      const param = {
        data: JSON.stringify({
          Status: 2,
          BeginDate: null,
          EndDate: null,
          PageNow: 1,
          PageSize: "100",
        }),
      };
      getUserNewsList(param).then((data) => {
        if (data.Code === "NoError") {
          self.userMessgaeList = data.Data.Data;
        }
      });
    },
    tabInit(index) {
      setStore("mc-tab-active", index);
    },
    onPageInit() {
      this.saveLoginState();
      if (this.getLoginState) {
        this.getUserInfo();
        this.getAllBalances();
        this.userMessgaeListEvent();
      }
    },
    onPageBeforeIn() {
      const self = this;
      self.$nextTick(() => {
        self.$f7.emit("myCustomEvent", "member-center");
        const tabActiveIndex = getStore("mc-tab-active") || 1;
        if (tabActiveIndex == 1) {
          self.$f7.tab.show("#tab-accountCenter");
        }
        if (tabActiveIndex == 2) {
          self.$f7.tab.show("#tab-selfHelpPrefer");
        }
        if (tabActiveIndex == 3) {
          self.$f7.tab.show("#tab-accountList");
        }
        if (tabActiveIndex == 4) {
          self.$f7.tab.show("#tab-personalInfo");
        }
        if (tabActiveIndex == 5) {
          self.$f7.tab.show("#tab-message");
        }
      });
    },
    onPageBeforeOut() {
      //removeStore('mc-tab-active');
    },
    closedBalanceInfo() {
      this.balancePopupOpened = false;
      this.showBalanceInfoPopup = false;
    },
  },
  created() {},
  mounted() {},
};
</script>
<style lang="less" scoped>
.panel-login-info {
  .welcome {
    font-size: 14px;
    font-style: italic;
    font-weight: bold;
    
  }
  .row-totalAmt {
    .loader {
      justify-content: flex-end;
    }
  }
}
</style>
