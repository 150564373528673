import Vue from 'vue'
import VueI18n from 'vue-i18n'
import { getCookie, setCookie, setStore, getStore } from '../config/utils';

Vue.use(VueI18n)

export const LANGLIST = {
  enUS: { key: 'en', code: 'enUS', name: 'English', currency: 'USD', local: require('./enUS.json') },
  thTH: { key: 'th', code: 'thTH', name: 'ไทย', currency: 'THB', local: require('./thTH.json') },
  viVN: { key: 'vi', code: 'viVN', name: 'Tiếng Việt', currency: 'VNDK', local: require('./viVN.json') },
  idID: { key: 'id', code: 'idID', name: 'Indonesia', currency: 'IDRK', hide: true, local: require('./idID.json') },
  msMY: { key: 'ms', code: 'msMY', name: 'B.Melayu', currency: 'MYR', hide: true, local: require('./msMY.json') },
  myMY: { key: 'my', code: 'myMY', name: 'မြန်မာ', currency: 'KMMK', hide: true, local: require('./myMY.json') },
  ptPT: { key: 'pt', code: 'ptPT', name: 'Português', currency: 'EUR', hide: false, local: require('./ptPT.json') },
  zhTW: { key: 'zh', code: 'zhTW', name: '繁體中文', currency: 'CNY', hide: true, local: require('./zhTW.json') },
  zhCN: { key: 'zh', code: 'zhCN', name: '简体中文', currency: 'CNY', local: require('./zhCN.json') },
  deDE: { key: 'de', code: 'deDE', name: 'Deutsch', currency: 'EUR', local: require('./deDE.json') },
  esES: { key: 'es', code: 'esES', name: 'Español', currency: 'EUR', local: require('./esES.json') },
  jaJP: { key: 'ja', code: 'jaJP', name: '日本語', currency: 'JPY', local: require('./jaJP.json') },
  koKR: { key: 'ko', code: 'koKR', name: '한국어', currency: 'KRW', local: require('./koKR.json') },
}

const DEFALUT_ITEM = Object.values(LANGLIST).find(item => !item.hide && navigator.language.includes(item.key))
const DEFAULT_LANG = DEFALUT_ITEM ? DEFALUT_ITEM.code : 'thTH'
const LOCALE_KEY = 'lang'

const locales = Object.values(LANGLIST).reduce((curr, prev) => {
  curr[prev.code] = prev.local
  return curr
}, {})

const i18n = new VueI18n({
  locale: DEFAULT_LANG,
  messages: locales
})

export const setup = lang => {
  if (lang === undefined) {
    lang = getCookie(LOCALE_KEY)
    if (locales[lang] === undefined) {
      lang = DEFAULT_LANG
    }
  }
  setCookie(LOCALE_KEY, lang, 7)

  Vue.config.lang = lang
  i18n.locale = lang
}
setup()
export default i18n
