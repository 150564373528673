import i18n from '@/locales/index'
export default {
    getMerchantInfo: (state) => {
        return state.merchantInfo;
    },
    getLoginState: (state) => {
        return state.isLogin;
    },
    getLoginInfo: (state) => {
        return state.userInfo;
    },
    getAllBalances: (state) => {
        return state.allBalances;
    },
    userInfo: (state) => {
        const currencySymbol = {
            CNY: '￥',
            IDRK: 'Rp',
            INR: '₹',
            MYR: 'RM',
            THB: '฿',
            USD: '$',
            USDT: '₮',
            VNDK: '₫',
            KMMK: 'K',
            EUR: '€',
        }[state.userInfo.Currency] || state.userInfo.Currency
        return { ...state.userInfo, currencySymbol };
    },
    firstLoadApp: (state) => {
        return state.firstLoadApp;
    },
    getsecurityCode: (state) => {
        return state.securityCode;
    },
    onlineCustomLoaded: state => {
        return state.onlineCustomLoaded
    },
    providerCodes: state => {
        return {
            2: { code: 'SG' },
            3: { code: 'CMD' },
            4: { code: 'OG' },
            18: { code: 'ALLBET' },
            19: { code: 'PP' },
            20: { code: 'CQ9' },
            22: { code: 'HB' },
            23: { code: 'KY' },
            24: { code: 'EB' },
            25: { code: 'VR' },
            30: { code: 'AG' },
            31: { code: 'KK' },
            32: { code: 'TK' },
            33: { code: 'VK' },
            504: { code: 'MG' },
            505: { code: 'BBIN' },
            506: { code: 'LY' },
            509: { code: 'BG' },
            510: { code: 'FY' },
            512: { code: 'PT' },
            517: { code: 'WM' },
            518: { code: 'FG' },
            519: { code: 'JDB' },
            521: { code: 'IBC' },
            523: { code: 'PG' },
            524: { code: 'PM' },
            525: { code: 'PM' },
            526: { code: 'TCG' },
            528: { code: 'WG' },
            529: { code: 'V8' },
            530: { code: 'NETENT' },
            531: { code: 'NETENT' },
            534: { code: 'DG' },
            535: { code: 'SA' },
            536: { code: 'Sexy' },
            537: { code: 'COG' },
            538: { code: 'EVO' },
            539: { code: 'JILI' },
            540: { code: 'KM' },
            541: { code: 'FS' },
            542: { code: 'UG' },
            543: { code: 'PG' },
            544: { code: 'FC' },
            545: { code: 'RT' },
            546: { code: 'JOKER' }
        }
    },
    missionNameMaps: state => {
        return {
            TotalDepositBonus: i18n.t('activity_center_0039'),
            InviteMemberToOpenBonusBox: i18n.t('activity_center_0040'),
            TotalTurnoverToPlayTurnTable: i18n.t('activity_center_0041'),
            TotalTurnoverBonus: i18n.t('activity_center_0042'),
            WinLoseBonus: i18n.t('activity_center_0043'),
        }
    }
}