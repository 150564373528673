// Import Vue
import Vue from "vue";
import store from "../store/";
import vueSeamlessScroll from "vue-seamless-scroll"; //滚动
import "../service/filter";
import VueClipboard from "vue-clipboard2";
import { Tab, Tabs, Search, Sticky } from "vant";
import VueLuckyCanvas from "@lucky-canvas/vue";
import i18n, { setup } from "../locales/index";
import { getCookie } from "../config/utils";

// Import Framework7
import Framework7 from "framework7/framework7.esm.bundle.js";

// Import Framework7-Vue Plugin
import Framework7Vue from "framework7-vue/framework7-vue.esm.bundle.js";

// Import Framework7 Styles
import "framework7/css/framework7.bundle.css";

// Import Icons and App Custom Styles
import "framework7-icons/css/framework7-icons.css";
import "../css/icons.css";
import "../css/custom-size.less";
import "../css/f7-variables.less";
import "../css/animation.less";
import "../fonts/font_common/iconfont.css";
import "../fonts/font_logo/iconfont.js";
import "../css/app.less";
import "../css/common.less";
import "../css/popup.less";
import "../css/media.less";

import "vant/lib/tabs/style";
import "vant/lib/search/style";
import "vant/lib/sticky/style";

// Import App Component
import App from "../components/app.vue";

import common from "../service/common";

// Init Framework7-Vue Plugin
Framework7.use(Framework7Vue);
Vue.use(vueSeamlessScroll);
Vue.use(VueClipboard);
Vue.use(Tab);
Vue.use(Tabs);
Vue.use(Search);
Vue.use(Sticky);
Vue.use(VueLuckyCanvas);

Vue.prototype.changeLanguage = setup;
Vue.prototype.common = common;

String.prototype.Format = function (args) {
  if (arguments.length > 0) {
    var result = this;
    if (arguments.length == 1 && typeof args == "object") {
      for (var key in args) {
        var reg = new RegExp("({" + key + "})", "g");
        result = result.replace(reg, args[key]);
      }
    } else {
      for (var i = 0; i < arguments.length; i++) {
        if (arguments[i] == undefined) {
          return "";
        } else {
          var reg = new RegExp("({[" + i + "]})", "g");
          result = result.replace(reg, arguments[i]);
        }
      }
    }
    return result;
  } else {
    return this;
  }
};

var setWindowVariable = function () {
  const { top } = document.querySelector("body").getBoundingClientRect();
  const desktopElem = document.querySelector(".device-desktop");
  if (desktopElem) {
    desktopElem.setAttribute("style", `--window-top:${top}px;`);
  }
};

// Init App
window.app = new Vue({
  el: "#app",
  i18n,
  store,
  render: (h) => h(App),
  // Register App Component
  components: {
    app: App,
  },
  mounted() {
    setWindowVariable();
  },
});

window.onresize = function () {
  setWindowVariable();
};
