import { setCookie } from '@/config/utils';
import { 
    SETMERCHAT_INFO,
    SETLOGINSTATE,
    SETLOGININFO,
    SETALLBALANCES,
    SETUSERINFO,
    SETFIRSTLOADAPP,
    SETSECURITYCODE,
    SET_ONLINECUSTOM_LOADSTATUS,
    SET_CURRENCY,
    SET_CURRENCY_LIST,
    SET_MISSION_LIST
} from './mutation-types'

export default {
    [SETMERCHAT_INFO](state, info) { 
        state.merchantInfo = info;
    },
    [SETLOGINSTATE](state, info) { 
        state.isLogin = info;
    },
    [SETLOGININFO](state, info) { 
        state.loginInfo = info;
    },
    [SETALLBALANCES](state, info) { 
        state.allBalances = info;
    },
    [SETUSERINFO](state, info) { 
        state.userInfo = info;
    },
    [SETFIRSTLOADAPP](state, info) {
        state.firstLoadApp = info;
    },
    [SETSECURITYCODE](state, info) {
        state.securityCode = info;
    },
    [SET_ONLINECUSTOM_LOADSTATUS](state, info) {
        state.onlineCustomLoaded = info
    },
    [SET_CURRENCY](state, info) {
        state.currency = info;
        setCookie('currency', info, 7);
    },
    [SET_CURRENCY_LIST](state, info) {
        state.currencyList = info;
    },
    [SET_MISSION_LIST](state, info) {
        state.missionList = info;
    }
}