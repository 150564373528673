<template>
  <f7-page no-toolbar no-swipeback name="deposit-records" class="page-deposit-records">
    <f7-navbar :title="$t('safe_records_0001')" back-link :no-hariline="true"></f7-navbar>
    <f7-block class="block-records" v-if="recordsList.length > 0">
      <div class="card card-outline" v-for="item in recordsList" :key="item.TicketNo">
        <!-- <div class="card-header">
          <div class="row no-gap">
            <div class="col-25 text-right">
              <i class="f7-icons">equal_square</i>
              <span class="co-gray">订单号:</span>
            </div>
            <div class="col-75 co-white text-right">{{11}}</div>
          </div>
        </div> -->
        <div class="card-content card-content-padding">
          <div class="row no-gap">
            <div class="col-25 text-right co-white">{{ $t("safe_records_0002") }}:</div>
            <div class="col-75 co-gray">
              <template v-if="item.SafetyBoxTransferType == 'SafetyBoxToMainWallet'">{{ $t("safe_records_0003") }}</template>
              <template v-if="item.SafetyBoxTransferType == 'MainWalletToSafetyBox'">{{ $t("safe_records_0004") }}</template>
            </div>
          </div>
          <div class="row no-gap">
            <div class="col-25 text-right co-white">{{ $t("safe_records_0005") }}:</div>
            <div class="col-75 co-blue">
              <template>{{ item.TransferAmount | formatCurrency }}</template>
            </div>
          </div>
        </div>
        <div class="card-footer co-white">{{ item.CreateTS.replace("T", " ") }}</div>
      </div>
    </f7-block>

    <f7-card v-else-if="recordsList.length <= 0" class="no-data">
      <img :src="`../../../static/images/yt999/noData_${$f7.params.currLang}.png`" width="45%" :onerror="`this.onerror=''; src='../../../static/images/yt999/noData_enUS.png'`" alt="No data" />
    </f7-card>
  </f7-page>
</template>

<script>
import { safetyboxtransferhistorymemeberlist } from "../../../axios/api";
import { mapGetters, mapMutations, mapActions } from "vuex";

import moment from "moment";
export default {
  components: {},
  props: {},
  data() {
    return {
      recordsList: [],
      queryParam: {
        BeginDate: moment(new Date()).format("YYYY-MM-DD 23:59:59"),
        EndDate: moment(new Date()).format("YYYY-MM-DD 23:59:59"),
      },
    };
  },
  watch: {},
  computed: {
    dateF() {
      let date1 = new Date();
      let date2 = new Date(date1);
      date2.setDate(date1.getDate() - 16);
      let year = date2.getFullYear();
      let month = date2.getMonth() + 1;
      month = month < 10 ? `0${month}` : month;
      let day = date2.getDate();
      return `${year}-${month}-${day} 00:00:00`;
    },
    ...mapGetters(["getsecurityCode"]),
  },
  methods: {
    safeRecords() {
      const self = this;
      let begin = new Date().setDate(new Date().getDate() - 15);
      let param = {
        BeginDate: new Date(self.queryParam.BeginDate).getTime() / 1000,
        EndDate: new Date(self.queryParam.EndDate).getTime() / 1000,
        PageNow: 1,
        PageSize: 50,
      };
      const params = { data: JSON.stringify(param) };
      safetyboxtransferhistorymemeberlist(params).then((data) => {
        if (data.Code === "NoError") {
          self.recordsList = self.recordsList.concat(data.Data[0]);
        }
      });
    },
    initPickerDateF() {
      // Inline date-time
      const self = this;
      const app = self.$f7;
      let date1 = new Date();
      const today = new Date(date1);
      today.setDate(date1.getDate() - 16);
      const month = today.getMonth() + 1 < 10 ? `0${today.getMonth() + 1}` : today.getMonth() + 1;
      self.pickerInline = app.picker.create({
        inputEl: "#dateF",
        rotateEffect: true,
        routableModals: false,
        value: [today.getFullYear(), month, today.getDate() < 10 ? `0${today.getDate()}` : today.getDate(), "00", "00", "00"],
        formatValue(values, displayValues) {
          let year = values[0];
          let month = values[1];
          let day = values[2];
          return `${year}-${month}-${day} ${values[3]}:${values[4]}:${values[5]}`;
        },
        cols: [
          // Years
          {
            values: (function createValues() {
              const arr = [];
              for (let i = 2010; i <= 2088; i += 1) {
                arr.push(i);
              }
              return arr;
            })(),
            textAlign: "left",
          },
          {
            divider: true,
            content: "-",
          },
          // Months
          {
            values: (function createValues() {
              const arr = [];
              for (let i = 1; i <= 12; i += 1) {
                arr.push(i < 10 ? `0${i}` : i);
              }
              return arr;
            })(),
            textAlign: "left",
          },
          {
            divider: true,
            content: "-",
          },
          // Days
          {
            values: (function createValues() {
              const arr = [];
              for (let i = 1; i <= 31; i += 1) {
                arr.push(i < 10 ? `0${i}` : i);
              }
              return arr;
            })(),
            textAlign: "left",
          },
          // Hours
          {
            values: (function createValues() {
              const arr = [];
              for (let i = 0; i <= 23; i += 1) {
                arr.push(i < 10 ? `0${i}` : i);
              }
              return arr;
            })(),
            textAlign: "left",
          },
          {
            divider: true,
            content: ":",
          },
          // Minutes
          {
            values: (function createValues() {
              const arr = [];
              for (let i = 0; i <= 59; i += 1) {
                arr.push(i < 10 ? `0${i}` : i);
              }
              return arr;
            })(),
            textAlign: "left",
          },
          {
            divider: true,
            content: ":",
          },
          // second
          {
            values: (function createValues() {
              const arr = [];
              for (let i = 0; i <= 59; i += 1) {
                arr.push(i < 10 ? `0${i}` : i);
              }
              return arr;
            })(),
            textAlign: "left",
          },
        ],
        on: {
          change(picker, values, displayValues) {
            const daysInMonth = new Date(picker.value[0], picker.value[1] * 1 + 1, 0).getDate();
            if (values[2] > daysInMonth) {
              //picker.cols[2].setValue(daysInMonth);
            }

            self.queryParam.BeginDate = `${values[0]}-${values[1]}-${values[2]} ${values[3]}:${values[4]}:${values[5]}`;
          },
        },
      });
    },
    initPickerDateT() {
      // Inline date-time
      const self = this;
      const app = self.$f7;
      const today = new Date();
      const month = today.getMonth() + 1 < 10 ? `0${today.getMonth() + 1}` : today.getMonth() + 1;
      self.pickerInline = app.picker.create({
        inputEl: "#dateT",
        rotateEffect: true,
        routableModals: false,
        value: [today.getFullYear(), month, today.getDate(), "23", "59", "59"],
        formatValue(values, displayValues) {
          let year = values[0];
          let month = values[1];
          let day = values[2];
          return `${year}-${month}-${day} ${values[3]}:${values[4]}:${values[5]}`;
        },
        cols: [
          // Years
          {
            values: (function createValues() {
              const arr = [];
              for (let i = 2010; i <= 2088; i += 1) {
                arr.push(i);
              }
              return arr;
            })(),
            textAlign: "left",
          },
          {
            divider: true,
            content: "-",
          },
          // Months
          {
            values: (function createValues() {
              const arr = [];
              for (let i = 1; i <= 12; i += 1) {
                arr.push(i < 10 ? `0${i}` : i);
              }
              return arr;
            })(),
            textAlign: "left",
          },
          {
            divider: true,
            content: "-",
          },
          // Days
          {
            values: (function createValues() {
              const arr = [];
              for (let i = 1; i <= 31; i += 1) {
                arr.push(i < 10 ? `0${i}` : i);
              }
              return arr;
            })(),
            textAlign: "left",
          },
          // Hours
          {
            values: (function createValues() {
              const arr = [];
              for (let i = 0; i <= 23; i += 1) {
                arr.push(i < 10 ? `0${i}` : i);
              }
              return arr;
            })(),
            textAlign: "left",
          },
          {
            divider: true,
            content: ":",
          },
          // Minutes
          {
            values: (function createValues() {
              const arr = [];
              for (let i = 0; i <= 59; i += 1) {
                arr.push(i < 10 ? `0${i}` : i);
              }
              return arr;
            })(),
            textAlign: "left",
          },
          {
            divider: true,
            content: ":",
          },
          // second
          {
            values: (function createValues() {
              const arr = [];
              for (let i = 0; i <= 59; i += 1) {
                arr.push(i < 10 ? `0${i}` : i);
              }
              return arr;
            })(),
            textAlign: "left",
          },
        ],
        on: {
          change(picker, values, displayValues) {
            const daysInMonth = new Date(picker.value[0], picker.value[1] * 1 + 1, 0).getDate();
            if (values[2] > daysInMonth) {
              //picker.cols[2].setValue(daysInMonth);
            }
            self.queryParam.EndDate = `${values[0]}-${values[1]}-${values[2]} ${values[3]}:${values[4]}:${values[5]}`;
          },
        },
      });
    },
  },
  created() {
    this.queryParam.BeginDate = this.dateF;
    this.safeRecords();
  },
  mounted() {
    this.initPickerDateF();
    this.initPickerDateT();
  },
};
</script>
<style lang="less" scoped>
.page-deposit-records {
  .block-records {
    margin: 0;
    padding: 0;
    .card {
      border: 1px solid #2352c6;
      background-color: #152759;
      margin: 8px;
      .co-white {
        color: #fff;
      }
      .co-gray {
        color: #b8b8b9;
      }
      .co-green {
        color: #33cd5f;
      }
      .co-blue {
        color: #11c1f3;
      }
      .co-yellow {
        color: #f7da00;
      }
      .co-red {
        color: red;
      }
      .card-header:after,
      .card-footer:before {
        background-color: #1c3b93;
      }
      .card-header {
        display: block;
        font-size: 15px;
        padding: 10px 15px 10px 8px;
        i.f7-icons {
          font-size: 18px;
          vertical-align: middle;
          margin-top: -3px;
          color: yellow;
        }
      }
      .card-content {
        font-size: 15px;
        padding: 2px 4px;
        .row {
          padding: 4px 0;
        }
      }
      .card-footer {
        justify-content: flex-end;
        padding: 8px 15px;
      }
    }
  }

  .no-data {
    background-color: transparent;
    box-shadow: none;
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    align-items: center;
    align-content: center;
    height: 50%;
  }
}
</style>
