<template>
  <f7-page no-toolbar no-swipeback name="withdraw-records" class="page-withdraw-records" infinite :infinite-distance="50" :infinite-preloader="showPreloader" @infinite="loadMore">
    <f7-navbar :title="$t('withdraw_records_0001')" back-link :no-hariline="true"></f7-navbar>
    <f7-block class="block-records" v-if="withdrawRecordsList.length > 0">
      <div class="card card-outline" v-for="item in withdrawRecordsList" :key="item.TicketNo">
        <div class="card-header">
          <div class="row no-gap">
            <!-- <div class="col-25 text-right"> -->
            <i class="f7-icons">equal_square</i>
            <!-- <span class="co-gray">{{ $t('withdraw_records_0002') }}:</span> -->
            <!-- </div> -->
            <div class="col-75 co-white text-left">{{ item.TicketNo }}</div>
          </div>
        </div>
        <div class="card-content card-content-padding">
          <div class="row no-gap">
            <div class="col-25 text-right co-white">{{ $t("withdraw_records_0003") }}:</div>
            <div class="col-75" :class="item.Amount < 0 ? 'co-yellow' : 'co-blue'">{{ item.Amount | formatCurrency }}</div>
          </div>
          <div class="row no-gap">
            <div class="col-25 text-right co-white">{{ $t("withdraw_records_0004") }}:</div>
            <div class="col-75" :class="item.Bonus < 0 ? 'co-yellow' : 'co-blue'">{{ item.Bonus | formatCurrency }}</div>
          </div>
          <div class="row no-gap">
            <div class="col-25 text-right co-white">{{ $t("withdraw_records_0005") }}:</div>
            <div class="col-75" :class="item.ServiceFee < 0 ? 'co-yellow' : 'co-blue'">{{ item.ServiceFee | formatCurrency }}</div>
          </div>
          <div class="row no-gap">
            <div class="col-25 text-right co-white">{{ $t("withdraw_records_0006") }}:</div>
            <div class="col-75" :class="item.WithdrawFee < 0 ? 'co-yellow' : 'co-blue'">{{ item.WithdrawFee | formatCurrency }}</div>
          </div>
          <div class="row no-gap">
            <div class="col-25 text-right co-white">{{ $t("withdraw_records_0007") }}:</div>
            <div class="col-75 co-green">{{ item.ConfirmAmount | formatCurrency }}</div>
          </div>
          <div class="row no-gap">
            <div class="col-25 text-right co-white">{{ $t("withdraw_records_0008") }}:</div>
            <div class="col-75 co-green">{{ item.Bonus | formatCurrency }}</div>
          </div>
          <div class="row no-gap">
            <div class="col-25 text-right co-white">{{ $t("withdraw_records_0009") }}:</div>
            <div class="col-75">
              <span class="co-yellow" v-if="item.VerifyStatus == 'Wait'">{{ $t("withdraw_records_0010") }}</span>
              <span class="co-green" v-else-if="item.VerifyStatus == 'Success'">{{ $t("withdraw_records_0011") }}</span>
              <span class="co-green" v-else-if="item.VerifyStatus == 'WithdrawProviderProcessSuccess'">{{ $t("withdraw_records_0012") }}</span>
              <span class="co-red" v-else-if="item.VerifyStatus == 'Fail'">{{ $t("withdraw_records_0013") }}</span>
              <span class="co-yellow" v-else>{{ $t("withdraw_records_0014") }}</span>
            </div>
          </div>
          <div class="row no-gap">
            <div class="col-25 text-right co-white">{{ $t("withdraw_records_0015") }}:</div>
            <div class="col-75 co-gray">{{ item.Remark ? item.Remark : "--" }}</div>
          </div>
        </div>
        <div class="card-footer co-white">{{ item.CreateTs | time_local }}</div>
      </div>
    </f7-block>

    <f7-card v-else-if="withdrawRecordsList.length <= 0 && !showData" class="no-data">
      <img :src="`../../../static/images/yt999/noData_${$f7.params.currLang}.png`" width="45%" :onerror="`this.onerror=''; src='../../../static/images/yt999/noData_enUS.png'`" alt="No data" />
    </f7-card>
  </f7-page>
</template>

<script>
import { getCookie } from "@/config/utils";
import { getWithdrawRecordsList } from "../../../axios/api";
export default {
  components: {},
  props: {},
  data() {
    return {
      withdrawRecordsList: [],
      allowInfinite: true,
      showPreloader: true,
      pageNow: 1,
      pageTotal: 0,
      showData: true,
    };
  },
  watch: {},
  computed: {},
  methods: {
    withdrawRecordsListEvent() {
      const self = this;
      let queryParam = JSON.parse(self.$f7route.params.Info);

      queryParam.BeginDate = Math.round(new Date(queryParam.BeginDate.replace(/-/g, "/")).getTime() / 1000);
      queryParam.EndDate = Math.round(new Date(queryParam.EndDate.replace(/-/g, "/")).getTime() / 1000);
      queryParam.PageNow = self.pageNow;
      queryParam.PageSize = 4;

      const params = { data: JSON.stringify(queryParam) };
      getWithdrawRecordsList(params).then((data) => {
        if (data.Code === "NoError") {
          self.withdrawRecordsList = self.withdrawRecordsList.concat(data.Data[0]);
          self.showData = self.withdrawRecordsList.length > 0;
          self.pageTotal = data.Data[1];
          if (self.withdrawRecordsList.length >= self.pageTotal) {
            self.showPreloader = false;
          }
        }
      });
    },
    loadMore() {
      const self = this;
      if (!self.allowInfinite) return;
      self.allowInfinite = false;

      setTimeout(() => {
        if (self.withdrawRecordsList.length >= self.pageTotal) {
          self.showPreloader = false;
          return;
        }
        self.pageNow += 1;
        self.withdrawRecordsListEvent();
        self.allowInfinite = true;
      }, 1000);
    },
  },
  created() {
    this.withdrawRecordsListEvent();
  },
  mounted() {},
};
</script>
<style lang="less" scoped>
.page-withdraw-records {
  .block-records {
    margin: 0;
    padding: 0;
    .card {
      border: 1px solid #d8d8d8;
      background-color: #f9f9f9;
      margin: 8px;
      .co-white {
        color: #464646;
      }
      .co-gray {
        color: #464646;
      }
      .co-green {
        color: #33cd5f;
      }
      .co-blue {
        color: #11c1f3;
      }
      .co-yellow {
        color: #f11f1f;
      }
      .co-red {
        color: #fd6565;
      }
      .card-header:after,
      .card-footer:before {
        background-color: #b7b7b7;
      }
      .card-header {
        display: block;
        font-size: 15px;
        padding: 10px 15px 10px 8px;
        i.f7-icons {
          font-size: 18px;
          vertical-align: middle;
          margin-top: -3px;
          color: var(--f7-base-red);
        }
      }
      .card-content {
        font-size: 15px;
        padding: 2px 4px;
        .row {
          padding: 4px 0;
        }
      }
      .card-footer {
        justify-content: flex-end;
        padding: 8px 15px;
      }
    }
  }

  .no-data {
    background-color: transparent;
    box-shadow: none;
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    align-items: center;
    align-content: center;
    height: 50%;
  }
}
</style>
