<template>
  <div class="activity-invite">
    <div class="title">
      <img :src="`static/images/yt999/activity/t_03_${$f7.params.currLang}.png`" :onerror="`this.onerror=''; src='../static/images/yt999/activity/t_03_enUS.png'`">
    </div>
    <div class="rules">
      <img class="ico" src="static/images/yt999/activity/8.png" alt="">
      <span class="label" @click="openDialog('dialog-invite-rules')">{{ $t('activity_center_0002') }}</span>
    </div>
    <div class="task-list">
      <div class="task-header">
        <div class="label">{{ $t('activity_center_0003') }}</div>
        <div class="value">{{ item.OwnQuantity }}</div>
      </div>
      <div class="task-warpper">
        <f7-link class="task-item" v-for="(mission, p) of item.SettingList" :key="p" @click="receive(mission)">
          <img class="ico" src="../../../static/images/yt999/activity/9.png" alt="">
          <f7-button class="status btn-receive" v-if="mission.OwnQuantity >= mission.RequiredQuantity && !mission.IsReceived">{{ $t('activity_center_0004') }}</f7-button>
          <f7-button class="status btn-received" v-else-if="mission.OwnQuantity >= mission.RequiredQuantity && mission.IsReceived">{{ $t('activity_center_0005') }}</f7-button>
          <f7-button class="status btn-details" v-else>{{ $t('activity_center_0006') }}</f7-button>
          <div class="desc">{{ $t('activity_center_0007', [mission.RequiredQuantity]) }}</div>
        </f7-link>
      </div>
    </div>

    <div class="dialog dialog-rules" id="dialog-invite-rules" @dialog:closed="$f7.$('.dialog-backdrop').css('background', 'transparent');" @dialog:open="$f7.$('.dialog-backdrop').css('background', 'rgba(0, 4, 7, 0.77)')">
      <div class="dialog-inner">
        <div class="dialog-title">
          {{ $t('activity_center_0008') }}
        </div>
        <div class="dialog-body">
          <ul class="list">
            <li class="item">{{ $t('activity_center_0009') }}</li>
            <li class="item">{{ getInviteRuleDesc() }}</li>
            <li class="item">{{ $t('activity_center_0010') }}</li>
            <li class="item">{{ $t('activity_center_0011', [getGameTypeLimitDesc()]) }}</li>
            <li class="item">{{ $t('activity_center_0012') }}</li>
          </ul>
        </div>
        <div class="dialog-footer">
          <f7-link class="btn-close" @click="closeDialog('dialog-invite-rules')">{{ $t('common_close') }}</f7-link>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { receiveBouns } from '@/axios/api'
export default {
  name: "TotalTurnoverBonus",
  props: {
    item: {
      type: Object,
      default() {
        return {
          SettingList: []
        }
      }
    }
  },
  data() {
    return {

    }
  },
  computed: {
  },
  methods: {
    async receive(item) {
      if (item.IsReceived || item.OwnQuantity < item.RequiredQuantity) {
        return
      }
      let loading = this.$f7.dialog.create({
        title: '',
        text: this.$t('activity_center_0013'),
        cssClass: "dialog-preloadgame",
      }).open();
      try {
        const res = await receiveBouns({ data: JSON.stringify({ missionID: item.MissionID, amount: item.Amount }) })
        this.$f7.dialog.alert(res.Data, this.$t('common_0004'));
        if (res.Status == 0) {
          this.$emit('receive')
        }
      } finally {
        loading.close();
      }
    },
    openDialog(id) {
      this.$f7.dialog.open(`#${id}`)
    },
    closeDialog(id) {
      this.$f7.dialog.close(`#${id}`)
    },
    getGameTypeLimitDesc() {
      const gameTypes = this.item.BonusAuditGameTypes
      if (!gameTypes) {
        return this.$t('activity_center_0014')
      } else {
        return gameTypes.map(gameType => gameType).join(' /')
      }
    },
    getInviteRuleDesc() {
        if (this.item.Settings.IsCheckAllLimit) {
            return this.$t('activity_center_0015', this.item.Settings)
        } else {
            return this.$t('activity_center_0016', this.item.Settings)
        }
    }
  }
}
    </script>
    
    <style lang="less">
.activity-invite {
  position: relative;
  background: url("../../../static/images/yt999/activity/7.png");
  background-size: 100% 100vh;
  height: calc(100vh - 70px);
  .title {
    position: absolute;
    top: 0;
    img {
      width: 100%;
    }
  }
  .rules {
    display: flex;
    align-items: flex-end;
    justify-content: flex-start;
    height: 160px;
    padding: 0 16px;
    font-weight: bold;
    .ico {
      width: 18px;
      height: 18px;
      margin-right: 6px;
    }
    .label {
      color: #476af7;
    }
  }

  .task-list {
    background: #fff;
    margin: 16px;
    padding: 16px;
    border-radius: 8px;
    .task-header {
      text-align: center;
      color: #5c86f6;
      .label {
        font-size: 12px;
      }
      .value {
        font-size: 16px;
        font-weight: bold;
      }
    }
    .task-warpper {
      display: flex;
      flex-wrap: wrap;
    }
    .task-item {
      width: 25%;
      display: flex;
      flex-direction: column;
      align-items: center;
      .ico {
        width: 80%;
      }
      .status {
        position: relative;
        top: -8px;
        font-size: 12px;
        background: #c02b1d;
        color: #fff;
        border-radius: 100px;
        line-height: 1;
        height: auto;
        padding: 4px 12px;
        &.btn-receive {
          background: #f3ae54;
          color: #fff;
        }
        &.btn-received {
          background: rgba(#000, 0.3);
        }
        &.btn-details {
          background: rgba(#000, 0.3);
        }
      }
      .desc {
        position: relative;
        top: -4px;
        font-size: 12px;
        transform: scale(0.7);
        text-align: center;
      }
    }
  }
}
</style>