<template>
  <f7-button popup-open="#popup-marquee" class="popup-marquee">  
    <div class="marquee-content">
      <div class="am-notice-bar">
        <div class="am-notice-bar-icon">
          <span class="notice-icon"></span>
        </div>
        <vue-seamless-scroll :data="lamplist" :class-option="optionLeft" class="am-notice-bar-content">
          <ul class="item" ref="marqueeRef" id="marquee-item" :style="'width:'+lampWidth+'px'">
            <li class="li-marquee" :style="'margin-left:'+screenWidth+'px'" v-for="item in lamplist" :key="item.Id" v-text="item.Content"></li>
          </ul>
        </vue-seamless-scroll>
      </div>     
    </div>
  </f7-button>
</template>
<script>
export default {
  props: ['lamplist'],
  data() {
    return {
      lampWidth:2000, 
      screenWidth:375,
    };
  },
  watch:{
    lamplist:function(newVal,oldVal){
      //console.log(newVal.length);
      if(newVal==null || newVal.length==0){
        return;
      }else{
        var width=0;
        for (let index = 0; index < newVal.length; index++) {
          const element = newVal[index];
          width=width+element.Content.length*14+this.screenWidth;
        }
        //console.log(width);
        this.lampWidth = width+300;
      }
    }
  },
  computed: {
    optionLeft () {
      return {
        step: 1, // 数值越大速度滚动越快
        limitMoveNum: 0, // 开始无缝滚动的数据量 this.dataList.length
        hoverStop: false, // 是否开启鼠标悬停stop
        direction: 2, // 0向下 1向上 2向左 3向右
        openWatch: true, // 开启数据实时监控刷新dom
        singleHeight: 0, // 单步运动停止的高度(默认值0是无缝不停止的滚动) direction => 0/1
        singleWidth: 0, // 单步运动停止的宽度(默认值0是无缝不停止的滚动) direction => 2/3
        waitTime: 1000 // 单步运动停止的时间(默认值1000ms)
      }
    }
  },
  created(){
    const self = this;
    const $$ = self.$f7.$;
    self.screenWidth=document.body.clientWidth;
    self.$nextTick(() => {
      setTimeout(() => {
        const len = $$('.li-marquee').length / 2;
        let width = 100;
        for(let i = 0; i < len; i++) {
          //console.log($$($$('.li-marquee')[i]).width());
          width += $$($$('.li-marquee')[i]).width();
        }
        //$$('#marquee-item').css('width', `${width}px`);
      },100);
    });
  }
};
</script>
<style lang="less" scoped>
.popup-marquee {
  position: relative;
  width: 100%;
  height: 32px;
  padding: 0 14px;
  margin: 0;
  margin-bottom: 10px;
  background-color: transparent;
  &::before {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
    background: url(/static/images/yt999/home/marquee-bg.png);
    background-repeat: no-repeat;
    background-size: 100% auto;
  }
  .marquee-content {
    height: 100%;
    position: relative;
    z-index: 2;
  }
  .am-notice-bar {
    display: flex;
    background: none;
    height: 32px;
    line-height: 26px;
    color: #3f5075;
    font-size: 14px;
    overflow: hidden;

    &-icon {
      display: flex;
      align-items: center;
      .notice-icon {        
        width: 14px;
        height: 14px;
        background-image: url(/static/images/yt999/home/marquee-icon.png);
        background-size: contain;
        background-repeat: no-repeat;
      }
    }

    &-content {
      margin-left: 6px;
      flex: 1;
      margin-right: 15px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;

      ul.item {
      //width: 1900px;
      margin: 0;
        li {
          float: var(--f7-text-left);
          //margin-left: 50px;
          //margin-right: 100px;
          list-style: var(--f7-border-none);
          color: #464646;
        }
      }
    }
  }
}
// .popup-marquee:after{
//   content:'';
//   position: absolute;
//   background: url(/static/images/yt999/light.png) no-repeat;
//   background-size: var(--f7-size-percent-100) var(--f7-size-percent-100);
//   height: var(--f7-height-size-2);
//   display: block;
//   top:auto;
//   right:auto;
//   width: 100%;
//   bottom: 0;
//   left: 0;
//   transform-origin: 50% 100%;
//   transform: scaleY(calc(1 / var(--f7-device-pixel-ratio)));
// }
</style>