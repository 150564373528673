<template>
  <f7-page no-toolbar no-swipeback name="" class="page">
    <f7-navbar :title="$t('referrer_share_0001')" back-link :no-hariline="true">
      <!-- <div class="head">
        <div class="nav">
          <f7-link tab-link="#tab-share" tab-link-active><p class="lef">分享链接</p></f7-link>
          <f7-link tab-link="#tab-register"><p class="rig">直接开户</p></f7-link>
        </div>
      </div> -->
    </f7-navbar>
    <f7-block class="block">
      <f7-tabs>
        <!-- <f7-tabs swipeable> -->
        <f7-tab id="tab-share" class="page-content" tab-active>
          <div class="share">
            <div class="code text-center" id="qrcode"></div>
            <div class="url text-center">{{ $t("referrer_share_0002") }}</div>
            <div class="list inset button-share">
              <ul>
                <li>
                  <a href="#" class="list-button color-black" v-clipboard:copy="link" v-clipboard:success="onCopy" v-clipboard:error="onError">{{ $t("referrer_share_0003") }}</a>
                </li>
              </ul>
            </div>
            <div class="tips-desc">
              <p>{{ $t("common_0004") }}：</p>
              <p>{{ $t("referrer_share_0004") }}</p>
              <p>{{ $t("referrer_share_0005") }}</p>
            </div>
            <!-- <div class="row">
              <div class="col-33">
                <div class="text-center"><img src="../../../static/images/yt999/referrer/wx.png" width="40%"></div>
                <div class="text-center">微信好友</div>
              </div>
              <div class="col-33">
                <div class="text-center"><img src="../../../static/images/yt999/referrer/friends.png" width="40%"></div>
                <div class="text-center">朋友圈</div>
              </div>
              <div class="col-33">
                <div class="text-center"><img src="../../../static/images/yt999/referrer/QQ.png" width="40%"></div>
                <div class="text-center">QQ</div>
              </div>
            </div> -->
          </div>
        </f7-tab>
        <f7-tab id="tab-register">
          <div class="register">
            <form class="list no-hairlines-md" id="form-register">
              <ul>
                <li class="item-content item-input">
                  <div class="item-media">
                    <i class="iconfont icon-wode"></i>
                  </div>
                  <div class="item-inner">
                    <div class="item-input-wrap yzm">
                      <input type="text" name="user-name" :placeholder="$t('register_0003')" required />
                    </div>
                  </div>
                </li>
                <li class="item-content item-input">
                  <div class="item-media">
                    <i class="iconfont icon-mima"></i>
                  </div>
                  <div class="item-inner">
                    <div class="item-input-wrap">
                      <input type="password" name="user-pwd" :placeholder="$t('register_agent_0005')" required />
                      <span class="input-clear-button"></span>
                    </div>
                  </div>
                </li>
                <li class="item-content item-input">
                  <div class="item-media">
                    <i class="iconfont icon-mima"></i>
                  </div>
                  <div class="item-inner">
                    <div class="item-input-wrap">
                      <input type="password" name="user-repwd" :placeholder="$t('register_agent_0006')" required />
                      <span class="input-clear-button"></span>
                    </div>
                  </div>
                </li>
                <li class="item-content item-input item-input-with-info">
                  <div class="item-media">
                    <i class="iconfont icon-xingming"></i>
                  </div>
                  <div class="item-inner">
                    <div class="item-input-wrap">
                      <input type="text" name="user-surename" :placeholder="$t('register_agent_0008')" />
                      <span class="input-clear-button"></span>
                    </div>
                  </div>
                </li>
                <li class="item-content item-input item-input-with-info">
                  <div class="item-media">
                    <i class="iconfont icon-yanzhengma"></i>
                  </div>
                  <div class="item-inner">
                    <div class="item-input-wrap">
                      <input type="text" name="user-verify" :placeholder="$t('register_agent_0018')" required />
                      <span class="span-verify">
                        <img @click.self="updateVerify" :src="`${verifyUrl}`" alt />
                      </span>
                    </div>
                  </div>
                </li>
              </ul>
              <f7-block strong>
                <f7-button fill raised large>{{ $t("referrer_share_0006") }}</f7-button>
              </f7-block>
            </form>
          </div>
        </f7-tab>
      </f7-tabs>
    </f7-block>
  </f7-page>
</template>

<script>
import QRCode from "qrcodejs2";
import api from "../../../config/api";
import { getCookie, setCookie, getStore, setStore } from "../../../config/utils";

export default {
  components: { QRCode },
  props: {},
  data() {
    return {
      link: "https://baidu.com",
      verifyUrl: ``,
    };
  },
  watch: {},
  computed: {},
  methods: {
    //  生成二维码
    qrcode() {
      let self = this;
      this.link = window.location.protocol + "//" + window.location.host + "/#/register/" + "?r=" + getCookie("LoginName");
      let qrcode = new QRCode("qrcode", {
        width: 124,
        height: 124, // 高度
        text: this.link, // 二维码内容
        // render: 'canvas' ,   // 设置渲染方式（有两种方式 table和canvas，默认是canvas）
        // background: '#f0f',   // 背景色
        // foreground: '#ff0'    // 前景色
      });
    },
    onCopy(e) {
      const self = this;
      if (e.text) {
        let copyLoading = self.$f7.dialog
          .create({
            title: "",
            text: this.$t("common_0003"),
            cssClass: "dialog-preloadgame",
          })
          .open();

        setTimeout(() => {
          copyLoading.close();
        }, 1000);
      }
    },
    onError(e) {
      console.log(e);
    },
    updateVerify() {
      const uid = common.uuid();
      setCookie("u", uid, 7);
      this.verifyUrl = `${api.verifyimage}?u=${uid}`;
    },
  },
  created() {
    this.verifyUrl = `${api.verifyimage}?u=${getCookie(`u`)}`;
  },
  mounted() {
    this.qrcode();
  },
};
</script>
<style lang="less" scoped>
.head {
  width: 100%;
  padding-right: 54px;
}
.nav {
  display: table;
  // display: table;
  margin: 0 auto;
  a {
    display: inline;
  }
  p {
    background: #3965b9;
    width: 80px;
    height: 30px;
    text-align: center;
    line-height: 30px;
    font-size: 14px;
    color: #fff;
    cursor: pointer;
    float: left;
    margin: 0;
    a {
      display: block;
      color: #fff;
    }
  }
  .tab-link-active {
    p {
      background: #0d3a92;
    }
  }
  .lef {
    border-right: 1px solid #0c3ca2;
    border-radius: 30px 0 0 30px;
  }
  .rig {
    border-right: 1px solid #0c3ca2;
    border-radius: 0 30px 30px 0;
  }
}

.page {
  .page-content {
    padding: 0px;
  }
  .share {
    .code {
      width: 125px;
      height: 125px;
      background: #fff;
      padding: 5px;
      margin: auto;
    }
    > div {
      margin-bottom: 30px !important;
    }
    .tips-desc {
      margin-left: 10px;
      margin-bottom: 4px;
    }
  }
  .register {
    .list {
      margin: var(--f7-margin-size-10) 0;
      .block-title {
        background: url(../../../static/images/yt999/register_title.png) no-repeat;
        width: var(--f7-px-100);
        height: var(--f7-height-size-30);
        line-height: var(--f7-height-size-30);
        background-size: cover;
        color: #000432;
        margin-left: var(--f7-size-ps-12);
        margin-bottom: var(--f7-margin-size-0);
        margin-top: var(--f7-margin-size-15);
        padding-left: var(--f7-px-5);
        font-size: var(--f7-font-size-14);
      }
      ul {
        background-color: var(--f7-color-bg-transparent);
        .item-content {
          margin-bottom: var(--f7-margin-size-10);
          padding-left: var(--f7-px-20);

          input {
            font-size: var(--f7-font-size-14);
            color: var(--f7-color-white);
          }

          .input-clear-button {
            right: var(--f7-size-ps-12);
          }

          .iconfont {
            color: var(--f7-color-white);
          }
          .span-verify {
            position: var(--f7-postion-absolute);
            right: var(--f7-px-10);
            top: var(--f7-px-4);
            z-index: 2;
          }
        }
      }
      ul:before,
      ul:after {
        height: 0;
      }
    }
    .list .item-inner:after {
      width: var(--f7-width-size-pt92);
    }

    .list li:last-child > .item-inner:after {
      display: var(--f7-display-block) !important;
    }

    .block-strong {
      padding: var(--f7-padding-size-10) var(--f7-padding-size-20, --f7-block-padding-horizontal);
      margin: var(--f7-margin-size-0) auto;
      .block-header {
        margin-bottom: var(--f7-margin-size-15);
      }
      .block-footer {
        margin-top: var(--f7-margin-size-15);
        a.link {
          border-bottom: 1px solid var(--f7-color-lightblue);
        }
      }
    }
    .block-strong:before,
    .block-strong:after {
      height: var(--f7-height-size-0);
    }
  }
  .button-share {
    margin: 10px 12px;
    ul {
      background: #5dabfe;
    }
    .list-button {
      background-color: var(--f7-base-red);
      color: #fff;
    }
  }
}
</style>
