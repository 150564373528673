<template>
  <f7-page no-toolbar no-swipeback name="betting-search" class="page-betting-search" @page:beforeremove="onPageBeforeRemove">
    <f7-navbar :title="$t('betting_records_0001')" back-link :no-hariline="true"></f7-navbar>
    <f7-block class="block-search">
      <div class="form">
        <!-- <div class="form-item">
          <div class="item-inner">
            <div class="item-title item-label">{{ $t('betting_records_0001') }}</div>
            <div class="item-input-wrap">
              <input type="text" readonly="readonly" id="picker-gameType" ref="gameType"/>
            </div>
          </div>          
        </div>
        <div class="form-item">
          <div class="item-inner">
            <div class="item-input-wrap">
              <input type="text" readonly="readonly" id="picker-classType" ref="classType"/>
            </div>
          </div>          
        </div> -->
        <div class="form-item">
          <div class="item-inner">
            <div class="item-title item-label">{{ $t("common_0029") }}</div>
            <div class="item-input-wrap">
              <input type="date" id="dateF" v-model="BeginDay" />
            </div>
          </div>
        </div>
        <div class="form-item">
          <div class="item-inner">
            <div class="item-title item-label">{{ $t("common_0030") }}</div>
            <div class="item-input-wrap">
              <input type="date" id="dateT" v-model="EndDay" />
            </div>
          </div>
        </div>
      </div>
    </f7-block>
    <div class="list inset button-search">
      <ul>
        <li>
          <a href="#" class="list-button color-black" @click="searchSubmit">{{ $t("common_0028") }}</a>
        </li>
      </ul>
    </div>
  </f7-page>
</template>

<script>
import moment from "moment";
import Rolldate from "rolldate";
import { getProvidergame } from "../../../axios/api";

export default {
  components: {},
  props: {},
  data() {
    return {
      queryParam: {
        ProviderId: 0,
        GameCode: "",
        BeginDate: moment(new Date()).format("YYYY-MM-DD 23:59:59"),
        EndDate: moment(new Date()).format("YYYY-MM-DD 23:59:59"),
      },
      BeginDay: "",
      EndDay: "",
      pickerDependent: null,
      pickerClassType: null,
      providerGameList: [],
    };
  },
  watch: {},
  computed: {},
  methods: {
    searchSubmit() {
      this.queryParam.BeginDate = this.BeginDay + " 00:00:00";
      this.queryParam.EndDate = this.EndDay + " 23:59:59";
      if (new Date(this.queryParam.BeginDate).getTime() > new Date(this.queryParam.EndDate).getTime()) {
        this.$f7.dialog.alert(this.$t("common_0031"), this.$t("common_0004"));
        return false;
      }
      this.$f7router.navigate(`/member-center/betting-records/${JSON.stringify(this.queryParam)}/`);
    },
    getUTCMinus4Date(diffDays) {
      let now = new Date();
      now.setDate(now.getDate() + diffDays);
      let year = now.getFullYear();
      let month = now.getMonth() + 1;
      let day = now.getDate();
      return `${year}-${month.toString().padStart(2, "0")}-${day.toString().padStart(2, "0")}`;
    },
    getProvidergameEvent() {
      const self = this;
      getProvidergame().then((data) => {
        if (data.Code === "NoError") {
          self.providerGameList = data.Data;
          self.initPicker();
        }
      });
    },
    initPicker() {
      const self = this;
      const carVendors = {};

      const providerNameKeyList = ["0"],
        providerNameValueList = [this.$t("common_0005")];
      self.providerGameList.forEach((item) => {
        providerNameKeyList.push(item.ProviderId);
        providerNameValueList.push(item.ProviderName);
        carVendors[item.ProviderId] = item.Games;
      });
      self.$refs.gameType.value = providerNameValueList[0];
      self.$refs.classType.value = this.$t("common_0005");
      self.pickerDependent = self.$f7.picker.create({
        inputEl: "#picker-gameType",
        rotateEffect: true,
        routableModals: false,
        formatValue(values, displayValues) {
          return displayValues[0];
        },
        cols: [
          {
            textAlign: "center",
            values: providerNameKeyList,
            displayValues: providerNameValueList,
            onChange(picker, value, displayValue) {
              self.initPickerClass(carVendors[value]);
            },
          },
        ],
        on: {
          change(picker, values, displayValues) {
            self.queryParam.ProviderId = values[0];
          },
        },
      });
    },
    initPickerClass(data) {
      const self = this;
      let replaceKeyList = [""];
      let replaceValueyList = [this.$t("common_0005")];
      if (data) {
        data.forEach((item) => {
          replaceKeyList.push(item[0]);
          replaceValueyList.push(item[1]);
        });
      }
      if (self.pickerClassType) self.pickerClassType.destroy();
      self.pickerClassType = self.$f7.picker.create({
        inputEl: "#picker-classType",
        rotateEffect: true,
        routableModals: false,
        formatValue(values, displayValues) {
          return displayValues[0];
        },
        cols: [
          {
            textAlign: "center",
            values: replaceKeyList,
            displayValues: replaceValueyList,
            onChange(picker, value, displayValue) {},
          },
        ],
        on: {
          change(picker, values, displayValues) {
            self.queryParam.GameCode = values[0];
          },
        },
      });
    },
    onPageBeforeRemove() {
      // const self = this;
      // self.pickerDependent.destroy();
    },
  },
  created() {
    this.BeginDay = this.getUTCMinus4Date(0);
    this.EndDay = this.getUTCMinus4Date(0);
  },
  mounted() {
    //this.getProvidergameEvent();
  },
};
</script>
<style lang="less" scoped>
.page-betting-search {
  .block-search {
    margin: 15px 10px;
    padding: 0;
    .list {
      margin: 0;
      ul {
        background-color: transparent;
        .item-input {
          .item-inner {
            .input-filter {
              .row-item {
                .col-50 {
                  position: relative;
                }
                .col-50:first-child:after {
                  content: "";
                  position: absolute;
                  right: 2px;
                  width: 1px;
                  height: 25px;
                  background-color: #033a58;
                  bottom: 10px;
                }
                .col-50:before {
                  font-family: framework7-core-icons;
                  font-weight: 400;
                  font-style: normal;
                  line-height: 1;
                  letter-spacing: normal;
                  text-transform: none;
                  white-space: nowrap;
                  word-wrap: normal;
                  direction: ltr;
                  -webkit-font-smoothing: antialiased;
                  text-rendering: optimizeLegibility;
                  -moz-osx-font-smoothing: grayscale;
                  -moz-font-feature-settings: "liga";
                  font-feature-settings: "liga";
                  text-align: center;
                  display: block;
                  width: 100%;
                  height: 100%;
                  position: absolute;
                  top: 50%;
                  width: 8px;
                  height: 14px;
                  margin-top: -7px;
                  font-size: 20px;
                  font-size: var(--f7-list-chevron-icon-font-size);
                  line-height: 14px;
                  color: #c7c7cc;
                  color: var(--f7-list-chevron-icon-color);
                  pointer-events: none;
                  right: calc(var(--f7-list-item-padding-horizontal) + 0px);
                  right: calc(var(--f7-list-item-padding-horizontal) + var(--f7-safe-area-right));
                  content: "chevron_down";
                }
              }
            }
            .item-title {
              padding-top: 8px;
              width: 25%;
            }
            input {
              color: #464646;
              font-size: 14px;
            }
            input.input-title {
              color: #464646;
              font-size: 16px;
            }
          }
          .item-inner:after {
            height: 0;
          }
        }
        .item-title {
          color: #464646;
          font-size: 16px;
        }
      }
      ul:before,
      ul:after {
        height: 0;
      }
    }
  }
  .button-search {
    margin: 10px 12px;

    ul {
      background: #5dabfe;
      li {
        a {
          color: #fff;
        }
      }
    }
  }
}
</style>
