<template>
  <f7-page no-toolbar no-swipeback name="set-encrypted" class="page-set-encrypted">
    <f7-navbar :title="$t('set_encrypted_0001')" back-link :no-hariline="true"></f7-navbar>
    <template v-if="!encryptedStatus">
      <f7-block class="block-set-encrypted">
        <div class="list inline-labels list-set-encrypted">
          <ul>
            <li v-if="secQuestionList.length > 0">
              <a class="item-link smart-select smart-select-init" data-open-in="sheet">
                <select v-model="setSecParam.SecurityQuestionID">
                  <option v-for="item in secQuestionList" :key="item.SecurityQuestionID" :value="item.SecurityQuestionID">{{ item.QuestionDetail }}</option>
                </select>
                <div class="item-content">
                  <div class="item-inner">
                    <div class="item-title">{{ $t("set_encrypted_0002") }}:</div>
                    <div class="item-after">{{ $t("set_encrypted_0003") }}</div>
                  </div>
                </div>
              </a>
            </li>
            <li>
              <div class="item-content item-input">
                <div class="item-inner">
                  <div class="item-title item-label text-right">{{ $t("set_encrypted_0004") }}:</div>
                  <div class="item-input-wrap">
                    <input type="text" :placeholder="$t('set_encrypted_0005')" v-model="setSecParam.SecurityAnswer" />
                  </div>
                </div>
              </div>
            </li>
            <li>
              <div class="item-content item-input">
                <div class="item-inner">
                  <div class="item-title item-label text-right">{{ $t("set_encrypted_0006") }}:</div>
                  <div class="item-input-wrap">
                    <input type="password" :placeholder="$t('set_encrypted_0007')" v-model="setSecParam.LoginPassword" />
                  </div>
                </div>
              </div>
            </li>
          </ul>
        </div>
      </f7-block>
      <div class="list inset button-save">
        <ul>
          <li>
            <a href="#" class="list-button color-black" @click="setSecSubmit">{{ $t("set_encrypted_0008") }}</a>
          </li>
        </ul>
      </div>
    </template>
    <template v-else>
      <f7-block class="block-set-encrypted">
        <div class="list inline-labels list-set-encrypted">
          <ul>
            <li v-if="secQuestionList.length > 0">
              <a class="item-link smart-select smart-select-init" data-open-in="sheet">
                <select v-model="updateSecParam.SecurityQuestionID">
                  <option v-for="item in secQuestionList" :key="item.SecurityQuestionID" :value="item.SecurityQuestionID">{{ item.QuestionDetail }}</option>
                </select>
                <div class="item-content">
                  <div class="item-inner">
                    <div class="item-title">{{ $t("set_encrypted_0009") }}:</div>
                    <div class="item-after">{{ $t("set_encrypted_0010") }}</div>
                  </div>
                </div>
              </a>
            </li>
            <li>
              <div class="item-content item-input">
                <div class="item-inner">
                  <div class="item-title item-label text-right">{{ $t("set_encrypted_0011") }}:</div>
                  <div class="item-input-wrap">
                    <input type="text" :placeholder="$t('set_encrypted_0005')" v-model="updateSecParam.SecurityAnswer" />
                  </div>
                </div>
              </div>
            </li>
            <li v-if="secQuestionList.length > 0">
              <a class="item-link smart-select smart-select-init" data-open-in="sheet">
                <select v-model="updateSecParam.NewSecurityQuestionID">
                  <option v-for="item in secQuestionList" :key="item.SecurityQuestionID" :value="item.SecurityQuestionID">{{ item.QuestionDetail }}</option>
                </select>
                <div class="item-content">
                  <div class="item-inner">
                    <div class="item-title">{{ $t("set_encrypted_0012") }}:</div>
                    <div class="item-after">{{ $t("set_encrypted_0013") }}</div>
                  </div>
                </div>
              </a>
            </li>
            <li>
              <div class="item-content item-input">
                <div class="item-inner">
                  <div class="item-title item-label text-right">{{ $t("set_encrypted_0014") }}:</div>
                  <div class="item-input-wrap">
                    <input type="text" :placeholder="$t('set_encrypted_0005')" v-model="updateSecParam.NewSecurityAnswer" />
                  </div>
                </div>
              </div>
            </li>
            <li>
              <div class="item-content item-input">
                <div class="item-inner">
                  <div class="item-title item-label text-right">{{ $t("set_encrypted_0006") }}:</div>
                  <div class="item-input-wrap">
                    <input type="password" :placeholder="$t('set_encrypted_0007')" v-model="updateSecParam.loginPassword" />
                  </div>
                </div>
              </div>
            </li>
          </ul>
        </div>
      </f7-block>
      <div class="list inset button-save">
        <ul>
          <li>
            <a href="#" class="list-button color-black" @click="updateSecSubmit">{{ $t("set_encrypted_0008") }}</a>
          </li>
        </ul>
      </div>
    </template>
  </f7-page>
</template>
<script>
import { getSecurityQuestionList, getSecurityQuestionStatus, updateSecurityQuestionAnswer, setSecurityQuestionAnswer } from "../../../axios/api";

export default {
  components: {},
  props: {},
  data() {
    return {
      secQuestionList: [],
      encryptedStatus: false,
      setSecParam: {
        SecurityQuestionID: "",
        SecurityAnswer: "",
        LoginPassword: "",
      },
      updateSecParam: {
        SecurityQuestionID: "",
        SecurityAnswer: "",
        NewSecurityQuestionID: "",
        NewSecurityAnswer: "",
        loginPassword: "",
      },
    };
  },
  watch: {},
  computed: {},
  methods: {
    getSecurityQuestionListEvent() {
      const self = this;
      getSecurityQuestionList().then((data) => {
        if (data.Code === "NoError") {
          self.secQuestionList = data.Data;
        }
      });
    },
    getSecurityQuestionStatusEvent(state) {
      const self = this;
      const param = { data: JSON.stringify({ Choose: state ? true : false }) };
      getSecurityQuestionStatus(param).then((data) => {
        if (data.Data == 0) {
          self.encryptedStatus = false;
        } else {
          self.encryptedStatus = true;
        }
      });
    },
    setSecSubmit() {
      const self = this;
      if (!self.setSecParam.SecurityQuestionID) {
        self.$f7.dialog.alert(this.$t("set_encrypted_0015"), this.$t("common_0004"));
        return false;
      }
      if (!self.setSecParam.SecurityAnswer) {
        self.$f7.dialog.alert(this.$t("set_encrypted_0016"), this.$t("common_0004"));
        return false;
      }
      if (!self.setSecParam.LoginPassword) {
        self.$f7.dialog.alert(this.$t("set_encrypted_0017"), this.$t("common_0004"));
        return false;
      }
      const param = { data: JSON.stringify(self.setSecParam) };
      setSecurityQuestionAnswer(param).then((data) => {
        if (data.Code === "NoError") {
          self.$f7.dialog.alert(data.Data, this.$t("common_0004"));
          self.getSecurityQuestionStatusEvent(1);
        } else {
          self.$f7.dialog.alert(data.Data, this.$t("common_0004"));
        }
      });
    },
    updateSecSubmit() {
      const self = this;
      if (!self.updateSecParam.SecurityQuestionID) {
        self.$f7.dialog.alert(this.$t("set_encrypted_0018"), this.$t("common_0004"));
        return false;
      }
      if (!self.updateSecParam.SecurityAnswer) {
        self.$f7.dialog.alert(this.$t("set_encrypted_0019"), this.$t("common_0004"));
        return false;
      }
      if (!self.updateSecParam.NewSecurityQuestionID) {
        self.$f7.dialog.alert(this.$t("set_encrypted_0020"), this.$t("common_0004"));
        return false;
      }
      if (!self.updateSecParam.NewSecurityAnswer) {
        self.$f7.dialog.alert(this.$t("set_encrypted_0021"), this.$t("common_0004"));
        return false;
      }
      if (!self.updateSecParam.loginPassword) {
        self.$f7.dialog.alert(this.$t("set_encrypted_0017"), this.$t("common_0004"));
        return false;
      }
      const param = { data: JSON.stringify(self.updateSecParam) };
      updateSecurityQuestionAnswer(param).then((data) => {
        self.$f7.dialog.alert(data.Data, this.$t("common_0004"));
      });
    },
  },
  created() {
    this.getSecurityQuestionStatusEvent();
    this.getSecurityQuestionListEvent();
  },
  mounted() {},
  updated() {},
};
</script>
<style lang="less" scoped>
.page-set-encrypted {
  --f7-page-toolbar-bottom-offset: 0;
  .block-set-encrypted {
    margin: 5px 0 10px;
    padding: 0px;
    .list-set-encrypted {
      margin: 0;
      ul {
        background-color: transparent;
        li .item-content {
          padding-left: 0px;
        }
        .item-input {
          .item-inner {
            .item-title {
              padding-top: 8px;
              color: #fff;
              padding-right: 5px;
              width: 25%;
            }
            input {
              color: #fff;
              font-size: 14px;
            }
          }
          .item-inner:after {
            height: 0;
          }
        }
        .smart-select {
          .item-content {
            .item-inner {
              .item-after {
                width: 70%;
                color: #fff;
                font-size: 14px;
              }
            }
            .item-inner:after {
              height: 0;
            }
          }
        }
        .smart-select.active-state {
          background-color: transparent;
        }
        .item-title {
          color: #fff;
          font-size: 15px;
          text-align: right;
          width: 26.5%;
        }
      }
      ul:before,
      ul:after {
        height: 0;
      }
    }
  }
  .button-save {
    margin: 10px 12px;

    ul {
      background: #5dabfe;
    }
  }
}
</style>
