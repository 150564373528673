<template>
  <f7-page no-toolbar no-swipeback name="integral-records" class="page-integral-records" infinite :infinite-distance="50" :infinite-preloader="showPreloader" @infinite="loadMore">
    <f7-navbar :title="$t('integral_records_0001')" back-link :no-hariline="true"></f7-navbar>
    <f7-block class="block-records" v-if="integralRecordsList.length > 0">
      <div class="card card-outline" v-for="item in integralRecordsList" :key="item.BonusPointEventID">
        <div class="card-header">
          <div class="row no-gap">
            <div class="col-25 text-right">
              <span class="co-gray">{{ $t("integral_records_0002") }}:</span>
            </div>
            <div class="col-75 co-white">{{ item.BonusPointEventID }}</div>
          </div>
        </div>
        <div class="card-content card-content-padding">
          <div class="row no-gap">
            <div class="col-25 text-right co-white">{{ $t("integral_records_0003") }}:</div>
            <div class="col-75 co-gray">
              <template v-if="item.BonusPointBatchType == 'OfflineDeposit'">{{ $t("integral_records_0004") }}</template>
              <template v-if="item.BonusPointBatchType == 'OnlineDeposit'">{{ $t("integral_records_0005") }}</template>
              <template v-if="item.BonusPointBatchType == 'ManualDeposit'">{{ $t("integral_records_0006") }}</template>
              <template v-if="item.BonusPointBatchType == 'BetTicketInit'">{{ $t("integral_records_0007") }}</template>
              <template v-if="item.BonusPointBatchType == 'BetTicketUpdate'">{{ $t("integral_records_0008") }}</template>
              <template v-if="item.BonusPointBatchType == 'BonusPointConsume'">{{ $t("integral_records_0009") }}</template>
            </div>
          </div>
          <div class="row no-gap">
            <div class="col-25 text-right co-white">{{ $t("integral_records_0011") }}:</div>
            <div class="col-75" :class="item.DiffCurrentBonusPoint > 0 ? 'co-green' : 'co-yellow'">{{ item.DiffCurrentBonusPoint | formatCurrency }}</div>
          </div>
          <div class="row no-gap">
            <div class="col-25 text-right co-white">{{ $t("integral_records_0010") }}:</div>
            <div class="col-75 co-blue">{{ item.BeforeCurrentBonusPoint }}</div>
          </div>
        </div>
        <div class="card-footer co-white">{{ item.CreateTS.replace("T", " ") }}</div>
      </div>
    </f7-block>

    <f7-card v-else-if="integralRecordsList.length <= 0 && !showData" class="no-data">
      <img :src="`../../../static/images/yt999/noData_${$f7.params.currLang}.png`" width="45%" :onerror="`this.onerror=''; src='../../../static/images/yt999/noData_enUS.png'`" alt="No data" />
    </f7-card>
  </f7-page>
</template>

<script>
import { getCookie } from "@/config/utils";
import { getIntegralRecordsList } from "../../../axios/api";
export default {
  components: {},
  props: {},
  data() {
    return {
      integralRecordsList: [],
      allowInfinite: true,
      showPreloader: true,
      pageNow: 1,
      pageTotal: 0,
      showData: true,
    };
  },
  watch: {},
  computed: {},
  methods: {
    integralRecordsListEvent() {
      const self = this;
      let queryParam = JSON.parse(self.$f7route.params.Info);
      queryParam.BeginDate = Math.round(new Date(queryParam.BeginDate.replace(/-/g, "/")).getTime() / 1000);
      queryParam.EndDate = Math.round(new Date(queryParam.EndDate.replace(/-/g, "/")).getTime() / 1000);
      queryParam.PageNow = self.pageNow;
      queryParam.PageSize = 20;
      queryParam.Category = 0;

      const params = { data: JSON.stringify(queryParam) };
      getIntegralRecordsList(params).then((data) => {
        if (data.Code === "NoError") {
          self.integralRecordsList = self.integralRecordsList.concat(data.Data.Data);
          self.showData = self.integralRecordsList.length > 0;
          self.pageTotal = data.Data.Total;
          if (self.integralRecordsList.length >= self.pageTotal) {
            self.showPreloader = false;
          }
        }
      });
    },
    loadMore() {
      const self = this;
      if (!self.allowInfinite) return;
      self.allowInfinite = false;

      setTimeout(() => {
        if (self.integralRecordsList.length >= self.pageTotal) {
          self.showPreloader = false;
          return;
        }
        self.pageNow += 1;
        self.integralRecordsListEvent();
        self.allowInfinite = true;
      }, 1000);
    },
  },
  created() {
    this.integralRecordsListEvent();
  },
  mounted() {},
};
</script>
<style lang="less" scoped>
.page-integral-records {
  .block-records {
    margin: 0;
    padding: 0;
    .card {
      border: 1px solid #d2d2d2;
      background-color: #f3f3f5;
      margin: 8px;
      .co-white {
        color: #464646;
      }
      .co-gray {
        color: #8e8e91;
      }
      .co-green {
        color: #17903a;
      }
      .co-blue {
        color: #028cb3;
      }
      .co-yellow {
        color: #f7da00;
      }
      .co-red {
        color: #c50d0d;
      }
      .card-header:after,
      .card-footer:before {
        background-color: #1c3b93;
      }
      .card-header {
        display: block;
        font-size: 15px;
        padding: 10px 15px 10px 8px;
        i.f7-icons {
          font-size: 18px;
          vertical-align: middle;
          margin-top: -3px;
          color: 1e56bb;
        }
      }
      .card-content {
        font-size: 15px;
        padding: 2px 4px;
        .row {
          padding: 4px 0;
        }
      }
      .card-footer {
        justify-content: flex-end;
        padding: 8px 15px;
      }
    }
  }

  .no-data {
    background-color: transparent;
    box-shadow: none;
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    align-items: center;
    align-content: center;
    height: 50%;
  }
}
</style>
