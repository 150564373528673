<template>
  <f7-page class="page-fish-list" name="fish-list" @page:init="onPageInit" @page:beforein="onPageBeforeIn">
    <!-- Top Navbar -->
    <f7-navbar back-link :sliding="false">
      <f7-nav-title sliding>
        {{ $t('fish_list_0001') }}
      </f7-nav-title>
      <f7-nav-right v-if="getLoginState" class="nav-balance">
        <f7-link @click="openBalancePopup">
          <img class="btn-dowload" src="../static/images/yt999/balance.png" height="22">
        </f7-link>
      </f7-nav-right>
    </f7-navbar>

    <!-- Game Navbutton -->
    <f7-tab id="tab-fish" class="page-content tab-fish" tab-active>
      <f7-block class="tab-content">
        <div class="card-fishing">           
          <ul>
            <li v-for="(item,itemIndex) in fishList" :key="itemIndex" v-show="item.Id!=0">
              <div class="item">
                <div class="item-img">
                  <img :src="`${fishHomeImgUri}${item.GameCode}_home.png`" alt >
                </div>            
                <div class="item-content row no-gap">
                  <div class="item-title col-70">
                    <div class="text">{{item.GameName}}</div>
                    <div class="desc">{{getFishDesc(item.ProviderId)}}</div>
                  </div>
                  <div class="item-entry col-30">
                    <button class="button button-outline button-raised" @click="playGame(item)">{{ $t('fish_list_0002') }}</button>
                  </div>
                </div>               
              </div>
            </li>
          </ul>
        </div>
      </f7-block>
    </f7-tab>

    <!-- Quick Transfer -->
    <div class="dialog dialog-balance dialog-move" @dialog:closed="balanceCloseEvent" @dialog:open="balanceOpenEvent">
      <div class="dialog-inner">
        <div class="dialog-title">
          {{ $t('home_0010') }}
          <f7-link @click="closeDialogBalance">
            <i class="f7-icons">chevron_right</i>
          </f7-link>
        </div>
        <div class="dialog-text">
          <f7-block class="block-balance">
            <f7-block-header class="block-header-totalBalance">
              <f7-row>
                <f7-col width="35">{{ $t('home_0011') }}</f7-col>
                <f7-col width="35">
                  <span v-if="totalWalletFlag">{{totalBalance| formatCurrency}}</span>
                  <preloader :list="4" v-else></preloader>
                </f7-col>
                <f7-col width="30">
                  <f7-button raised fill class="btn-refresh" @click="getAllBalanceList">{{ $t('home_0012') }}</f7-button>
                </f7-col>
              </f7-row>
              <f7-row>
                <f7-col width="35">{{ $t('home_0013') }}</f7-col>
                <f7-col width="35">
                  <span v-if="totalWalletFlag">{{walletBalance| formatCurrency}}</span>
                  <preloader :list="4" v-else></preloader>
                </f7-col>
                <f7-col width="30">
                  <f7-button raised fill class="btn-recycling" @click="oneClickRecycling">{{ $t('common_one_key_transfer_game_balance_back') }}</f7-button>
                </f7-col>
              </f7-row>
              <f7-row>
                <f7-col width="35">{{ $t('home_0015') }}</f7-col>
                <f7-col width="35">
                  <span v-if="totalWalletFlag">{{paddingBalance| formatCurrency}}</span>
                  <preloader :list="4" v-else></preloader>
                </f7-col>
                <f7-col width="30"></f7-col>
              </f7-row>
            </f7-block-header>
            <div class="block-content">
              <f7-row class="item-balance" v-for="item in gameBalanceList" :key="item.ProviderId" :class="`item-balance-${item.ProviderId}`">
                <f7-col width="35">{{item.Name}}</f7-col>
                <f7-col width="35" class="col-balance">
                  <span v-if="totalWalletFlag">
                    <span v-if="item.IsMaintain">{{ $t('common_under_maintenance') }}</span>
                    <span v-else-if="!item.QuerySuccess">N/A</span>
                    <span v-else>{{item.Balance| formatCurrency}}</span>
                  </span>
                  <preloader :list="4" v-else></preloader>
                </f7-col>
                <f7-col width="30">
                  <f7-row class="btn-group">
                    <f7-col>
                      <f7-button raised fill class="btn-transfer" :class="{'btn-disabled':item.IsMaintain || !item.QuerySuccess}" @click="mainWalletToGame(item)">{{ $t('common_balance_transfer_in') }}</f7-button>
                    </f7-col>
                    <f7-col>
                      <f7-button raised fill class="btn-transferOut"  :class="{'btn-disabled':item.IsMaintain || !item.QuerySuccess}" @click="gameWalletToMain(item)" >{{ $t('common_balance_transfer_out') }}</f7-button>
                    </f7-col>
                  </f7-row>
                </f7-col>
              </f7-row>
            </div>
            <f7-block-footer>
              <f7-row no-gap>
                <f7-col tag="span">
                  <f7-button large class="btn-recharge border-radius-left" @click="jumpPage(0, 'member-center/deposit')" >{{ $t('home_deposit') }}</f7-button>
                </f7-col>
                <f7-col tag="span">
                  <f7-button large fill class="btn-withdrawal border-radius-right" @click="jumpPage(0, 'member-center/withdraw')">{{ $t('home_withdrawal') }}</f7-button>
                </f7-col>
              </f7-row>
            </f7-block-footer>
          </f7-block>
        </div>
        
      </div>
    </div>

  </f7-page>
</template>
<script>
import marquee from "./template/marquee";
import preloader from "./template/preloader";
import api from "../config/api";
import { getCookie, setStore, removeStore, getStore } from "../config/utils";
import { mapGetters, mapMutations } from "vuex";
import {
  getInfo,
  getCarouseladvertList,
  getLampList,
  getGameListp,
  getGameListByPId,
  getBalances,
  getGameEntry,
  getValidProviders,
  mainWalletToGame,
  gameWalletToMain,
  recyclingFunds,
  getFisheListbyClass,
  alreadyRead
} from "../axios/api";
import http from "../axios/http";
import common from '../service/common';

export default {
  components: {
    marquee,
    preloader
  },
  data() {
    return {
      app_version:VERSION,
      dowloadLink: "",
      bannerList: [],
      bannerBaseUri: "",
      bannerShow: false,
      swiperParams: {
        speed: 500,
        loop: true,
        autoplay: {
          disableOnInteraction: false,
          delay: 2500
        },
        lazy: {
          loadPrevNext: true
        }
      },
      fishList: [],
      fishHomeImgUri: '',
      selectCardDefault: 23,
      selectLotteryDefault: 31,
      loadingSkeleton: true, //加载消息弹窗特效标识
      skeletonTimeout: null, //消息弹窗timeout 对象
      gameBalanceList: [], //以下是钱包变量
      totalWalletFlag: false,
      totalBalance: 0.0,
      walletBalance: 0.0,
      paddingBalance: 0.0,
      infiniteLoading: false, //加载条
      userMessageList: [], //个人消息
      selectedMessage:{},
      checkRead: false,
      messageNum: 0,
      isAppDevice: getStore('device'),
      getInfoStatus:false,
      getGameListStatus: false,
      timeoutStatus: false
    };
  },
  computed: {
    ...mapGetters(["getMerchantInfo", "getAllBalances", "firstLoadApp", 'getLoginState']),
      loadingPopupOpened(){
        return this.$f7router.currentRoute.name == 'home' && this.firstLoadApp;
      },
  },
  methods: {
    ...mapMutations(["SETMERCHAT_INFO", "SETALLBALANCES", "SETFIRSTLOADAPP"]),
    getInfo() {
      const self = this;
      getInfo().then(data => {
        if (data.Code === "NoError" && data.Data) {
          self.getInfoStatus = true;
          const d = data.Data;
          self.SETMERCHAT_INFO(d); //存储Vuex store merchantinfo
          setStore(`ImagesURL`, d.ImagesURL);
          self.dowloadLink = d.BaseInfo.AppSite;
          self.fishHomeImgUri = d.GameImagesURL + (window.configs.fishHomeImg == null?'static/images/fish/mobile/':window.configs.fishHomeImg);
          self.getFishList();
        }
      });
    },
    getFishList(){
      const self=this;
      const data = {
            "ParentClassId":0,
            "ChildClassId":0,
            "IsHot":false,
            "Platform":2
      };
      const params = { data: JSON.stringify(data) };
      //var tmpFishList=[];
      getFisheListbyClass(params).then(data =>{
        self.fishList = data.Data;
        // let current_flag=0;
        // let arr=[];
        // for (let index = 0; index < self.fishList.length; index++) {
        //   arr[arr.length]=self.fishList[index];
        //   self.fishList[index].GameCode = self.fishList[index].GameCode.toString().toLowerCase();
        //   current_flag++;
        //   if(current_flag==self.fishPageSize){
        //     tmpFishList[tmpFishList.length]=arr;
        //     current_flag=0;
        //     arr=[];
        //   }
        // }
        // var addlast=false;
        // if (current_flag!=0&&current_flag<self.fishPageSize) {
        //   while (current_flag<self.fishPageSize) {
        //     arr[arr.length]={Id:0,Name:"",GameCode:'agin_6'};
        //     current_flag++;
        //   }
        //   addlast=true;
        // }
        // if(addlast){
        //   tmpFishList[tmpFishList.length]=arr;
        // }
        // self.fishList_main=tmpFishList;
      });
    },
    getFishDesc(id){
      switch (id) {
        case 30:  //AG
          return this.$t('fish_list_0009');
        case 20:  //CQ9
          return this.$t('fish_list_0010');
        case 2:  //SG
          return this.$t('fish_list_0011');
        case 505:  //BB
          return this.$t('fish_list_0012');
        case 509:  //BG
          return this.$t('fish_list_0013');
        case 515:  //VG
          return this.$t('fish_list_0014');
        case 518:  //FG
          return this.$t('fish_list_0015');
        case 520:  //GG
          return this.$t('fish_list_0016');
        case 522:  //LC
          return this.$t('fish_list_0017');
        case 519: //JDB
          return this.$t('fish_list_0018');
        case 19: //PP
          return this.$t('fish_list_0019');
        default:
          break;
      }
    },
    playGame(item) {
      const self = this;
      const router = self.$f7router;
      if (self.getLoginState && getCookie("zz")) {
        const params = {
          ProviderId: item.ProviderId,
          gameCode: item.ProviderGameCode,
          Language: "zhCN",
          View: "v1",
          TemplateName: "flashtechgray",
          Platform: 2,
          Name: item.Name||item.GameName,
          Host: window.location.origin,
        };
        const d = {type: 1, data: JSON.stringify(params), z: getCookie('zz')||getStore('zz')};
        const isApp = getStore('device') && getStore('device') === 'App';
        if(!isApp && !common.getBrowser.isSougou) {
          setStore('playGameParam', JSON.stringify(params));
          this.common.redirectURL(`/#/loading-game/`);
          return;
        }

        let promtLoadingGame = self.$f7.dialog.create({
          title: '',
          text: this.$t('common_game_loading_format', [params.Name]),
          cssClass: "dialog-preloadgame",
        }).open();
        self.$f7.params.dialog.closeByBackdropClick = false;
        self.$f7.$('.dialog-backdrop').css('background-color','transparent');
        
        getGameEntry(d).then(data => {
          self.$f7.params.dialog.closeByBackdropClick = true;
          self.$f7.$('.dialog-backdrop').css('background-color','rgba(0, 0, 0, 0.74)');
          //promtLoadingGame.close();

          if (data.Code === "NoError" && data.Data) {
            if(params.ProviderId == 512) {
              const entryData = JSON.parse(data.Data);
              let encode = btoa(encodeURI(data.Data));
              window.location.href = entryData.GameEntryURL + '/index.html?info=' + encode;
            } else { 
              window.location.href = data.Data;
            }
          } else {
            promtLoadingGame.close()
            let content =
              data.Code === "ProviderMaintain" &&
              data.Data &&
              data.Data.length >= 2
                ? this.$t('common_0024') + "</br>" + this.$t('fish_list_0020') + "：" +
                  data.Data[0].replace("T", " ") +
                  "<br/>" + this.$t('fish_list_0021') + "：" +
                  data.Data[1].replace("T", " ")
                : this.$t('common_loading_game_failed');
            //self.$f7.dialog.alert(content, "");
            self.promptMaintain(content);
          }
        });
      } else {
        let promtLoadingGame = self.$f7.dialog.create({
          title: '',
          text: this.$t('common_need_login'),
          cssClass: "dialog-preloadgame",
        }).open();   
        setTimeout(function(){ 
          promtLoadingGame.close(); }
          , 2000
          );
        //router.navigate("/login/");
      }
    },
    promptMaintain(content){
      const self = this;
      self.$f7.params.dialog.closeByBackdropClick = false;
      self.$f7.$('.dialog-backdrop').css('background-color','transparent');
      let promptMaintain = self.$f7.dialog.create({
        title: '',
        text: content,
        cssClass: "dialog-game",
      }).open();
      setTimeout(() => {
        self.$f7.params.dialog.closeByBackdropClick = true;
        self.$f7.$('.dialog-backdrop').css('background-color','rgba(0, 0, 0, 0.74)');
        promptMaintain.close();
      }, 2000);
    },
    showDeterminate(multiColor) {
      // 顶部 progressbar加载条
      const self = this;
      const app = self.$f7;
      if (self.infiniteLoading) return;
      self.infiniteLoading = true;
      if (multiColor) {
        app.progressbar.show("multi");
      } else {
        app.progressbar.show(app.theme === "md" ? "yellow" : "blue");
      }
    },
    async getValidProviders() {
      const self = this;
      let res = await http.post(api.validproviders);
      if (res.Code === "NoError") {
        self.$f7.dialog.open('.dialog-balance',true);
        self.gameBalanceList = res.Data;

        self.getAllBalanceList();
      }
    },
    getAllBalanceList() {
      const self = this;
      self.totalWalletFlag = false; //总钱包余额是否请求完成标识
      getBalances().then(data => {
        if (data.Code === "NoError") {
          const res = data.Data;
          self.totalWalletFlag = true;
          self.SETALLBALANCES(res); //存储Vuex store balance
          self.gameBalanceList = res[0];
          self.totalBalance = res[1];
          self.paddingBalance = res[2];
          self.walletBalance = res[3];
        }
      });
    },
    jumpPage(type, param) {
      const self = this;
      const router = self.$f7router;
      if (type === 0) {
        //balance popup 弹窗
        self.$f7.dialog.close('.dialog-balance');
        router.navigate(`/${param}/`);
      }
    },
    oneClickRecycling(event) {
      const self = this;
      if (!self.totalWalletFlag) return;
      self.$f7.$('.btn-recycling').addClass('progressbar');
      self.$f7.$('.btn-recycling').text(this.$t('common_game_balance_transfer_back'));
      recyclingFunds().then(data => {
        self.$f7.$('.btn-recycling').removeClass('progressbar');
        self.$f7.$('.btn-recycling').text(this.$t('common_one_key_transfer_game_balance_back'));
        if (data.Code === "NoError") {
          self.getAllBalanceList();
        }
      });
    },
    mainWalletToGame(item) {
      const self = this;
      if (!self.totalWalletFlag || item.IsMaintain || !item.QuerySuccess || !self.walletBalance) return;
      const params = {
        ProviderId: item.ProviderId,
        Amount: self.walletBalance
      };
      const d = { data: JSON.stringify(params) };
      self.$f7.$(`.item-balance-${item.ProviderId}`).addClass('progressbar');
      mainWalletToGame(d).then(data => {
        setTimeout(() => {
          self.$f7.$(`.item-balance-${item.ProviderId}`).removeClass('progressbar');
        }, 1000);
        if (data.Code === "NoError") {
          self.getAllBalanceList();
        }
      });
    },
    gameWalletToMain(item) {
      const self = this;
      if (!self.totalWalletFlag || item.IsMaintain || !item.QuerySuccess || !item.Balance) return;
      const params = {
        ProviderId: item.ProviderId,
        Amount: item.Balance
      };
      const d = { data: JSON.stringify(params) };
      self.$f7.$(`.item-balance-${item.ProviderId}`).addClass('progressbar');
      gameWalletToMain(d).then(data => {
        setTimeout(() => {
          self.$f7.$(`.item-balance-${item.ProviderId}`).removeClass('progressbar');
        }, 1000);
        if (data.Code === "NoError") {
          self.getAllBalanceList();
        }
      });
    },
    openBalancePopup() {
      const self = this;
      if (self.getLoginState) {
        self.getValidProviders();
      }
    },
    closeDialogBalance() {
      this.$f7.dialog.close('.dialog-balance');
    },
    balanceOpenEvent() {
      this.$f7.$(".dialog-backdrop").css("background", "rgba(0, 4, 7, 0.77)");
    },
    balanceCloseEvent() {
      this.$f7.$(".dialog-backdrop").css("background", "transparent");
    },
    loadingStatusEvent() {
      const self = this;
      const start = new Date();
      let timeoutShowReload = setInterval(() => {
        const end = new Date();
        let time = (end - start) / 1000;
        if (time >= 10) {
            self.timeoutStatus = true;
            clearInterval(timeoutShowReload);
        }
      }, 1000)
      let loadingInterval = setInterval(() => {
        const end = new Date();
        let time = (end - start) /1000;
        if(time > 3) {
          if(this.firstLoadApp && this.bannerShow && this.getInfoStatus && this.getGameListStatus) {
            //this.loadingPopupOpened = false;
            this.$f7.popup.close('.loadding-popup');
            this.SETFIRSTLOADAPP(false);
            clearInterval(loadingInterval);
            clearInterval(timeoutShowReload);
          }
        }
      }, 1000);
    },
    onPageInit(e, page) {
      const self = this;
      self.$f7ready(f7 => {
        self.getInfo();
      });
    },
    onPageBeforeIn() {
      // this.$f7.emit("myCustomEvent", "home");
      // removeStore("mc-tab-active");
    },
  },
  created() {},
  mounted() {},
  updated() {
    this.$f7.lazy.create(this.$el);
    this.$f7.swiper.create(this.$el);
  }
};
</script>
<style lang="less" scoped>
  .page-home{
    --f7-page-subnavbar-offset:80px;
  }
  .nav-80{
    height:77px;
  }
</style>