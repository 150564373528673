<template>
  <f7-page no-toolbar no-swipeback name="direct-search" class="page-direct-search">
    <f7-navbar :title="$t('referrer_receive_search_0001')" back-link :no-hariline="true"></f7-navbar>
    <f7-block class="block-search">
      <div class="form">
        <div class="form-item">
          <div class="item-inner">
            <div class="item-title item-label">{{ $t("common_0029") }}</div>
            <div class="item-input-wrap">
              <input type="date" id="dateF" v-model="BeginDay" />
            </div>
          </div>
        </div>
        <div class="form-item">
          <div class="item-inner">
            <div class="item-title item-label">{{ $t("common_0030") }}</div>
            <div class="item-input-wrap">
              <input type="date" id="dateT" v-model="EndDay" />
            </div>
          </div>
        </div>
      </div>
    </f7-block>
    <div class="list inset button-search">
      <ul>
        <li>
          <a href="#" class="list-button color-white" @click="searchSubmit">{{ $t("common_0028") }}</a>
        </li>
      </ul>
    </div>
  </f7-page>
</template>

<script>
import moment from "moment";

export default {
  components: {},
  props: {},
  data() {
    return {
      queryParam: {
        BeginDate: moment(new Date()).format("YYYY-MM-DD 23:59:59"),
        EndDate: moment(new Date()).format("YYYY-MM-DD 23:59:59"),
      },
      BeginDay: "",
      EndDay: "",
    };
  },
  watch: {},
  computed: {},
  methods: {
    searchSubmit() {
      this.queryParam.BeginDate = this.BeginDay + " 00:00:00";
      this.queryParam.EndDate = this.EndDay + " 23:59:59";
      if (new Date(this.queryParam.BeginDate).getTime() > new Date(this.queryParam.EndDate).getTime()) {
        this.$f7.dialog.alert(this.$t("common_0031"), this.$t("common_0004"));
        return false;
      }
      this.$f7router.navigate(`/member-center/referrer-receive-records/${JSON.stringify(this.queryParam)}/`);
    },
    getUTCMinus4Date(diffDays) {
      let now = new Date();
      now.setDate(now.getDate() + diffDays);
      let year = now.getFullYear();
      let month = now.getMonth() + 1;
      let day = now.getDate();
      return `${year}-${month.toString().padStart(2, "0")}-${day.toString().padStart(2, "0")}`;
    },
  },
  created() {
    this.BeginDay = this.getUTCMinus4Date(0);
    this.EndDay = this.getUTCMinus4Date(0);
  },
  mounted() {},
};
</script>
<style lang="less" scoped>
.page-direct-search {
  .block-search {
    margin: 15px 10px;
    padding: 0;
    .list {
      margin: 0;
      ul {
        background-color: transparent;
        .item-input {
          .item-inner {
            .item-title {
              padding-top: 12px;
              width: 30%;
              font-size: 12px;
              color: #464646;
            }
            input {
              color: #464646;
              font-size: 12px;
            }
            input.input-title {
              color: #b19785;
              font-size: 16px;
            }
          }
          .item-inner:after {
            right: 10px;
            left: auto;
          }
        }
        .item-title {
          color: #fff;
          font-size: 16px;
        }
      }
      ul:before,
      ul:after {
        height: 0;
      }
    }
  }
  .button-search {
    margin: 10px 12px;
    .list-button {
      background-color: var(--f7-base-red);
    }
    ul {
      background: #5dabfe;
    }
  }
}
</style>
