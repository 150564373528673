<template>
  <f7-page no-toolbar no-swipeback name="activities-review" class="page-activities-review" infinite :infinite-distance="50" :infinite-preloader="showPreloader" @infinite="loadMore">
    <f7-navbar :title="$t('v1_ar_0001')" back-link :no-hariline="true"></f7-navbar>
    <div class="block accordion-list block-review" v-if="activitiesList.length > 0">
      <div class="accordion-item" v-for="item in activitiesList" :key="item.Id">
        <div class="accordion-item-toggle">
          <div class="row no-gap">
            <div class="col-50">{{ item.PromotionName }}</div>
            <div class="col-50 text-right">{{ item.CreateTs | time_local }}</div>
          </div>
          <div class="row no-gap">
            <div class="col-50">{{ $t("v1_ar_0002") }} {{ item.Id }}</div>
            <div class="col-50 text-right">
              (
              <span v-if="item.Status == 'Wait' || item.Status == 'Locked'" class="col-yellow">{{ $t("v1_ar_0003") }}</span>
              <span v-if="item.Status == 'Accept'" class="col-green">{{ $t("v1_ar_0004") }}</span>
              <span v-if="item.Status == 'Rejected'" class="col-red">{{ $t("v1_ar_0005") }}</span>
              <span v-if="item.Status == 'Init'" class="col-green">{{ $t("v1_ar_0006") }}</span>
              )
            </div>
          </div>
          <div class="row no-gap">
            <div class="col-50">{{ $t("v1_ar_0007") }}({{ item.Bonus | formatCurrency }})</div>
            <div class="col-50 text-right col-detail">
              {{ $t("v1_ar_0008") }}
              <i class="f7-icons">chevron_right</i>
            </div>
          </div>
        </div>
        <div class="accordion-item-content">
          <p class="text-center">{{ $t("v1_ar_0009") }}</p>
          <div class="card card-outline">
            <div class="card-content" v-html="$options.filters.formatActiveDetail(item.RequestDetail)"></div>
          </div>
          <p class="text-center">{{ $t("v1_ar_0010") }}</p>
          <div class="card card-outline">
            <div class="card-content">{{ item.Remark ? item.Remark : "--" }}</div>
          </div>
        </div>
      </div>
    </div>

    <f7-card v-else-if="activitiesList.length <= 0 && !showData" class="no-data">
      <img :src="`../../../static/images/yt999/noData_${$f7.params.currLang}.png`" width="45%" :onerror="`this.onerror=''; src='../../../static/images/yt999/noData_enUS.png'`" alt="No data" />
    </f7-card>
  </f7-page>
</template>

<script>
import { getCookie } from "@/config/utils";
import { getActivityAuditUserName } from "../../../axios/api";

export default {
  components: {},
  props: {},
  data() {
    return {
      activitiesList: [],
      allowInfinite: true,
      showPreloader: true,
      pageNow: 1,
      pageTotal: 0,
      showData: true,
    };
  },
  watch: {},
  computed: {},
  methods: {
    getActivityAuditUserNameEvent() {
      const self = this;
      let queryParam = JSON.parse(self.$f7route.params.Info);
      queryParam.BeginDate = Math.round(new Date(queryParam.BeginDate.replace(/-/g, "/")).getTime() / 1000);
      queryParam.EndDate = Math.round(new Date(queryParam.EndDate.replace(/-/g, "/")).getTime() / 1000);
      queryParam.PageNow = self.pageNow;
      queryParam.PageSize = 10;

      const params = { data: JSON.stringify(queryParam) };
      getActivityAuditUserName(params).then((data) => {
        if (data.Code === "NoError") {
          self.activitiesList = self.activitiesList.concat(data.Data[0]);
          self.showData = self.activitiesList.length > 0;
          self.pageTotal = data.Data[1];
          if (self.activitiesList.length >= self.pageTotal) {
            self.showPreloader = false;
          }
        }
      });
    },
    loadMore() {
      const self = this;
      if (!self.allowInfinite) return;
      self.allowInfinite = false;

      setTimeout(() => {
        if (self.activitiesList.length >= self.pageTotal) {
          self.showPreloader = false;
          return;
        }
        self.pageNow += 1;
        self.getActivityAuditUserNameEvent();
        self.allowInfinite = true;
      }, 1000);
    },
  },
  created() {
    this.getActivityAuditUserNameEvent();
  },
  mounted() {},
};
</script>
<style lang="less" scoped>
.page-activities-review {
  .block-review {
    margin: 0;
    padding: 0;
    .accordion-item {
      margin: 6px;
      border-radius: 4px;
      border: 1px solid #d2d2d2;
      background-color: #f3f3f5;
      //background: -webkit-linear-gradient(top, #293158 5%, #1e2d56 100%);
      color: #464646;
      font-size: 14px;

      .col-red {
        color: #c50d0d;
      }
      .col-green {
        color: #17903a;
      }
      .col-yellow {
        color: yellow;
      }
      .col-blue {
        color: #028cb3;
      }
      .accordion-item-toggle {
        padding: 8px 10px;
        .row {
          padding: 3px 0;
          .col-detail {
            color: #0f9fe6;
            i.f7-icons {
              font-size: 16px;
              vertical-align: middle;
              margin-top: -2px;
              margin-right: -4px;
              margin-left: -4px;
            }
          }
        }
      }
      .accordion-item-content {
        p {
          margin: 6px 0;
        }
        .card {
          margin: var(--f7-card-margin-vertical) 50px;
          padding: 4px 30px;
          background-color: #e2e2e2;
          border: 1px solid #b2a176;
        }
      }
    }
    .accordion-item-opened {
      .accordion-item-content {
        border-top: 1px solid #c2c5ce;
      }
    }
  }

  .no-data {
    background-color: transparent;
    box-shadow: none;
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    align-items: center;
    align-content: center;
    height: 50%;
  }
}
</style>
