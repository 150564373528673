<template>
  <f7-page no-toolbar no-swipeback name="messgae-center" class="page-messgae-center" @page:beforein="onPageBeforeIn">
    <!-- <f7-navbar title="消息中心" back-link :no-hariline="true"></f7-navbar> -->
    <f7-navbar innerClass="navbar-inner-message">
      <f7-nav-left>
        <f7-link @click="backMemberCenter">
          <i class="f7-icons">chevron_left</i>
        </f7-link>
      </f7-nav-left>
      <f7-nav-title>{{ $t("message_center_0001") }}</f7-nav-title>
      <f7-nav-right>
        <f7-link></f7-link>
      </f7-nav-right>
    </f7-navbar>
    <div class="buttons-tab buttons-message">
      <f7-link tab-link="#tab-game-message" tab-link-active :text="$t('message_center_0002')"></f7-link>
      <f7-link tab-link="#tab-user-message" :text="$t('message_center_0003')"></f7-link>
    </div>

    <f7-block class="block-button" v-if="showUserMsgBtn">
      <f7-segmented round tag="p">
        <f7-button round outline :active="readActiveIndex == 0" @click="toggleMessage(0)">{{ $t("message_center_0004") }}</f7-button>
        <f7-button round outline :active="readActiveIndex == 1" @click="toggleMessage(1)">{{ $t("message_center_0005") }}</f7-button>
      </f7-segmented>
    </f7-block>
    <f7-tabs swipeable class="tabs-message">
      <f7-tab @tab:show="tabShow(1)" id="tab-game-message" class="page-content tab-game-message" tab-active>
        <template v-if="systemList.length > 0">
          <f7-block class="block">
            <f7-card class="card-message" v-for="item in systemList" :key="item.Id">
              <f7-card-content v-html="item.Content"></f7-card-content>
              <f7-card-footer>{{ item.CreateTs.replace("T", " ") }}</f7-card-footer>
            </f7-card>
          </f7-block>
        </template>
        <template v-else-if="systemList.length <= 0 && !showData">
          <f7-card class="no-data">
            <img :src="`../../../static/images/yt999/hd_nodata_${$f7.params.currLang}.png`" width="45%" :onerror="`this.onerror=''; src='../../../static/images/yt999/hd_nodata_enUS.png'`" alt="No data" />
          </f7-card>
        </template>
      </f7-tab>
      <f7-tab @tab:show="tabShow(2)" id="tab-user-message" class="page-content tab-user-message">
        <template v-if="userMessageList.length > 0">
          <f7-block class="block">
            <div class="card-message card-user-message card" v-for="item in userMessageList" :key="item.Id" @click="openCardPopup(item)">
              <div class="text-left card-content card-content-padding">
                <i class="iconfont icon-renwu"></i>
                {{ item.Title }}
              </div>
              <div class="card-footer">
                <i class="iconfont icon-time"></i>
                {{ item.CreateTs.replace("T", " ") }}
              </div>
            </div>
          </f7-block>
        </template>
        <template v-else>
          <f7-card class="no-data">
            <img :src="`../../../static/images/yt999/noData_${$f7.params.currLang}.png`" width="45%" :onerror="`this.onerror=''; src='../../../static/images/yt999/noData_enUS.png'`" alt="No data" />
          </f7-card>
        </template>
      </f7-tab>
    </f7-tabs>

    <f7-popup v-show="showMsgPopup" class="message-popup" :opened="popupMsgOpened" @popup:open="openMsgPopup" @popup:close="closeMsgPopup">
      <f7-page>
        <f7-navbar :title="popupMessageItem.Title">
          <f7-nav-right>
            <f7-link popup-close>
              <i class="f7-icons">multiply_circle</i>
            </f7-link>
          </f7-nav-right>
        </f7-navbar>
        <f7-block>
          <p v-html="popupMessageItem.Content"></p>
        </f7-block>
      </f7-page>
    </f7-popup>
  </f7-page>
</template>

<script>
import { getCookie } from "@/config/utils";
import { getMsgSystemList, getUserNewsList, alreadyRead } from "../../../axios/api";

export default {
  components: {},
  props: {},
  data() {
    return {
      systemList: [],
      systemParam: {
        MerchantAlias: "",
        Type: 1,
        Title: "",
        ProviderId: 0,
        BeginDate: 0,
        EndDate: 0,
        PageNow: 1,
        PageSize: "1000",
      },
      userMessageList: [],
      userMsgParam: {
        Status: 2,
        BeginDate: null,
        EndDate: null,
        PageNow: 1,
        PageSize: "1000",
      },
      showUserMsgBtn: false,
      readActiveIndex: 0,
      popupMsgOpened: false,
      popupMessageItem: [],
      showData: true,
      showMsgPopup: true,
    };
  },
  watch: {},
  computed: {},
  methods: {
    getMsgSystemListEvent() {
      const self = this;
      const param = { data: JSON.stringify(self.systemParam) };
      getMsgSystemList(param).then((data) => {
        if (data.Code === "NoError") {
          self.systemList = data.Data.Data;
          self.showData = self.systemList.length > 0;
        }
      });
    },
    userMessageListEvent() {
      const self = this;
      const param = { data: JSON.stringify(self.userMsgParam) };
      getUserNewsList(param).then((data) => {
        if (data.Code === "NoError") {
          self.userMessageList = data.Data.Data;
        }
      });
    },
    tabShow(tab) {
      if (tab === 1) {
        this.getMsgSystemListEvent();
        this.showUserMsgBtn = false;
      } else {
        this.userMessageListEvent();
        this.showUserMsgBtn = true;
      }
    },
    toggleMessage(index) {
      if (index === 0) {
        this.userMsgParam.Status = 2;
      } else {
        this.userMsgParam.Status = 1;
      }
      this.readActiveIndex = index;
      this.userMessageListEvent();
    },
    onPageBeforeIn() {
      const id = this.$f7route.params.Id;
      if (id == 1) {
        this.$f7.tab.show("#tab-game-message");
        this.getMsgSystemListEvent();
      } else {
        this.$f7.tab.show("#tab-user-message");
        this.userMessageListEvent();
      }
    },
    openCardPopup(item) {
      const self = this;
      self.popupMessageItem = item;
      self.popupMsgOpened = true;
      if (!item.IsRead) {
        alreadyRead({ data: item.Id }).then((data) => {
          self.userMsgParam.Status = 2;
          self.userMessageListEvent();
        });
      }
    },
    backMemberCenter() {
      this.$f7router.navigate("/member-center/");
    },
    openMsgPopup() {
      this.showMsgPopup = true;
    },
    closeMsgPopup() {
      this.popupMsgOpened = false;
      this.showMsgPopup = false;
    },
  },
  created() {},
  mounted() {},
};
</script>
<style lang="less" scoped>
.page-messgae-center {
  --f7-page-toolbar-bottom-offset: 0px;

  .buttons-message {
    height: 49px;
    width: var(--f7-width-size-pt100);
    display: var(--f7-display-flex);
    justify-content: var(--f7-text-center);
    box-sizing: var(--f7-border-box);
    align-items: var(--f7-text-center);
    align-content: var(--f7-text-center);
    overflow: var(--f7-hidden);
    // background-color: #0c1939;
    // border-bottom: 1px solid #0534a0;
    a.tab-link {
      width: var(--f7-width-size-pt100);
      height: var(--f7-height-size-pt100);
      box-sizing: var(--f7-border-box);
      display: var(--f7-display-flex);
      justify-content: var(--f7-text-center);
      align-items: var(--f7-text-center);
      flex-direction: var(--f7-flex-direction);
      text-transform: var(--f7-tabbar-link-text-transform);
      font-weight: var(--f7-tabbar-link-font-weight);
      letter-spacing: var(--f7-tabbar-link-letter-spacing);
      overflow: var(--f7-hidden);
      color: #808080;
      font-size: var(--f7-px-14);
    }

    a.tab-link.tab-link-active {
      color: #464646;
      // background: url(../../../static/images/yt999/signInNav.png) no-repeat 0px
      //   0px;
      // background-size: var(--f7-size-percent-100) var(--f7-size-percent-100);
      border-bottom: 1px solid;
    }
  }

  .block-button {
    width: 100%;
    position: fixed;
    margin: 0 auto;
    z-index: 2;
    display: flex;
    justify-content: center;
    top: 105px;
    a.button {
      width: 130px;
      height: 32px;
      line-height: 30px;
      border-color: #2864ff;
      background-color: transparent;
      color: #2864ff;
    }
    a.button-active {
      background-color: #2864ff;
      color: #fff;
    }
  }
  .tabs-message {
    position: relative;
    overflow: hidden;
    min-height: calc(100% - 52px);
    height: calc(100% - 52px);
    .tab {
      padding: 0;
      .block {
        margin: 0;
        padding: 0;
        .card-message {
          margin: 6px 4px;
          background-color: #f3f3f5;
          color: #464646;
          border: 1px solid #d2d2d2;
          i.iconfont {
            font-size: 16px;
            color: #1e56bb;
            margin-right: 6px;
          }
          .card-footer {
            justify-content: flex-end;
            color: #464646;
            font-size: 14px;
            min-height: 20px;
            padding: 7px 15px;
          }
          .card-footer:before {
            height: 0;
          }
        }
        .card-user-message {
          .card-content {
            padding: 8px 15px 0;
            text-overflow: ellipsis;
            overflow: hidden;
            white-space: nowrap;
            font-size: 16px;
          }
          .card-footer {
            justify-content: flex-start;
          }
        }
        .card-user-message:first-child {
          margin-top: 54px;
        }
      }

      .no-data {
        background-color: transparent;
        box-shadow: none;
        display: flex;
        flex-flow: row nowrap;
        justify-content: center;
        align-items: center;
        align-content: center;
        height: 80%;
      }
    }
  }
}
.message-popup {
  .block {
    margin: 10px 0;
    color: #464646;
  }
}
</style>
